import React, { useCallback, useEffect } from "react";
import {
  EventType,
  InteractionType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { AuthenticatedApp } from "./AuthenticatedApp";
import { Authenticator } from "./Authenticator";
import { msalConfig } from "../utils/config/authConfig";
import { useStore } from "../store/useStore";
import { useApi } from "../utils/services/useApi";
import { Toast } from "primereact/toast";
import { useToaster } from "../utils/services/useToaster";
import { useCookies } from "../utils/services/useCookies";
import { useLoginService } from "../utils/services/useLoginService";
const msalInstance = new PublicClientApplication(msalConfig);

function App() {
  const {
    login,
    token,
    profileImage,
    storeProfileImage,
    setCurrentYearHolidays,
    setNextYearHolidays,
    setPreviousYearHolidays,
    profile,
    roles,
    storeRole,
    storeProfile,
  } = useStore();

  const { toast, showToaster } = useToaster();
  const { handleMsalToken } = useLoginService();
  const { get } = useApi(showToaster);
  const { getCookieToken, getCookieProfileImage, getCookieRoles, getCookieProfile } =
    useCookies();
  if (!token && getCookieToken()) {
    login(getCookieToken());
  }

  if (!profileImage && getCookieProfileImage()) {
    storeProfileImage(getCookieProfileImage());
  }
  
  
  if (!roles?.length && getCookieRoles()) {
    storeRole(getCookieRoles());
  }

  if (!profile.employeeId && getCookieProfile()) {
    storeProfile(getCookieProfile());
  }

  const year = new Date().getFullYear();
  const nextYear = year + 1;
  const previousYear = year - 1;

  const getCurrentYearData = useCallback(
    async () => {
      try {
        const data = await get(`holiday/${year}`);
        setCurrentYearHolidays(data);
      } catch (err) {
        console.log(err);
      }
    },
    [year, setCurrentYearHolidays] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getNextYearData = useCallback(
    async () => {
      try {
        const data = await get(`holiday/${nextYear}`);
        setNextYearHolidays(data);
      } catch (err) {
        console.log(err);
      }
    },
    [nextYear, setNextYearHolidays] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getPreviousYearData = useCallback(
    async () => {
      try {
        const data = await get(`holiday/${previousYear}`);
        setPreviousYearHolidays(data);
      } catch (err) {
        console.log(err);
      }
    },
    [previousYear, setPreviousYearHolidays] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    getCurrentYearData();
  }, [getCurrentYearData]);

  useEffect(() => {
    getNextYearData();
  }, [getNextYearData]);

  useEffect(() => {
    getPreviousYearData();
  }, [getPreviousYearData]);

  useEffect(() => {
    msalInstance.addEventCallback((message: any) => {
      if (
        message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS &&
        message.interactionType === InteractionType.Redirect
      ) {
        handleMsalToken(message.payload.accessToken);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MsalProvider instance={msalInstance}>
      <Toast ref={toast} />
      <BrowserRouter>
        {token ? <AuthenticatedApp /> : <Authenticator />}
      </BrowserRouter>
    </MsalProvider>
  );
}

export default App;
