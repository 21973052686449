import axios, { AxiosError } from "axios";
import { env } from "../env";
import { ToasterType } from "../../types/ToasterType";
import { useStore } from "../../store/useStore";

export const useApi = (showToaster: (obj: ToasterType) => void) => {
  const { token, logout } = useStore();

  axios.interceptors.response.use(
    async (response) => {
      return response;
    },
    (error: AxiosError) => {
      const { status, data }: { status: number, data: any } = error.response!;

      const CustomError = data?.errors
      switch (status) {
        case 400:
          showToaster({
            status: "error",
            CustomError,
          });
          break;
        case 401:
          logout();
          showToaster({
            status: "error",
            CustomError,
          });
          break;
        case 404:
          showToaster({
            status: "error",
            CustomError,
          });
          break;
        case 500:
          showToaster({
            status: "error",
            CustomError,
          });
          break;
        default:
          showToaster({
            status: "error",
            CustomError,
          });
          break;
      }
      return Promise.reject(error);
    }
  );

  return {
    getBinary: (endpoint: string) => axios.get(`${env.API_ENDPOINT}/${endpoint}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'arraybuffer'
    }).then((response) => response.data),
    get: (endpoint: string) =>
      axios
        .get(`${env.API_ENDPOINT}/${endpoint}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => response.data.data),
    post: (endpoint: string, body = {}) =>
      axios
        .post(`${env.API_ENDPOINT}/${endpoint}`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => response.data),
    put: (endpoint: string, body = {}) =>
      axios
        .put(`${env.API_ENDPOINT}/${endpoint}`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => response.data),
    httpDelete: (endpoint: string) =>
      axios
        .delete(`${env.API_ENDPOINT}/${endpoint}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        )
        .then((response) => response.data),
  };
};