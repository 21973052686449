import { ColorRing } from "react-loader-spinner";
const Loader = ({
  color,
  height,
  width,
  visible,
}: {
  color: string;
  height: number;
  width: number;
  visible: boolean;
}) => {
  return (
    <ColorRing
      visible={true}
      height="40"
      width="40"
      ariaLabel="blocks-loading"
      wrapperStyle={{}}
      wrapperClass="blocks-wrapper"
      colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
    />
  );
};

export default Loader;
