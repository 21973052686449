import Cookies from "universal-cookie";
import { ProfileType } from "../../types/ProfileType";


export const useCookies = () => {

    const cookies = new Cookies();

    const getCookieToken = (): string => {
        return cookies.get("token");
    }

    const getCookieProfileImage = (): string | undefined => {
        const profileImage = localStorage.getItem("profileImage");
        if (profileImage) {
            return JSON.parse(profileImage)
        }
    }

    const storeCookieToken = (token: string) => {
        cookies.set("token", token);
    }

    const storeCookieProfileImage = (profileImage: string) => {
        localStorage.setItem("profileImage", JSON.stringify(profileImage));
    }


    const clearCookies = (): void => {
        Object.keys(cookies.getAll()).forEach(cookie => {
            cookies.remove(cookie);
        })
    }

    const storeCookieRoles = (roles: string[]) => {
        cookies.set("roles", roles)
    }

    const getCookieRoles = () => {
        return cookies.get("roles")
    }

    const storeCookieProfile = (profile: ProfileType) => {
        cookies.set("profile", profile)
    }

    const getCookieProfile = () => {
        return cookies.get("profile")
    }

    return {
        getCookieProfileImage,
        getCookieToken,
        storeCookieToken,
        storeCookieProfileImage,
        storeCookieRoles,
        getCookieRoles,
        storeCookieProfile,
        getCookieProfile,
        clearCookies
    }

}   