import Top from "../assets/images/top.svg";
import whiteTop from "../assets/images/whiteTop.svg";
import whiteBottom from "../assets/images/whiteBottom.svg";
import Fingerprint from "../assets/images/Fingerprint-cuate.svg";
import Bottom from "../assets/images/bottom.svg";
import microsoftLogo from "../assets/images/microsoft-logo.svg";
import { Button } from "primereact/button";
import { useLoginService } from "../utils/services/useLoginService";
import kitten1 from "../assets/images/kitten_1.png";
import kitten2 from "../assets/images/kitten_2.png";
import kitten3 from "../assets/images/kitten_3.png";
import "../assets/styles/login.scss";
import { useReducer } from "react";
import { constantObj } from "../utils/config/constantConfig";

type KittenState = {
  kitten1: boolean;
  kitten2: boolean;
  kitten3: boolean;
  lastKittenShown: number;
};

type KittenAction = {
  type: "SHOW_KITTEN" | "HIDE_KITTENS";
};

const Login = () => {
  const { handleLogin } = useLoginService();

  const audio = new Audio("./meow.mp3");

  const kittensReducer = (state: KittenState, action: KittenAction) => {
    switch (action.type) {
      case "SHOW_KITTEN":
        let lastKitten = state.lastKittenShown;

        if (lastKitten >= 3) {
          lastKitten = 0;
        }

        const newState: KittenState = {
          ...state,
          [`kitten${lastKitten + 1}`]: true,
          lastKittenShown: lastKitten + 1,
        };

        return newState;

      case "HIDE_KITTENS":
        return { ...state, kitten1: false, kitten2: false, kitten3: false };
      default:
        return state;
    }
  };

  const showKittens = () => {
    audio.play();
    dispatch({ type: "SHOW_KITTEN" });

    setTimeout(() => {
      dispatch({ type: "HIDE_KITTENS" });
    }, 2000);
  };

  const [kittensVisible, dispatch] = useReducer(kittensReducer, {
    kitten1: false,
    kitten2: false,
    kitten3: false,
    lastKittenShown: 0,
  });

  return (
    <>
      <div className="loginMain md:bg-white flex flex-row h-full">
        <img
          className="inline-flex mt-5 md:hidden img-fluid -mt-4 ml-2"
          src={whiteTop}
          alt="top"
          height="20%"
          width="70px"
        />
        <div className=" w-6 px-3 hideForMobile">
          <div className="px-2">
            <div className="mb-2">
              <img
                className="hidden md:inline-flex img-fluid"
                src={Top}
                alt="top"
                height="20%"
                width="20%"
              />
              <img
                className="inline-flex mt-5 md:hidden img-fluid"
                src={whiteTop}
                alt="top"
                height="20%"
                width="70px"
              />
            </div>
            <div className="text-center relative top-auto">
              <img
                src={Fingerprint}
                alt="Fingerprint"
                height="350px"
                width="350px"
                className="hidden md:inline-flex"
              />
            </div>
            <div className="mb-2 text-right">
              <img
                className="hidden md:inline-flex img-fluid"
                src={Bottom}
                alt="bottom"
                height="20%"
                width="20%"
              />
            </div>
          </div>
        </div>
        <div className="loginMainRight md:bg-white w-6 flex justify-content-center align-items-center flex-column ">
          <div className="MobileLogo mb-5">
            <img
              className={`hidden md:inline-flex img-fluid${
                constantObj.companyId === "softobotics" ? "rounded" : ""
              }`}
              width={`${
                constantObj.companyId === "softobotics" ? "259px" : "200px"
              }`}
              src={constantObj.companyLogo}
              alt=""
            />
          </div>
          <div className="middle">
            <img
              className="inline-flex md:hidden img-fluid"
              width="250px"
              src={constantObj.darkBackgroundLogo}
              alt=""
            />
            <Button
              onClick={handleLogin}
              className="MobileButton md: p-button-outlined p-button-secondary loginBtn"
            >
              <img src={microsoftLogo} alt="microsoft logo" />
              <span className="MobileLoginText pl-4">Login with Microsoft</span>
            </Button>
          </div>
        </div>
        <img
          className="inline-flex mobileBottom md:hidden img-fluid"
          src={whiteBottom}
          alt="bottom"
          height="20%"
          width="70px"
        />
        <div className="kittens">
          <span>P.S.&nbsp;</span>
          <span onClick={showKittens} className="link">
            Kittens
          </span>
        </div>
        {kittensVisible.kitten1 && (
          <img
            alt="kitten1"
            className="kitten-image"
            src={kitten1}
            height={500}
          />
        )}
        {kittensVisible.kitten2 && (
          <img
            alt="kitten2"
            className="kitten-image"
            src={kitten2}
            height={500}
          />
        )}
        {kittensVisible.kitten3 && (
          <img
            alt="kitten3"
            className="kitten-image"
            src={kitten3}
            height={500}
          />
        )}
      </div>
    </>
  );
};
export default Login;
