import  { SetStateAction } from 'react';
import { useState, useRef, useCallback, useEffect } from 'react';
import { Card } from "primereact/card";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useApi } from "../utils/services/useApi";
import { useToaster } from "../utils/services/useToaster";
import { monthYearBodyTemplate } from '../components/PayrollTableTemplate';
import { useNavigate, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Toast } from "primereact/toast";
import { Dialog } from 'primereact/dialog';
import warning from "../assets/images/warning.svg";
import { empPayrollData } from '../types/empPayrollData';

export const AllEmployeePayroll = () => {
    const [displayRunPayroll, setdisplayRunPayroll] = useState(false);
    const navigate = useNavigate();
    const [selectedEmployeeCount, setSelectedEmployeeCount] = useState(0);
    const { showToaster } = useToaster();
    const { post, get } = useApi(showToaster);
    const toast = useRef<any>(null);
    const [, setPosition] = useState("center");
    const [selectedEmployee, setSelectedEmployee] = useState<empPayrollData[]>([]);
    const [employeePayroll, setEmployeePayroll] = useState<empPayrollData[]>([]);
    
    useEffect(() => {
        setSelectedEmployeeCount(selectedEmployee.length);
    }, [selectedEmployee]);

    const dialogFuncMap: any = {
        displayRunPayroll: setdisplayRunPayroll,
    };

    const handleRunPayroll = (
        name: string,
        position: SetStateAction<string> | undefined
    ) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    };

    const onHide = (name: string) => {
        dialogFuncMap[`${name}`](false);
    };

    const { date } = useParams();
    let formatteddate;
    if (date) {
        const year = date.slice(0, 4);
        const month = date.slice(4, 6);
        formatteddate = DateTime.fromObject({ year: parseInt(year), month: parseInt(month), day: 1 }).toFormat("MMM yyyy");
    }

    const dt = useRef(null);
    const getEmployeePayroll = useCallback(async () => {
        try {
            const {content} = await get(`/payroll/employees?pagination=false`);
            setEmployeePayroll(content);
        }
        catch (err) {
            console.log(err)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getEmployeePayroll()
    }, [getEmployeePayroll])


    const cancelChecked = () => {
        setSelectedEmployee([])
        navigate('/employee-payroll');
    }

    const handleOnSubmit = useCallback(
        async () => {
            const listOfEmployeeId = selectedEmployee.map((employees: empPayrollData) => employees.empId);
            try {
                let selectedEmployeePayroll
                if (selectedEmployee.length > 0) {
                    selectedEmployeePayroll = {
                        empIds: listOfEmployeeId,
                        month: date
                    }

                    const { success } = await post(`payroll/bulk`, selectedEmployeePayroll);
                    if (success) {
                        toast.current.show({
                            severity: "success",
                            summary: "Payroll Ran Successfully",
                            life: 1000,
                        });
                    }
                    onHide("displayRunPayroll");
                    setTimeout(() => {
                        navigate('/employee-payroll');
                    }, 1500)
                }
               
            }
            catch (err) {
                onHide("displayRunPayroll");
                console.log(err);
            }
        }, [selectedEmployee] // eslint-disable-line react-hooks/exhaustive-deps
    );


    return (
        <div id="allEmployeePayroll" className='px-4 py-5 all-employee-page'>
            <Toast ref={toast} />
            <Card className='mb-4'>
                <div className="text-left font-bold">{`Running Payroll for ${formatteddate}`}</div>
            </Card>

            <div>
                <DataTable ref={dt} selection={selectedEmployee} onSelectionChange={(e) => setSelectedEmployee(e.value)} value={employeePayroll}>
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} exportable={false}></Column>
                    <Column field="name" header="Name" ></Column>
                    <Column field="empId" header="Employee Id" style={{ minWidth: '16rem' }}></Column>
                    <Column field="email" header="Email Id" ></Column>
                    <Column field="month" header="Last Payroll Month" body={monthYearBodyTemplate} style={{ minWidth: '8rem' }}></Column>
                </DataTable>

                <Dialog
                    header="Run Payroll"
                    visible={displayRunPayroll}
                    onHide={() => onHide("displayRunPayroll")}
                >
                    <div className="flex">
                        <img src={warning} alt="" />
                        <div className="ml-3 flex align-items-center">
                            Are you sure you want to run the payroll?
                        </div>
                    </div>
                    <div className="text-right mt-3">
                        <Button
                            className="mr-3 bg-transparent border-0 text-blue-600"
                            onClick={() => onHide("displayRunPayroll")}
                        >
                            No
                        </Button>
                        <Button
                            className="cust-btn-clr"
                            onClick={(e) => {
                                e.preventDefault();
                                handleOnSubmit();
                            }}
                        >
                            Yes
                        </Button>
                    </div> 
                </Dialog>

                <div className='mt-4'>
                    <div className='flex flex-row-reverse'>
                        <Button disabled={selectedEmployeeCount === 0} onClick={()=>{
                            if(selectedEmployee.length>0){
                                handleRunPayroll("displayRunPayroll", 'center');
                            }
                            
                        }} className='cust-btn-clr'>Run</Button>
                        <Button onClick={cancelChecked} label="Cancel" className='p-button-outlined mr-2' />
                    </div>
                </div>
            </div>
        </div>
    )
}
