import { useStore } from "../../store/useStore";
import axios from "axios";
import { env } from "../env";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../config/authConfig";
import { useCookies } from "./useCookies";
import { ProfileType } from "../../types/ProfileType";
import jwt_decode from "jwt-decode";
import { useMediaQuery } from "react-responsive";

const useLoginService = () => {
  const { login, storeProfileImage } = useStore();
  const { storeProfile, storeRole } = useStore();

  const { instance } = useMsal();
  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const {
    storeCookieToken,
    storeCookieProfileImage,
    storeCookieProfile,
    storeCookieRoles,
  } = useCookies();
  const getToken = async (token: string) => {
    try {
      const res = await axios.get(`${env.API_ENDPOINT}/auth/login`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data.data;
    } catch (err) {
      console.log(err);
    }
  };

  const handleLogin = async () => {
    if (isMobile) {
      instance.acquireTokenRedirect(loginRequest);
    } else {
      const { accessToken } = await instance.acquireTokenPopup(loginRequest);
      handleMsalToken(accessToken);
    }
  };
  const handleMsalToken = async (accessToken: string) => {
    const { token, profileImage } = await getToken(accessToken!);
    const ProfileData: ProfileType = jwt_decode(token);
    if (token) {
      login(token);
      storeCookieToken(token);
      storeProfileImage(profileImage);
      storeCookieProfileImage(profileImage);
      storeProfile(ProfileData);
      storeCookieProfile(ProfileData);
      storeRole(ProfileData.roles);
      storeCookieRoles(ProfileData.roles);
    }
  };

  return { handleLogin, handleMsalToken };
};

export { useLoginService };
