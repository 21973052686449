import { Button } from "primereact/button";
import React, { useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { useApi } from "../utils/services/useApi";
import { useToaster } from "../utils/services/useToaster";
import { Toast } from "primereact/toast";
import { MyTeamType } from "../types/MyTeamType";

const CompLeaveModal = ({
  employee,
  onHide,
  isVisible
}: {
  employee: MyTeamType;
  onHide: () => void;
  isVisible: boolean
}) => {
  const [reason, setReason] = useState("");
  const [leaves, setLeaves] = useState(0);
  const [reasonError, setReasonError] = useState(false);
  const [leavesError, setLeavesError] = useState(false);
  const { toast, showToaster } = useToaster();
  const { post } = useApi(showToaster);
  
  const handleSubmit = async () => {
    if (!leaves) setLeavesError(true);
    if (!reason) setReasonError(true);

    try {
      const { success } = await post(`leave/comp`, { reason, numDays: leaves, empId: employee.empId });

      if (success) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Leave Added",
          life: 3000,
        });
      }
      onHide();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
              header="Add Compensatory Leave"
              className="pb-0 leaveRequestPendingModal"
              visible={isVisible}
              onHide={onHide}
            >
              <div className="bottom">
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Name</p>
                  <p className="font-bold right my-2">
                    {employee.displayName}
                  </p>
                </div>
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Email</p>
                  <p className="font-bold right my-2">
                    {employee.mail}
                  </p>
                </div>
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Employee ID</p>
                  <p className="font-bold right my-2">
                    {employee.empId}
                  </p>
                </div>
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Leave Type</p>
                  <p className="font-bold right my-2">
                    {'Casual'}
                  </p>
                </div>
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Number Of Leaves</p>
                  <InputNumber
                  maxFractionDigits={1}
                  value={leaves}
                  onChange={(e) => {
                    if (e.value) {
                      setLeaves(e.value);
                      setLeavesError(false);
                    } else {
                      setLeaves(0);
                      setLeavesError(true);
                    }
                  }} />
                </div>
                <div className="eachRow">
                  <p className="w-3 left"></p>
                  <div className="mt-1 text-red-400">
                    {leavesError ? "This field is required" : null}
                  </div>
                </div>
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Reason</p>
                  <InputTextarea
                    className="mt-3 my-2 modalTextArea"
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value);
                      if (e.target.value) {
                        setReasonError(false)
                      } else {
                        setReasonError(true);
                      }
                    }}
                    rows={5}
                    cols={60}
                    autoResize
                  />
                </div>
                <div className="eachRow">
                  <p className="w-3 left"></p>
                  <div className="text-red-400">
                    {reasonError ? "This field is required" : null}
                  </div>
                </div>
              </div>
              <div className="flex gap-5 justify-content-end mt-2">
                <Button
                  label="Submit"
                  className="p-button-success"
                  onClick={handleSubmit}
                  disabled={leavesError || reasonError}
                ></Button>
              </div>
            </Dialog>
        </div>
  );
};

export { CompLeaveModal };
