import React, { useEffect, useState, useCallback, SetStateAction } from "react";
import { useApi } from "../utils/services/useApi";
import { useToaster } from "../utils/services/useToaster";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import {
  NumberOfLeavesBodyTemplate,
  createdDateBodyTemplate,
  endDateBodyTemplate,
  LeaveRequestBodyTemplate,
  leaveTypeBodyTemplate,
  nameBodyTemplate,
  startDateBodyTemplate,
  statusBodyTemplate,
} from "../components/LeaveTableTemplate";
import { Paginator } from "primereact/paginator";
import {
  currentPageReportType,
  onBasicPageChangeType,
} from "../types/PaginationType";
import "../assets/styles/leaveRequest.scss";
import { Dropdown } from "primereact/dropdown";
import { LeavesData } from "../constants/LeavesData";
import { LeavesStatus } from "../constants/LeaveStatus";
import { InputText } from "primereact/inputtext";
import { serialize } from "../../src/utils/serialize";
import { Spinner } from "../components/Spinner";

export const LeaveRequest = () => {
  const { showToaster } = useToaster();
  const { get } = useApi(showToaster);
  const [size, setSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [leaveRequestData, setLeaveRequestData] = useState([]);
  const [totalRecords, setTotalRecords] = useState();
  const [basicFirst, setBasicFirst] = useState(0);
  const [sortField, setSortField] = useState<any>("created_at");
  const [sortOrder, setSortOrder] = useState<SetStateAction<any>>(-1);
  const [leaveType, setLeaveType] = useState<string | null>("");
  const [status, setStatus] = useState<string | null>("");
  const [searchTerm, setSearchTerm] = useState<string | null>("");
  const [pageLoader, setPageLoader] = useState(true);

  const getLeaveRequest = useCallback(
    async () => {
      try {
        const queryParams = {
          number: currentPage,
          size,
          sortBy: sortField,
          sortOrder,
          searchTerm,
          status,
          leaveType,
        };

        const { content, totalElements } = await get(
          `leave-request/list?${serialize(queryParams)}`
        );
        setLeaveRequestData(content);
        setTotalRecords(totalElements);
        setPageLoader(false);
      } catch (err) {
        console.log(err);
      }
    },
    [size, currentPage, sortField, sortOrder, searchTerm, status, leaveType] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    getLeaveRequest();
  }, [getLeaveRequest]);

  const template3 = {
    layout:
      "RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport",
    CurrentPageReport: (options: currentPageReportType) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "auto",
            textAlign: "center",
          }}
        >
          Showing {options.first} to {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  const onBasicPageChange = (event: onBasicPageChangeType) => {
    setCurrentPage(event.page);
    setBasicFirst(event.first);
    setSize(event.rows);
  };

  return (
    <>
      {pageLoader ? (
        <Spinner />
      ) : (
        <div className="test my-team-bg pt-4 h-full tmsContainer">
          <div className="mx-5" id="leaveRequest">
            <div className="card">
              <h3>Leave Requests</h3>
              <div className="searchbar mb-4">
                <div className="col-md-4">
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                      placeholder="Search Employee Name"
                      className="custom-input-text-dropdown mb-2"
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </span>
                </div>
                <div className="col-md-8">
                  <Dropdown
                    value={leaveType}
                    onChange={(e) => {
                      setLeaveType(e.value);
                    }}
                    options={LeavesData}
                    optionLabel="name"
                    className="mr-3 custom-input-text-dropdown mb-2"
                    placeholder="Filter by Leave Type"
                    showClear={!!leaveType?.length}
                  />
                  <Dropdown
                    value={status}
                    onChange={(e) => {
                      setStatus(e.value);
                    }}
                    options={LeavesStatus}
                    optionLabel="name"
                    placeholder="Filter by Status"
                    className="custom-input-text-dropdown"
                    showClear={!!status?.length}
                  />
                </div>
              </div>
              <DataTable
                value={leaveRequestData}
                id="myLeaveMain"
                className="leaveRequestTable"
                sortField={sortField}
                sortOrder={sortOrder}
                onSort={(e) => {
                  setSortField(e.sortField);
                  setSortOrder(e.sortOrder);
                }}
              >
                <Column
                  header="Name"
                  body={nameBodyTemplate}
                  exportable={false}
                />
                <Column
                  header="Leave Type"
                  body={leaveTypeBodyTemplate}
                  exportable={false}
                />
                <Column
                  header="From Date"
                  field="start_date"
                  body={startDateBodyTemplate}
                  exportable={false}
                  sortable
                />
                <Column
                  header="To Date"
                  field="end_date"
                  body={endDateBodyTemplate}
                  exportable={false}
                  sortable
                />
                <Column
                  header="Applied At"
                  field="created_at"
                  body={createdDateBodyTemplate}
                  exportable={false}
                  sortable
                />
                <Column
                  header="Number Of Leaves"
                  body={NumberOfLeavesBodyTemplate}
                  exportable={false}
                />
                <Column
                  header="Status"
                  body={statusBodyTemplate}
                  exportable={false}
                ></Column>
                <Column
                  header="Action"
                  body={(e) => (
                    <LeaveRequestBodyTemplate
                      onComplete={getLeaveRequest}
                      leaveId={e.leaveId}
                    />
                  )}
                  exportable={false}
                ></Column>
              </DataTable>
              <Paginator
                first={basicFirst}
                rows={size}
                totalRecords={totalRecords}
                template={template3}
                rowsPerPageOptions={[10, 20, 50]}
                onPageChange={onBasicPageChange}
              ></Paginator>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
