import { Button } from "primereact/button";
import React, { useState, useCallback } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { Tag } from "primereact/tag";
import { Dialog } from "primereact/dialog";
import { useApi } from "../utils/services/useApi";
import { useToaster } from "../utils/services/useToaster";
import { LeaveType } from "../types/LeaveType";
import { DateTime } from "luxon";
import { Toast } from "primereact/toast";
import { leaveTypesObj } from "../constants/LeaveTypesObj";
import { StatusEnum } from "../enum/StatusEnum";
import { useStore } from "../store/useStore";
import { RoleEnum } from "../enum/RoleEnum";
import "../assets/styles/LeaveRequestModal.scss";
import { ProgressSpinner } from "primereact/progressspinner";

const LeaveRequestModal = ({
  onComplete,
  leaveId,
}: {
  onComplete: () => void;
  leaveId: number;
}) => {
  const [displayPendingLeave, setDisplayPendingLeave] = useState(false);
  const [displayLeave, setdisplayLeave] = useState(false);
  const [displayRejectComment, setDisplayRejectComment] = useState(false);
  const [, setPosition] = useState("center");
  const [comments, setComments] = useState("");
  const { toast, showToaster } = useToaster();
  const { get, put } = useApi(showToaster);
  const [singleLeave, setSingleLeave] = useState<LeaveType | null>(null);
  const [commentError, setCommentError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rejectloading, setRejectloading] = useState(false);
  const [rejectApprovedloading, setRejectApprovedloading] = useState(false);

  const { roles } = useStore();
  const isPrivileged: boolean = roles.includes(RoleEnum.ADMIN);

  const colors: any = {
    APPROVED: "success",
    ADDED: "info",
    PENDING: "warning",
    REJECTED: "danger",
  };

  const dialogFuncMap: any = {
    displayPendingLeave: setDisplayPendingLeave,
    displayRejectComment: setDisplayRejectComment,
    displayLeave: setdisplayLeave,
  };

  const onShow = (
    name: string,
    position: React.SetStateAction<string> | undefined
  ) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const handleReject = (
    name: string,
    position: React.SetStateAction<string> | undefined
  ) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name: string) => {
    dialogFuncMap[`${name}`](false);
  };

  const getSingleLeave = useCallback(async (id: number) => {
    try {
      const res = await get(`leave/${id}`);
      onShow(
        res?.status === StatusEnum.PENDING
          ? "displayPendingLeave"
          : "displayLeave",
        "center"
      );
      setSingleLeave(res);
    } catch (err) {
      console.log(err);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleApproveLeaveRequest = async (id: number) => {
    try {
      setLoading(true);
      const payload = {
        approveComments: comments,
      };
      const { success } = await put(`leave-request/approve/${id}`, payload);
      if (success) {
        setLoading(false);
        setComments("");
        toast.current.show({
          severity: "success",
          summary: "Approved",
          detail: "Leave Approved",
          life: 3000,
        });
      }
      onHide("displayLeave");
      onComplete();
      getSingleLeave(leaveId);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleRejectLeaveRequest = async (id: number) => {
    try {
      if (comments) {
        const payload = {
          approveComments: comments,
        };
        setRejectloading(true);

        const { success } = await put(`leave-request/reject/${id}`, payload);
        if (success) {
          setRejectloading(false);
          setComments("");
          toast.current.show({
            severity: "warn",
            summary: "Rejected",
            detail: "Leave Rejected",
            life: 3000,
          });
        }
        onHide("displayLeave");
        onComplete();
        getSingleLeave(leaveId);
      } else {
        onHide("displayLeave");
        setCommentError(true);
      }
    } catch (err) {
      setRejectloading(false);
      onHide("displayLeave");
      console.log(err);
    }
  };

  const rejectApprovedLeave = async (id: number) => {
    try {
      if (isPrivileged && comments) {
        setRejectApprovedloading(true);
        const payload = { approveComments: comments };
        const { success } = await put(`leave-request/reject/${id}`, payload);
        if (success) {
          setRejectApprovedloading(false);
          setComments(" ");
          toast.current.show({
            severity: "warn",
            summary: "Rejected",
            detail: "Leave Rejected",
            life: 3000,
          });
        }
        onHide("displayLeave");
        onComplete();
        getSingleLeave(leaveId);
      } else {
        setRejectApprovedloading(false);
        setCommentError(true);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <Toast ref={toast} />
      <Button
        className="cust-btn-clr leaveRequestViewBtn"
        onClick={async () => {
          if (leaveId) await getSingleLeave(leaveId);
        }}
      >
        View
      </Button>
      {singleLeave && (
        <div>
          {singleLeave.status === StatusEnum.PENDING ? (
            <Dialog
              header="Leave Details"
              className="pb-0 leaveRequestPendingModal"
              visible={displayPendingLeave}
              onHide={() => onHide("displayPendingLeave")}
            >
              <div className="bottom">
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Name</p>
                  <p className="font-bold right my-2">
                    {singleLeave.emp_Id?.name || "-"}
                  </p>
                </div>
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Email</p>
                  <p className="font-bold right my-2">
                    {singleLeave.emp_Id?.email || "-"}
                  </p>
                </div>
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Employee Id</p>
                  <p className="font-bold right my-2">
                    {singleLeave.emp_Id?.empId || "-"}
                  </p>
                </div>
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Leave Type</p>
                  <p className="font-bold right my-2">
                    {leaveTypesObj[singleLeave.leaveType]}
                  </p>
                </div>
                <div className="eachRow">
                  <p className="w-3 left my-2">Leave Period</p>
                  <p className="font-bold right my-2">
                    {Math.abs(singleLeave.numDays) === 0.5
                      ? "Half Day"
                      : Math.abs(singleLeave.numDays) === 1
                      ? "Single"
                      : Math.abs(singleLeave.numDays) > 1
                      ? "Multiple"
                      : "-"}
                  </p>
                </div>
                <div className="eachRow">
                  <p className="w-3 left my-2">Date</p>
                  <p className="font-bold right my-2">
                    {DateTime.fromISO(singleLeave.startDate).toLocaleString(
                      DateTime.DATE_MED
                    )}{" "}
                    {singleLeave.endDate &&
                      !(singleLeave?.endDate === singleLeave?.startDate) &&
                      ` - ${DateTime.fromISO(
                        singleLeave.endDate
                      ).toLocaleString(DateTime.DATE_MED)}`}
                  </p>
                </div>
                <div className="eachRow">
                  <p className="w-3 left my-2">Apply Date</p>
                  <p className="font-bold right my-2">
                    {DateTime.fromISO(singleLeave.createdDate).toLocaleString(
                      DateTime.DATE_MED
                    )}
                  </p>
                </div>
                <div className="eachRow">
                  <p className="w-3 left my-2">Number Of Leaves</p>
                  <p className="font-bold right my-2">
                    {Math.abs(singleLeave.numDays)}
                  </p>
                </div>
                <div className="eachRow">
                  <p className="w-3 left my-2">Reason(Optional)</p>
                  <p className="font-bold right my-2">
                    {singleLeave.reason || "-"}
                  </p>
                </div>
                <div className="eachRow">
                  <p className="w-3 left my-2">Status</p>
                  <Tag
                    value={
                      singleLeave.status.charAt(0).toUpperCase() +
                      singleLeave.status.substr(1).toLowerCase()
                    }
                    severity={colors[singleLeave.status]}
                    rounded={true}
                    id="MobileStatusRight"
                    className="leaveRequestTag"
                  ></Tag>
                </div>
                <div className="eachRow">
                  <p className="w-3 left my-2">Reviewer Comments</p>
                  <InputTextarea
                    className="mt-3 my-2 modalTextArea"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    rows={5}
                    cols={60}
                    autoResize
                  />
                </div>
                <div className="eachRow">
                  <div className="mt-3 text-red-400">
                    {commentError ? "This field is required" : null}
                  </div>
                </div>
              </div>
              <div className="flex gap-5 justify-content-end mt-2">
                <Button
                  disabled={rejectloading}
                  className="p-button-warning h-3rem"
                  onClick={() => {
                    handleRejectLeaveRequest(singleLeave.leaveId);
                  }}
                >
                  {rejectloading ? (
                    <ProgressSpinner
                      className="custom-progress-spinner"
                      strokeWidth="10"
                    />
                  ) : (
                    "Reject"
                  )}
                </Button>
                <Button
                  disabled={loading}
                  className="p-button-success h-3rem"
                  onClick={() => handleApproveLeaveRequest(singleLeave.leaveId)}
                >
                  {loading ? (
                    <ProgressSpinner
                      className="custom-progress-spinner"
                      strokeWidth="10"
                    />
                  ) : (
                    "Approve"
                  )}
                </Button>
              </div>
            </Dialog>
          ) : (
            <Dialog
              header="Leave Details"
              className="pb-0 leaveRequestPendingModal"
              id="MobileViewModal"
              visible={displayLeave}
              onHide={() => onHide("displayLeave")}
            >
              <div className="bottom">
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Leave Type</p>
                  <p className="font-bold right my-2">
                    {leaveTypesObj[singleLeave.leaveType]}
                  </p>
                </div>
                <div className="eachRow">
                  <p className="w-3 left my-2">Leave Period</p>
                  <p className="font-bold right my-2">
                    {Math.abs(singleLeave.numDays) === 0.5
                      ? "Half Day"
                      : Math.abs(singleLeave.numDays) === 1
                      ? "Single"
                      : Math.abs(singleLeave.numDays) > 1
                      ? "Multiple"
                      : "-"}
                  </p>
                </div>
                <div className="eachRow">
                  <p className="w-3 left my-2">Date</p>
                  <p className="font-bold right my-2">
                    {DateTime.fromISO(singleLeave.startDate).toLocaleString(
                      DateTime.DATE_MED
                    )}{" "}
                    {singleLeave.endDate &&
                      !(singleLeave?.endDate === singleLeave?.startDate) &&
                      ` - ${DateTime.fromISO(
                        singleLeave.endDate
                      ).toLocaleString(DateTime.DATE_MED)}`}
                  </p>
                </div>
                <div className="eachRow">
                  <p className="w-3 left my-2">Apply Date</p>
                  <p className="font-bold right my-2">
                    {DateTime.fromISO(singleLeave.createdDate).toLocaleString(
                      DateTime.DATE_MED
                    )}
                  </p>
                </div>
                <div className="eachRow">
                  <p className="w-3 left my-2">Number Of Leaves</p>
                  <p className="font-bold right my-2">
                    {Math.abs(singleLeave.numDays)}
                  </p>
                </div>
                <div className="eachRow">
                  <p className="w-3 left my-2">Reason (optional)</p>
                  <p className="font-bold right my-2">
                    {singleLeave.reason || "-"}
                  </p>
                </div>
                <div className="eachRow">
                  <p className="w-3 left my-2">Status</p>
                  <Tag
                    value={
                      singleLeave.status.charAt(0).toUpperCase() +
                      singleLeave.status.substr(1).toLowerCase()
                    }
                    severity={colors[singleLeave.status]}
                    rounded={true}
                    className="h-1rem p-2 py-3 mt-2"
                  ></Tag>
                </div>
                <div className="eachRow">
                  <p className="w-3 left my-2">Reviewer Name</p>
                  <p className="font-bold right my-2">
                    {singleLeave.approver?.name || "-"}
                  </p>
                </div>
                <div className="eachRow">
                  <p className="w-3 left my-2">Reviewer Comments</p>
                  <p className="font-bold right my-2">
                    {singleLeave.approveComments
                      ? singleLeave.approveComments
                      : "-"}
                  </p>
                </div>
              </div>

              <div className="text-right">
                {singleLeave.status !== StatusEnum.APPROVED ? (
                  <Button
                    onClick={() => onHide("displayLeave")}
                    className="bg-transparent text-blue-500 py-1 px-6 MobileAcceptedButton"
                  >
                    Ok
                  </Button>
                ) : (
                  ""
                )}

                {singleLeave.status === StatusEnum.APPROVED && isPrivileged ? (
                  <>
                    <Button
                      label="Reject"
                      className="p-button-warning mr-4"
                      onClick={() => {
                        handleReject("displayRejectComment", "center");
                      }}
                    ></Button>
                    <Button
                      onClick={() => onHide("displayLeave")}
                      label="Ok"
                      className="p-button-success"
                    ></Button>

                    <Dialog
                      header="Reject Leave"
                      className="reject-modal"
                      visible={displayRejectComment}
                      onHide={() => onHide("displayRejectComment")}
                    >
                      <div className="flex flex-column">
                        <div className="">
                          <div className="my-2">
                            Please Provide the reason for rejecting leave
                          </div>
                          <InputTextarea
                            className="mt-3 my-2"
                            value={comments}
                            onChange={(e) => setComments(e.target.value)}
                            rows={5}
                            cols={60}
                            autoResize
                          />
                          <div className="eachRow">
                            <div className="mt-3 text-red-400">
                              {commentError ? "This field is required" : null}
                            </div>
                          </div>
                        </div>

                        <div className="text-right mt-1 mr-7">
                          <Button
                            className="bg-transparent text-blue-500 py-2 px-4 MobileAcceptedButton h-2rem"
                            onClick={() => onHide("displayRejectComment")}
                          >
                            Cancel
                          </Button>
                          <Button
                            disabled={rejectApprovedloading}
                            className=" py-2 px-4 ml-3 p-button-success h-2rem"
                            onClick={() =>
                              rejectApprovedLeave(singleLeave.leaveId)
                            }
                          >
                            {rejectApprovedloading ? (
                              <ProgressSpinner
                                className="custom-progress-spinner"
                                strokeWidth="10"
                              />
                            ) : (
                              "OK"
                            )}
                          </Button>
                        </div>
                      </div>
                    </Dialog>
                  </>
                ) : (
                  ""
                )}
              </div>
            </Dialog>
          )}
        </div>
      )}
    </div>
  );
};

export { LeaveRequestModal };
