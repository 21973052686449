import { useState, useEffect, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Card } from "primereact/card";
import warning from "../assets/images/warning.svg";
import { useNavigate } from "react-router-dom";
import { monthBodyTemplate } from "../components/PayrollTableTemplate";
import { employeeViewButtonTemplate } from "../components/PayrollTableTemplate";
import { Calendar } from "primereact/calendar";
import { useApi } from "../utils/services/useApi";
import { useToaster } from "../utils/services/useToaster";
import { DateTime } from "luxon";
import { Paginator } from "primereact/paginator";
import {
  currentPageReportType,
  onBasicPageChangeType,
} from "../types/PaginationType";
import { Spinner } from "../components/Spinner";

export const EmployeePayroll = () => {
  const { showToaster } = useToaster();
  const { get } = useApi(showToaster);
  const [displayBasic, setDisplayBasic] = useState(false);
  const [date, setDate] = useState<Date | Date[] | undefined>(undefined);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [size, setSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [leaveRequestData, setLeaveRequestData] = useState([]);
  const [totalRecords, setTotalRecords] = useState();
  const [basicFirst, setBasicFirst] = useState(0);
  const [pageLoader, setPageLoader] = useState(true);

  const handleOkClick = (date: any) => {
    if (!date) {
      setErrorMessage("This field is required.");
    } else {
      setErrorMessage("");
      const dateInString = new Date(date);
      const dateFormat = DateTime.fromJSDate(dateInString);
      const month = dateFormat.toFormat("MM");
      const year = dateFormat.toFormat("yyyy");
      const formattedDate = year + month;

      navigate(`/employee-payroll/all-employees/${formattedDate}`);
    }
  };

  const getAllEmployeePayroll = useCallback(async () => {
    try {
      const { content, totalElements } = await get(
        `/payroll/employees?number=${currentPage}&size=${size}`
      );
      setLeaveRequestData(content);
      setTotalRecords(totalElements);
      setPageLoader(false);
    } catch (err) {
      console.log(err);
    }
  }, [size, currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getAllEmployeePayroll();
  }, [getAllEmployeePayroll]);

  const pageTemplate = {
    layout:
      "RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport",
    CurrentPageReport: (options: currentPageReportType) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "auto",
            textAlign: "center",
          }}
        >
          Showing {options.first} to {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  const onBasicPageChange = (event: onBasicPageChangeType) => {
    setCurrentPage(event.page);
    setBasicFirst(event.first);
    setSize(event.rows);
  };

  const dialogFuncMap: any = {
    displayBasic: setDisplayBasic,
  };

  const onClick = (name: string) => {
    dialogFuncMap[`${name}`](true);
  };
  const onHide = (name: string) => {
    dialogFuncMap[`${name}`](false);
  };

  return (
    <>
      {pageLoader ? (
        <Spinner />
      ) : (
        <div
          className="px-4 py-3 employee-payroll-bg"
          id="employee-payroll-page"
        >
          <div id="">
            <div>
              <Card className="my-3">
                <div className="text-left font-bold px-2">Employee List</div>
              </Card>
            </div>
            <div className="text-right pb-3">
              <Button
                className="cust-btn-clr"
                onClick={() => onClick("displayBasic")}
              >
                {" "}
                Run Payroll for all
              </Button>
            </div>

            <DataTable value={leaveRequestData} id="Employee-payroll">
              <Column header="Employee Id" field="empId" exportable={false} />

              <Column header="Name" field="name" exportable={false} />

              <Column header="Email Id" field="email" exportable={false} />

              <Column
                header="Month"
                field="lastPayrollMonth"
                body={monthBodyTemplate}
                exportable={false}
              />

              <Column
                header="Action"
                field="empId"
                body={employeeViewButtonTemplate}
                exportable={false}
              ></Column>
            </DataTable>
          </div>
          <Paginator
            first={basicFirst}
            rows={size}
            totalRecords={totalRecords}
            template={pageTemplate}
            rowsPerPageOptions={[10, 20, 50]}
            onPageChange={onBasicPageChange}
          ></Paginator>

          <Dialog
            header="Run Payroll"
            visible={displayBasic}
            style={{ width: "35vw" }}
            onHide={() => onHide("displayBasic")}
            className="font-bold pb-0"
          >
            <div className="employee-modal" id="employee-modal">
              <div className="flex flex-row align-items-center ">
                <img className="mr-4" src={warning} alt="warning" />

                <p className="employee-modal-para mb-3">
                  Run payroll for all will only work with copying data from
                  previous month.
                </p>
              </div>
              <div className="ml-5 employee-payroll-calender">
                <Calendar
                  showIcon
                  id="monthpicker"
                  value={date}
                  onChange={(e) => setDate(e.value)}
                  view="month"
                  dateFormat="mm/yy"
                  placeholder="Please Select Month"
                />
                {errorMessage && (
                  <div style={{ color: "red" }}>{errorMessage}</div>
                )}
              </div>

              <div className="flex flex-row-reverse mt-2 employee-payroll-buttons">
                <Button
                  onClick={() => handleOkClick(date)}
                  className="cust-btn-clr"
                >
                  Ok
                </Button>
                <Button
                  onClick={() => onHide("displayBasic")}
                  label="Cancel"
                  className="p-button-outlined mr-2 cust-btn-text"
                />
              </div>
            </div>
          </Dialog>
        </div>
      )}
    </>
  );
};
