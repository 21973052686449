import { ReactNode, useState } from "react";
import { Header } from "./Header";
import { Sidebar } from "./Sidebar";
import { Hamburger } from "../Hamburger";
import hamburger from "../../assets/images/hamburger.svg";
import closeBtn from "../../assets/images/closeButton.svg";
import { Footer } from "../Footer";
import { useMediaQuery } from "react-responsive";
import { constantObj } from "../../utils/config/constantConfig";

export const Layout = ({ children }: { children: ReactNode }) => {
  const [toggle, setToggle] = useState<boolean>(false);
  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <>
      <div className="flex" id="DesktopView">
        <div className="sidebar-container">
          <Sidebar />
        </div>
        <div className="header-container flex flex-column shadow-1">
          <Header />
          <div className="container-height"> {children}</div>
          <Footer />
        </div>
      </div>

      {isMobile && (
        <div className="MobileHamburger">
          <div className="bg-clr2B px-2 py-3 h-4rem w-100">
            <button
              onClick={() => setToggle(!toggle)}
              className="button-color bg-clr2B border-none"
            >
              {!toggle ? (
                <img
                  src={hamburger}
                  alt="hamburger"
                  className="mobileHamburgerIcon"
                />
              ) : (
                <img
                  src={closeBtn}
                  alt="close button"
                  className="closeBtnIcon"
                />
              )}
            </button>
            <img
              src={constantObj.logo}
              alt=""
              className={`${
                constantObj.companyId === "softobotics"
                  ? "MobileLogoImg"
                  : "MobileLogoCloudprismImg"
              }`}
            />
          </div>{" "}
          <div>
            {toggle ? (
              <Hamburger setToggle={setToggle} toggle={toggle} />
            ) : (
              <div className="header-container flex flex-column shadow-1 w-full">
                {children}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
