import earnLeave from "../assets/images/earn-leave.svg";
import casualLeave from "../assets/images/casual-leave.svg";
import optionalLeave from "../assets/images/optional-leave.svg";
import wfh from "../assets/images/work-from-home.svg";
import { LeaveCardTypes } from "../types/LeaveCardTypes";
import { LeaveEnum } from "../enum/LeaveEnum";
import { constantObj } from "../utils/config/constantConfig";

export const LeavesCardData: LeaveCardTypes[] = [
  {
    id: 1,
    leaveType: "Earned Leaves",
    icon: earnLeave,
    targetId: "earned-leaves",
    apiName: LeaveEnum.EARNED,
    content:
      "Employees will be entitled to get 1.25 leaves per month, which will be accrued at the end of every working month. Unused earned leaves can be carried forwared to the next year, up to a maximum of 15 leaves.",
  },
  {
    id: 2,
    leaveType: "Casual Leaves",
    icon: casualLeave,
    targetId: "casual-leaves",
    apiName: LeaveEnum.CASUAL,
    content:
      "Employees will be entitled to 5 casual leaves every year, which will be accrued at the beginning of the English Calendar Year. Unused casual leaves are not carried forward to the next year.",
  },
  {
    id: 3,
    leaveType: "Optional Leaves",
    icon: optionalLeave,
    targetId: "optional-leaves",
    apiName: LeaveEnum.OPTIONAL,

    content: `Employees are entitled to 2 optional leaves per year, starting from the beginning of English Calendar Year. These leaves can only be availed on specified dates, on account of specific festivals as per company policy${
      constantObj.companyId === "softobotics"
        ? " and need to be applied in advance"
        : ""
    }. These dates may change every year depending on the change in dates of the occasion, however, the actual list of occasions will remain the same. Unused Optional Leaves cannot be carried forward to the next year.`,
  },
  {
    id: 4,
    leaveType: "WFH",
    icon: wfh,
    targetId: "wfh",
    apiName: LeaveEnum.WORKFROMHOME,

    content:
      " Employees will be entitled to get 2 WFH per month, which will be accrued at the end of every working month. Unused wfh leaves can be carried forwared to the next month.",
  },
];
