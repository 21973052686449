import { Card } from "primereact/card";
import "../assets/styles/myTeam.scss";
import { useEffect, useState, useCallback } from "react";
import { useApi } from "../utils/services/useApi";
import { useToaster } from "../utils/services/useToaster";
import { MyTeamType } from "../types/MyTeamType";
import { useNavigate } from "react-router";
import { Menu } from "primereact/menu";
import { useStore } from "../store/useStore";
import { RoleEnum } from "../enum/RoleEnum";
import { CompLeaveModal } from "../components/CompLeaveModal";
import { Spinner } from "../components/Spinner";
import { TabPanel, TabView } from "primereact/tabview";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import "primeicons/primeicons.css";
import warning from "../assets/images/warning.svg";
import { EmployeePayload } from "../types/EmployeePayload";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { DateTime } from "luxon";
import { InputSwitch } from "primereact/inputswitch";

export const MyTeam = () => {
  const { toast, showToaster } = useToaster();
  const { get, put, post } = useApi(showToaster);
  const { roles } = useStore();
  const [teamData, setTeamData] = useState<MyTeamType[] | null>(null);
  const [menuVisible, setMenuVisible] = useState<number>(-1);
  const [selectedEmp, setSelectedEmp] = useState<MyTeamType | null>(null);
  const [isCompLeaveVisible, setCompLeaveVisible] = useState<boolean>(false);
  const [myTeamLoader, setMyTeamLoader] = useState(true);
  const [archivedEmpLoader, setArchivedEmpLoader] = useState(false);
  const [newEmpLoader, setNewEmpLoader] = useState(false);

  const navigate = useNavigate();
  const isAdmin: boolean = roles.includes(RoleEnum.ADMIN);
  const [activeIndex, setActiveIndex] = useState(0);
  const [archivedEmployees, setArchivedEmployees] = useState<
    MyTeamType[] | null
  >(null);
  const [archiveModal, setArchivedModal] = useState(false);
  const [unArchiveModal, setUnArchiveModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [wfhModal, setWfhModal] = useState(false);
  const [wfhCount, setWfhCount] = useState<number | null>();
  const [editable, setEditable] = useState(false);
  const [timeOfficeId, setTimeOfficeId] = useState<string>();
  const [newEmployees, setNewEmployees] = useState<MyTeamType[] | null>(null);
  const [newEmployeeModal, setNewEmployeeModal] = useState(false);
  const [empId, setEmpId] = useState<string>();
  const [jobTitle, setJobTitle] = useState<string | null>();
  const [doj, setDoj] = useState<Date | Date[] | undefined>(undefined);
  const [wfhLeaves, setWfhLeaves] = useState<number>(0);
  const [casualLeaves, setCasualLeaves] = useState<number>(0);
  const [earnedLeaves, setEarnedLeaves] = useState<number>(0);
  const [optionalLeaves, setOptionalLeaves] = useState<number>(0);
  const [accNo, setAccNo] = useState<string>();
  const [pan, setPan] = useState<string>();
  const [uan, setUan] = useState<string>();
  const [pfNo, setPfNo] = useState<string>();
  const [bank, setBank] = useState<string>();
  const [empIdErrorMessage, setEmpIdErrorMessage] = useState<string | null>();
  const [dojErrorMessage, setDojErrorMessage] = useState<string | null>();
  const [wfhErrorMessage, setWfhErrorMessage] = useState<string | null>();
  const [casualErrorMessage, setCasualErrorMessage] = useState<string | null>();
  const [earnedErrorMessage, setEarnedErrorMessage] = useState<string | null>();
  const [optionalErrorMessage, setOptionalErrorMessage] = useState<
    string | null
  >();
  const [dojValue, setDojValue] = useState<Date | Date[] | undefined>(
    undefined
  );
  const [newEmpId, setNewEmpId] = useState<string | undefined>();
  const [adminToggle, setAdminToggle] = useState<boolean | undefined>(false);
  const [accountsToggle, setAccountsToggle] = useState<boolean | undefined>(
    false
  );
  const [leadsToggle, setLeadsToggle] = useState<boolean | undefined>(false);
  const [memberToggle, setMemberToggle] = useState<boolean | undefined>(false);
  const [modalLoader, setModalLoader] = useState<boolean | undefined>(true);

  const handleCalendar = () => {
    navigate(`/my-teams-calendar/${selectedEmp?.empId}`);
  };

  const handleLeaveHistory = () => {
    navigate(`/my-teams/${selectedEmp?.empId}`);
  };

  // const handleSimulatedLogin = () => {
  //   setMenuVisible(-1);
  // };

  const handleCompLeave = () => {
    setCompLeaveVisible(true);
    setMenuVisible(-1);
  };

  const handleInformation = () => {
    setMenuVisible(-1);
    setWfhModal(true);
  };

  const handleUnArchivedEmployeesStatus = () => {
    setMenuVisible(-1);
    setArchivedModal(true);
  };

  const handleArchivedEmployeeStatus = () => {
    setMenuVisible(-1);
    setUnArchiveModal(true);
  };

  const handleArchivedEmployees = () => {
    setLoader(true);
    editEmployee({ archive: false });
  };

  const handleUnArchivedEmployees = () => {
    setLoader(true);
    editEmployee({ archive: true });
  };

  const handleNewEmployeeStatus = () => {
    setMenuVisible(-1);
    setNewEmployeeModal(true);
  };

  const kebabOptions = [
    {
      label: "View Calendar",
      icon: "pi pi-fw pi-calendar",
      command: handleCalendar,
    },
    {
      label: "Leave History",
      icon: "pi pi-fw pi-clock",
      command: handleLeaveHistory,
    },
    // ...(isAdmin
    //   ? [
    //       {
    //         label: "Simulated Login",
    //         icon: "pi pi-fw pi-users",
    //         command: handleSimulatedLogin,
    //       },
    //     ]
    //   : []),
    ...(isAdmin
      ? [
          {
            label: "Add Comp Leave",
            icon: "pi pi-fw pi-plus",
            command: handleCompLeave,
          },
        ]
      : []),
    ...(isAdmin
      ? [
          {
            label: "Edit Info",
            icon: "pi pi-fw pi-pencil",
            command: handleInformation,
          },
        ]
      : []),
    ...(isAdmin
      ? [
          {
            label: "Archive",
            icon: "pi pi-fw pi-exclamation-circle",
            command: handleUnArchivedEmployeesStatus,
          },
        ]
      : []),
  ];

  const archivedEmpKebabOptions = [
    ...(isAdmin
      ? [
          {
            label: "Unarchive",
            icon: "pi pi-fw pi-undo",
            command: handleArchivedEmployeeStatus,
          },
        ]
      : []),
  ];

  const newEmpKebabOptions = [
    ...(isAdmin
      ? [
          {
            label: "Add to Portal",
            icon: "pi pi-fw pi-plus",
            command: handleNewEmployeeStatus,
          },
        ]
      : []),
  ];

  const handleError = () => {
    if (!empId) {
      setEmpIdErrorMessage("This field is required.");
    } else {
      setEmpIdErrorMessage(null);
    }
    if (!doj) {
      setDojErrorMessage("This field is required.");
    } else {
      setDojErrorMessage(null);
    }

    if (!wfhLeaves && wfhLeaves !== 0) {
      setWfhErrorMessage("This field is required.");
    } else {
      setWfhErrorMessage(null);
    }

    if (!casualLeaves && casualLeaves !== 0) {
      setCasualErrorMessage("This field is required.");
    } else {
      setCasualErrorMessage(null);
    }
    if (!earnedLeaves && earnedLeaves !== 0) {
      setEarnedErrorMessage("This field is required.");
    } else {
      setEarnedErrorMessage(null);
    }
    if (!optionalLeaves && optionalLeaves !== 0) {
      setOptionalErrorMessage("This field is required.");
    } else {
      setOptionalErrorMessage(null);
    }
  };

  const getArchivedEmployees = useCallback(async () => {
    try {
      if (activeIndex === 1) {
        setArchivedEmpLoader(true);
      }
      const res = await get(`employee`);
      setArchivedEmployees(res);
      setArchivedEmpLoader(false);
    } catch (err) {
      console.log(err);
    }
  }, [activeIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  const getMyTeam = useCallback(async () => {
    try {
      if (activeIndex === 0 && wfhModal) {
        setMyTeamLoader(true);
      }
      const res = await get(`my-team/direct-reports`);
      setTeamData(res);
      setMyTeamLoader(false);
    } catch (err) {
      console.log(err);
      setMyTeamLoader(false);
    }
  }, [wfhModal, activeIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAllNewEmployees = useCallback(async () => {
    try {
      if (activeIndex === 2) {
        setNewEmpLoader(true);
      }
      const res = await get(`employee/new-employees`);
      setNewEmployees(res);
      setNewEmpLoader(false);
    } catch (err) {
      console.log(err);
      setNewEmpLoader(false);
    }
  }, [activeIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (teamData) {
      setMyTeamLoader(false);
    }
  }, [teamData]);

  const addNewEmployee = async () => {
    try {
      setLoader(true);
      const payload = {
        empId: empId,
        displayName: selectedEmp?.displayName,
        mail: selectedEmp?.mail,
        jobTitle: jobTitle,
        timeOfficeId: timeOfficeId,
        dateOfJoining: formatDate(doj),
        casualLeaveDays: casualLeaves,
        earnedLeaveDays: earnedLeaves,
        optionalLeaveDays: optionalLeaves,
        wfhLeaveDays: wfhLeaves,
        accountNo: accNo,
        bank: bank,
        pan: pan,
        uan: uan,
        pfNo: pfNo,
      };
      const res = await post(`employee`, payload);
      if (res) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Employee Added Successfully",
          life: 3000,
        });
      }
      getAllNewEmployees();
      getMyTeam();
      setNewEmployeeModal(false);
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  const formatDate = (date: any) => {
    const dateInString = new Date(date);
    const dateFormat = DateTime.fromJSDate(dateInString);
    const day = dateFormat.toFormat("dd");
    const month = dateFormat.toFormat("MM");
    const year = dateFormat.toFormat("yyyy");
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };
  const editEmployee = useCallback(
    async (payload: EmployeePayload) => {
      try {
        await put(`employee/${selectedEmp?.empId}`, payload);
        setLoader(false);
        getMyTeam();
        getArchivedEmployees();
        if (editable) {
          setWfhModal(false);
          setEditable(false);
        }

        if (payload.archive) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Employee Archived Successfully",
            life: 3000,
          });
        }
        if (payload.archive === false) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Employee Unarchived Successfully",
            life: 3000,
          });
        }
        if (payload.wfh === 0 || payload.wfh) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "WFH Updated Successfully",
            life: 3000,
          });
        }
      } catch (error: any) {
        console.log(error);
        setLoader(false);
      }
    },
    [selectedEmp?.empId, editable] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const addWfh = () => {
    if (editable) {
      editEmployee({
        wfh: wfhCount,
        timeOfficeId: timeOfficeId?.toString(),
        member: memberToggle,
        leads: leadsToggle,
        accounts: accountsToggle,
        admin: adminToggle,
      });
    }
  };

  useEffect(() => {
    getMyTeam();
  }, [getMyTeam]);

  useEffect(() => {
    if (activeIndex === 1 && isAdmin) {
      getArchivedEmployees();
    }
    if (activeIndex === 2 && isAdmin) {
      getAllNewEmployees();
    }
  }, [getArchivedEmployees, getAllNewEmployees, activeIndex, isAdmin]);

  useEffect(() => {
    if (empId) {
      setEmpIdErrorMessage(null);
    }

    if (doj) {
      setDojErrorMessage(null);
    }

    if (wfhLeaves === 0 || wfhLeaves) {
      setWfhErrorMessage(null);
    }

    if (casualLeaves === 0 || casualLeaves) {
      setCasualErrorMessage(null);
    }

    if (optionalLeaves === 0 || optionalLeaves) {
      setOptionalErrorMessage(null);
    }

    if (earnedLeaves === 0 || earnedLeaves) {
      setEarnedErrorMessage(null);
    }
  }, [empId, wfhLeaves, casualLeaves, optionalLeaves, earnedLeaves, doj]);

  const setNewEmpData = (dateofjoining: string | undefined, empid: string) => {
    if (dateofjoining) {
      setDojValue(new Date(dateofjoining));
      setDoj(new Date(dateofjoining));
    }
    if (empid) {
      setNewEmpId(empid);
      setEmpId(empid);
    }
  };

  const setRoles = useCallback(async () => {
    setModalLoader(true);
    try {
      if (wfhModal) {
        const res = await get(`employee/${selectedEmp?.empId}`);
        setAccountsToggle(res.accounts);
        setLeadsToggle(res.leads);
        setMemberToggle(res.member);
        setAdminToggle(res.admin);
        setModalLoader(false);
      }
    } catch (error: any) {
      console.log(error);
      setModalLoader(false);
    }
  }, [wfhModal, selectedEmp?.empId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedEmp?.empId && wfhModal) {
      setRoles();
    }
  }, [
    wfhModal,
    selectedEmp?.empId,
    setRoles,
    memberToggle,
    adminToggle,
    accountsToggle,
    leadsToggle,
  ]);

  return (
    <>
      {myTeamLoader || archivedEmpLoader || newEmpLoader ? (
        <Spinner />
      ) : (
        <>
          <Toast ref={toast} />
          <div
            id="team-card-header"
            className="my-team-bg pt-3 h-full tmsContainer"
          >
            <TabView
              activeIndex={activeIndex}
              onTabChange={(e) => {
                setActiveIndex(e.index);
              }}
              className="my-team-bg tab-panel-bg"
            >
              <TabPanel header={`Current Team`}>
                <div className="myTeamMain my-team-bg" id="MobileTeam">
                  {!!teamData?.length &&
                    teamData.map((el: MyTeamType, index: number) => (
                      <Card
                        className="mx-3 md:m-3 MobileTeamEach border-round-md md:border-round-2xl my-team-card"
                        key={el.empId}
                      >
                        <div className="flex mb-4 team-ard">
                          <img
                            className="h-fit myTeamMobileProfile border-circle team-profile-image"
                            src={`data:image/png;base64,${el.profileImage}`}
                            alt="profile-img"
                          />
                          <div className="my-auto ml-3">
                            <p className="m-0 font-bold myTeamDisplayName">
                              {el.displayName}
                            </p>
                            <p className="m-0 titleColor myTeamTitle">
                              {el.jobTitle}
                            </p>
                            <p className="m-0 text-600 myTeamEmailId">
                              {el.empId}
                            </p>
                            <p className="m-0 text-600 myTeamEmailId">
                              {el.mail}
                            </p>
                            <p className="m-0 text-600 myTeamEmailId">
                              Time Office ID:
                              {el.timeOfficeId ? el.timeOfficeId : "-"}
                            </p>
                            <p className="m-0 text-600 myTeamEmailId">
                              WFH: {el.wfh ? el.wfh : "0"}
                            </p>
                          </div>
                          <div>
                            <i
                              className="pi pi-ellipsis-v cursor-pointer my-team-kebab-menu"
                              onClick={() => {
                                setMenuVisible(
                                  menuVisible === index ? -1 : index
                                );
                                setSelectedEmp(teamData[index]);
                                setWfhCount(el.wfh);
                                setTimeOfficeId(el.timeOfficeId);
                              }}
                            ></i>
                            <Menu
                              className="absolute team-kebab-menu"
                              hidden={menuVisible !== index}
                              model={kebabOptions}
                            />
                          </div>
                        </div>
                      </Card>
                    ))}
                  {selectedEmp && (
                    <CompLeaveModal
                      isVisible={isCompLeaveVisible}
                      onHide={() => setCompLeaveVisible(false)}
                      employee={selectedEmp}
                    />
                  )}
                </div>
              </TabPanel>
              {isAdmin && (
                <TabPanel header={`Archived Employees`}>
                  <div className="myTeamMain my-team-bg" id="MobileTeam">
                    {!!archivedEmployees?.length &&
                      archivedEmployees.map((el: MyTeamType, index: number) => (
                        <Card
                          className="mx-3 md:m-3 MobileTeamEach border-round-md md:border-round-2xl my-team-card"
                          key={el.empId}
                        >
                          <div className="flex mb-4 team-ard">
                            <img
                              className="h-fit myTeamMobileProfile border-circle team-profile-image"
                              src={`data:image/png;base64,${el.profileImage}`}
                              alt="profile-img"
                            />
                            <div className="my-auto ml-3">
                              <p className="m-0 font-bold myTeamDisplayName">
                                {el.displayName}
                              </p>
                              <p className="m-0 titleColor myTeamTitle">
                                {el.jobTitle}
                              </p>
                              <p className="m-0 text-600 myTeamEmailId">
                                {el.empId}
                              </p>
                              <p className="m-0 text-600 myTeamEmailId">
                                {el.mail}
                              </p>

                              <p className="m-0 text-600 myTeamEmailId">
                                Time Office ID:
                                {el.timeOfficeId ? el.timeOfficeId : "-"}
                              </p>
                              <p className="m-0 text-600 myTeamEmailId">
                                WFH: {el.wfh ? el.wfh : "0"}
                              </p>
                            </div>
                            <div>
                              <i
                                className="pi pi-ellipsis-v cursor-pointer my-team-kebab-menu"
                                onClick={() => {
                                  setMenuVisible(
                                    menuVisible === index ? -1 : index
                                  );
                                  setSelectedEmp(archivedEmployees[index]);
                                }}
                              ></i>
                              <Menu
                                className="absolute team-kebab-menu"
                                hidden={menuVisible !== index}
                                model={archivedEmpKebabOptions}
                              />
                            </div>
                          </div>
                        </Card>
                      ))}
                    {selectedEmp && (
                      <CompLeaveModal
                        isVisible={isCompLeaveVisible}
                        onHide={() => setCompLeaveVisible(false)}
                        employee={selectedEmp}
                      />
                    )}
                  </div>
                </TabPanel>
              )}
              {isAdmin && (
                <TabPanel header={`Add New Employee`}>
                  <div className="myTeamMain my-team-bg" id="MobileTeam">
                    {!!newEmployees?.length &&
                      newEmployees.map((el: MyTeamType, index: number) => (
                        <Card
                          className="mx-3 md:m-3 MobileTeamEach border-round-md md:border-round-2xl my-team-card"
                          key={el.id}
                        >
                          <div className="flex mb-4 team-ard">
                            <img
                              className="h-fit myTeamMobileProfile border-circle team-profile-image"
                              src={`data:image/png;base64,${el.profileImage}`}
                              alt="profile-img"
                            />
                            <div className="my-auto ml-3">
                              <p className="m-0 font-bold myTeamDisplayName">
                                {el.displayName}
                              </p>
                              <p className="m-0 titleColor myTeamTitle">
                                {el.jobTitle}
                              </p>
                              <p className="m-0 text-600 myTeamEmailId">
                                {el.empId}
                              </p>
                              <p className="m-0 text-600 myTeamEmailId">
                                {el.mail}
                              </p>
                            </div>
                            <div>
                              <i
                                className="pi pi-ellipsis-v cursor-pointer my-team-kebab-menu"
                                onClick={() => {
                                  setSelectedEmp(newEmployees[index]);
                                  setNewEmpData(el.dateOfJoining, el.empId);
                                  setMenuVisible(
                                    menuVisible === index ? -1 : index
                                  );
                                }}
                              ></i>
                              <Menu
                                className="absolute team-kebab-menu"
                                hidden={menuVisible !== index}
                                model={newEmpKebabOptions}
                              />
                            </div>
                          </div>
                        </Card>
                      ))}
                  </div>
                </TabPanel>
              )}
            </TabView>
            <Dialog
              header="Archive"
              visible={archiveModal}
              style={{ width: "35vw" }}
              onHide={() => {
                setArchivedModal(false);
              }}
              className="pb-0 payroll-modal"
            >
              <div className="flex MobileWithdrawTop">
                <img src={warning} alt="" />
                <div className="ml-3 flex align-items-center">
                  Are you sure that you want to archive that employee ?
                </div>
              </div>
              <div className="text-right mt-3">
                <Button
                  className="mr-3 bg-transparent border-0 text-blue-600"
                  onClick={() => {
                    setArchivedModal(false);
                  }}
                >
                  No
                </Button>
                <Button
                  className="cust-btn-clr"
                  onClick={() => {
                    setArchivedModal(false);
                    handleUnArchivedEmployees();
                  }}
                >
                  {loader ? (
                    <ProgressSpinner
                      className="custom-progress-spinner"
                      strokeWidth="10"
                    />
                  ) : (
                    "Yes"
                  )}
                </Button>
              </div>
            </Dialog>
            <Dialog
              header="Unrchive"
              visible={unArchiveModal}
              style={{ width: "35vw" }}
              onHide={() => {
                setUnArchiveModal(false);
              }}
              className="pb-0 payroll-modal"
            >
              <div className="flex MobileWithdrawTop">
                <img src={warning} alt="" />
                <div className="ml-3 flex align-items-center">
                  Are you sure that you want to unarchive that employee ?
                </div>
              </div>
              <div className="text-right mt-3">
                <Button
                  className="mr-3 bg-transparent border-0 text-blue-600"
                  onClick={() => {
                    setUnArchiveModal(false);
                  }}
                >
                  No
                </Button>
                <Button
                  className="cust-btn-clr"
                  onClick={() => {
                    setUnArchiveModal(false);
                    handleArchivedEmployees();
                  }}
                >
                  {loader ? (
                    <ProgressSpinner
                      className="custom-progress-spinner"
                      strokeWidth="10"
                    />
                  ) : (
                    "Yes"
                  )}
                </Button>
              </div>
            </Dialog>

            <Dialog
              header="Edit Information"
              visible={wfhModal}
              style={{ minWidth: "35vw" }}
              onHide={() => {
                setWfhModal(false);
                setEditable(false);
                setAccountsToggle(false);
                setAdminToggle(false);
                setMemberToggle(false);
                setLeadsToggle(false);
                setModalLoader(false);
              }}
              className="pb-0 payroll-modal"
            >
              {modalLoader && <Spinner />}
              <div className="bottom">
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Name</p>
                  <p className="font-bold right my-2">
                    {selectedEmp?.displayName}
                  </p>
                </div>
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Email</p>
                  <p className="font-bold right my-2">{selectedEmp?.mail}</p>
                </div>
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Employee ID</p>
                  <p className="font-bold right my-2">{selectedEmp?.empId}</p>
                </div>
                <div className="eachRow flex">
                  <p className="w-3 left my-2">WFH</p>
                  <InputNumber
                    onChange={(e) => setWfhCount(e.value)}
                    maxFractionDigits={1}
                    value={wfhCount}
                    disabled={!editable}
                  />
                </div>
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Time Office ID</p>
                  <InputText
                    className="mt-2"
                    onChange={(e) => setTimeOfficeId(e.target.value)}
                    value={timeOfficeId}
                    disabled={!editable}
                  />
                </div>
                <div className="eachRow flex">
                  <p className="w-3 left my-2 font-bold">Role</p>
                </div>
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Member</p>
                  <InputSwitch
                    className={`mt-2 ${
                      memberToggle ? "custom-switch-on" : "custom-switch-off"
                    }`}
                    checked={memberToggle}
                    onChange={(e) => {
                      setMemberToggle(e.value);
                      editEmployee({
                        member: e.value,
                        leads: leadsToggle,
                        accounts: accountsToggle,
                        admin: adminToggle,
                      });
                    }}
                  />
                </div>
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Team Lead</p>
                  <InputSwitch
                    className="mt-2"
                    checked={leadsToggle}
                    onChange={(e) => {
                      setLeadsToggle(e.value);
                      editEmployee({
                        member: memberToggle,
                        leads: e.value,
                        accounts: accountsToggle,
                        admin: adminToggle,
                      });
                    }}
                  />
                </div>
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Accounts</p>
                  <InputSwitch
                    className="mt-2"
                    checked={accountsToggle}
                    onChange={(e) => {
                      setAccountsToggle(e.value);
                      editEmployee({
                        member: memberToggle,
                        leads: leadsToggle,
                        accounts: e.value,
                        admin: adminToggle,
                      });
                    }}
                  />
                </div>
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Admin</p>
                  <InputSwitch
                    className="mt-2"
                    checked={adminToggle}
                    onChange={(e) => {
                      setAdminToggle(e.value);
                      editEmployee({
                        member: memberToggle,
                        leads: leadsToggle,
                        accounts: accountsToggle,
                        admin: e.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="flex justify-content-end mt-2">
                <Button
                  className="p-button-success"
                  onClick={() => {
                    addWfh();
                    setEditable(true);
                    editable && setLoader(true);
                  }}
                  disabled={loader}
                >
                  {!editable ? (
                    "Edit"
                  ) : loader ? (
                    <ProgressSpinner
                      className="custom-progress-spinner"
                      strokeWidth="10"
                    />
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </Dialog>

            <Dialog
              header="Add to Portal"
              visible={newEmployeeModal}
              style={{ minWidth: "35vw" }}
              onHide={() => {
                setNewEmployeeModal(false);
                setDoj(undefined);
                setEmpId("");
                setNewEmpId("");
                setDojValue(undefined);
              }}
              className="pb-0 payroll-modal"
            >
              <div className="bottom">
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Name</p>
                  <p className="font-bold right my-2">
                    <InputText
                      value={selectedEmp?.displayName}
                      disabled={!!selectedEmp?.displayName}
                    />
                  </p>
                </div>
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Email</p>
                  <p className="font-bold right my-2">
                    <InputText
                      value={selectedEmp?.mail}
                      disabled={!!selectedEmp?.mail}
                    />
                  </p>
                </div>
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Employee ID</p>
                  <p className="font-bold right my-2">
                    <InputText
                      value={empId}
                      onChange={(e: any) => {
                        setEmpId(e.target.value);
                      }}
                      disabled={!!newEmpId}
                    />
                    {empIdErrorMessage && (
                      <div style={{ color: "red" }}>{empIdErrorMessage}</div>
                    )}
                  </p>
                </div>
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Job Title</p>
                  <p className="font-bold right my-2">
                    {" "}
                    <InputText
                      value={selectedEmp?.jobTitle}
                      disabled={!!selectedEmp?.jobTitle}
                      onChange={() => setJobTitle(selectedEmp?.jobTitle)}
                    />
                  </p>
                </div>
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Date of Joining</p>
                  <p className="font-bold right my-2">
                    <Calendar
                      value={doj}
                      onChange={(e) => setDoj(e.value)}
                      dateFormat="dd/mm/yy"
                      disabled={!!dojValue}
                    />
                    {dojErrorMessage && (
                      <div style={{ color: "red" }}>{dojErrorMessage}</div>
                    )}
                  </p>
                </div>
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Time Office ID</p>
                  <p className="font-bold right my-2">
                    {" "}
                    <InputText
                      onChange={(e: any) => setTimeOfficeId(e.target.value)}
                    />
                  </p>
                </div>
                <div className="eachRow flex">
                  <p className="w-3 left my-2">WFH Leaves</p>
                  <p className="font-bold right my-2">
                    {" "}
                    <InputNumber
                      onChange={(e: any) => setWfhLeaves(e.value)}
                      minFractionDigits={2}
                      value={wfhLeaves}
                    />
                    {wfhErrorMessage && (
                      <div style={{ color: "red" }}>{wfhErrorMessage}</div>
                    )}
                  </p>
                </div>
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Casual Leaves</p>
                  <p className="font-bold right my-2">
                    {" "}
                    <InputNumber
                      onChange={(e: any) => setCasualLeaves(e.value)}
                      minFractionDigits={2}
                      value={casualLeaves}
                    />
                    {casualErrorMessage && (
                      <div style={{ color: "red" }}>{casualErrorMessage}</div>
                    )}
                  </p>
                </div>
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Earned Leaves</p>
                  <p className="font-bold right my-2">
                    {" "}
                    <InputNumber
                      onChange={(e: any) => setEarnedLeaves(e.value)}
                      minFractionDigits={2}
                      value={earnedLeaves}
                    />
                    {earnedErrorMessage && (
                      <div style={{ color: "red" }}>{earnedErrorMessage}</div>
                    )}
                  </p>
                </div>
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Optional Leaves</p>
                  <p className="font-bold right my-2">
                    {" "}
                    <InputNumber
                      onChange={(e: any) => setOptionalLeaves(e.value)}
                      minFractionDigits={2}
                      value={optionalLeaves}
                    />
                    {optionalErrorMessage && (
                      <div style={{ color: "red" }}>{optionalErrorMessage}</div>
                    )}
                  </p>
                </div>
              </div>
              <div className="eachRow flex">
                <p className="w-3 left my-2">Account No</p>
                <p className="font-bold right my-2">
                  {" "}
                  <InputText onChange={(e: any) => setAccNo(e.target.value)} />
                </p>
              </div>
              <div className="eachRow flex">
                <p className="w-3 left my-2">Bank</p>
                <p className="font-bold right my-2">
                  {" "}
                  <InputText onChange={(e: any) => setBank(e.target.value)} />
                </p>
              </div>
              <div className="eachRow flex">
                <p className="w-3 left my-2">PAN</p>
                <p className="font-bold right my-2">
                  {" "}
                  <InputText
                    onChange={(e: any) => setPan(e.target.value)}
                  />{" "}
                </p>
              </div>
              <div className="eachRow flex">
                <p className="w-3 left my-2">UAN</p>
                <p className="font-bold right my-2">
                  {" "}
                  <InputText
                    onChange={(e: any) => setUan(e.target.value)}
                  />{" "}
                </p>
              </div>
              <div className="eachRow flex">
                <p className="w-3 left my-2">PF No</p>
                <p className="font-bold right my-2">
                  {" "}
                  <InputText
                    onChange={(e: any) => setPfNo(e.target.value)}
                  />{" "}
                </p>
              </div>
              <div className="flex justify-content-end mt-2">
                <Button
                  className="p-button-success"
                  onClick={() => {
                    handleError();
                    if (
                      empId &&
                      doj &&
                      (wfhLeaves === 0 || wfhLeaves) &&
                      (casualLeaves === 0 || casualLeaves) &&
                      (optionalLeaves === 0 || optionalLeaves) &&
                      (earnedLeaves === 0 || earnedLeaves)
                    ) {
                      addNewEmployee();
                    }
                  }}
                  disabled={loader}
                >
                  {loader ? (
                    <ProgressSpinner
                      className="custom-progress-spinner"
                      strokeWidth="10"
                    />
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </Dialog>
          </div>
        </>
      )}
    </>
  );
};
