import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { EmployeeDetails } from "../components/EmployeeDetails";
import { useApi } from "../utils/services/useApi";
import { useToaster } from "../utils/services/useToaster";
import "../assets/styles/RunningPayroll.scss";
import { RunPayroll } from "../components/RunPayroll";
import { useFieldArray, useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import warning from "../assets/images/warning.svg";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { SetStateAction } from "react";
import Loader from "../components/Loader";

export const RunningTemplatePayroll = () => {
  const { id, date, templateid } = useParams();
  const [memberData, setMemberData] = useState<any>({});
  const { showToaster } = useToaster();
  const toast = useRef<any>(null);
  const { get, post } = useApi(showToaster);
  const navigate = useNavigate();
  const [displayRunPayroll, setdisplayRunPayroll] = useState(false);
  const [, setPosition] = useState("center");
  const [loading, setLoading] = useState(false);
  const [lop, setLop] = useState(0);
  const [clicked, setClicked] = useState<boolean>(false);

  const dialogFuncMap: any = {
    displayRunPayroll: setdisplayRunPayroll,
  };

  const handleRunPayroll = (
    name: string,
    position: SetStateAction<string> | undefined
  ) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name: string) => {
    dialogFuncMap[`${name}`](false);
  };

  const { control, setValue, watch, reset } = useForm({
    mode: "onChange",
  });

  const { fields: allowanceFields, remove: removeAllowance } = useFieldArray({
    control,
    name: "allowanceArray",
  });

  const { fields: deductionsFields, remove: removeDeduction } = useFieldArray({
    control,
    name: "deductionArray",
  });

  const getIndividualTeam = useCallback(async () => {
    try {
      const res = await get(`/employee-info/${id}`);
      setMemberData(res);
    } catch (err) {
      console.log(err);
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getIndividualTeam();
  }, [getIndividualTeam]);

  const getEmployeeTemplateData = useCallback(async () => {
    try {
      const res = await get(`/templates/${templateid}`);

      if (res.templateJson && res.templateJson.allowance) {
        const modifiedAllowanceTemplateData = res.templateJson.allowance.map(
          (obj: any) => ({
            label: obj.value,
            value: "",
            index: obj.index,
          })
        );
        setValue("allowanceArray", modifiedAllowanceTemplateData);
      }

      if (res.templateJson && res.templateJson.deduction) {
        const modifiedDeductionTemplateData = res.templateJson.deduction.map(
          (obj: any) => ({
            label: obj.value,
            value: "",
            index: obj.index,
          })
        );
        setValue("deductionArray", modifiedDeductionTemplateData);
      }
    } catch (err) {
      console.log(err);
    }
  }, [templateid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getEmployeeTemplateData();
  }, [getEmployeeTemplateData]);

  const handleOnSubmit = useCallback(
    async (data: any) => {
      try {
        setLoading(true);
        let payrollJsonPostData;
        if (
          (lop === 0 || lop) &&
          data.allowanceArray.length > 0 &&
          data.deductionArray.length > 0
        ) {
          payrollJsonPostData = {
            empId: id,
            month: Number(date),
            payrollJson: {
              allowance: data.allowanceArray,
              deduction: data.deductionArray,
            },
            lop: lop,
          };
          const { success } = await post(`payroll`, payrollJsonPostData);
          if (success) {
            setLoading(false);
            toast.current.show({
              severity: "success",
              summary: "Payroll Run Successfully",
              life: 1000,
            });
          }
          onHide("displayRunPayroll");
          setTimeout(() => {
            navigate("/employee-payroll");
          }, 1500);
        }
      } catch (err) {
        setLoading(false);
        onHide("displayRunPayroll");
        console.log(err);
      }
      reset();
    },
    [lop] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div className="running-payroll-bg">
      <Toast ref={toast} />
      <div className="running-payroll-details p-3 m-3">
        <EmployeeDetails
          memberData={memberData}
          backLink={`/employee-payroll/${id}`}
          date={date}
          hideAccountInfo={false}
          setLop={setLop}
          lop={lop}
          clicked={clicked}
        />
      </div>
      <Dialog
        header="Run Payroll"
        visible={displayRunPayroll}
        onHide={() => onHide("displayRunPayroll")}
      >
        <div className="flex">
          <img src={warning} alt="" />
          <div className="ml-3 flex align-items-center">
            Are you sure you want to run the payroll?
          </div>
        </div>
        <div className="text-right mt-3">
          <Button
            className="mr-3 bg-transparent border-0 text-blue-600"
            onClick={() => onHide("displayRunPayroll")}
          >
            No
          </Button>
          <Button
            className="cust-btn-clr"
            onClick={(e) => {
              e.preventDefault();
              handleOnSubmit(watch());
            }}
          >
            {loading && (
              <Loader
                color="#00BFFF"
                height={100}
                width={100}
                // timeout={3000} //3 secs
                visible={true}
              />
            )}
            <span>Yes</span>
          </Button>
        </div>
      </Dialog>
      <div className="running-payroll-components m-3 p-5">
        <form>
          <div className="flex justify-content-between  ">
            <div className="payroll-left-component w-6 pl-6">
              <RunPayroll
                header="Salary Components"
                name="allowanceArray"
                control={control}
                fields={allowanceFields}
                removeInput={removeAllowance}
              />
            </div>
            <div className="w-6 pl-6 ml-8">
              <RunPayroll
                header="Salary Deductions"
                name="deductionArray"
                control={control}
                fields={deductionsFields}
                removeInput={removeDeduction}
              />
            </div>
          </div>
          <div className="flex flex-row-reverse justify-content-between  mt-3 mr-4 running-payroll-components">
            <div>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/employee-payroll");
                }}
                className="p-button-outlined mr-2 ml-2"
              >
                Cancel
              </Button>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  lop === 0 || lop
                    ? handleRunPayroll("displayRunPayroll", "center")
                    : setClicked(true);
                }}
                className="cust-btn-clr"
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
