import { PayrollRunProps } from "../types/PayrollProps";
import { Controller } from "react-hook-form";
import { InputNumber } from "primereact/inputnumber";

export const RunPayroll = ({
  header,
  name,
  fields,
  removeInput,
  control,
}: PayrollRunProps) => {
  return (
    <div id="payroll-component">
      <h1 className="mb-5 text-base">{header}</h1>
      {fields.map((data: any, index: number) => (
        <div key={data.id} className="payroll-outerBox">
          <span className="ml-2">{data.label}</span>
          <div key={data.id} className="my-2 ml-2">
            <Controller
              name={`${name}.${index}.value`}
              control={control}
              rules={{ required: "This field is required" }}
              defaultValue={data.value}
              render={({ field }) => (
                <>
                  <InputNumber
                    {...field}
                    value={!!field.value ? field.value : ""}
                    onChange={(e) => field.onChange(e.value)}
                    minFractionDigits={2}
                  />
                </>
              )}
            />
            {fields.length > 1 && (
              <i
                onClick={() => removeInput(+index)}
                className="pi pi-times ml-2"
              ></i>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
