import React, { useState, useEffect, useCallback } from "react";
import { Card } from "primereact/card";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useStore } from "../store/useStore";
import "../assets/styles/calendar.scss";
import interactionPlugin from "@fullcalendar/interaction";
import info from "../assets/images/info.svg";
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";
import {
  DatesSetArg,
  EventClickArg,
  EventContentArg,
} from "@fullcalendar/core";
import { useApi } from "../utils/services/useApi";
import { useToaster } from "../utils/services/useToaster";
import punchInLogo from "../assets/images/punchInLogo.svg";
import punchOutLogo from "../assets/images/punchOutLogo.svg";
import timerLogo from "../assets/images/timerLogo.svg";
import { CalendarType, LeaveObj } from "../types/CalendarType";
import { useMediaQuery } from "react-responsive";
import { DateTime } from "luxon";
import { Spinner } from "../components/Spinner";
import { Tag, TagSeverityType } from "primereact/tag";
import { StatusColor } from "../constants/StatusColor";
import { StatusEnum } from "../enum/StatusEnum";

export const Calendar = () => {
  const { showToaster } = useToaster();
  const { get } = useApi(showToaster);
  const [clickedCurrentDate, setClickedCurrentDate] = useState<
    string | undefined
  >();
  const [calendarData, setCalendarData] = useState<CalendarType[]>([]);
  const [currentMonth, setCurrentMonth] = useState(
    (new Date().getMonth() + 1).toString().padStart(2, "0")
  );

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const { currentYearHoliday, nextYearHoliday, previousYearHoliday } =
    useStore();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [event, setEvent] = useState("");
  const [selectedEvent, setSelectedEvent] = useState<CalendarType>();
  const [pageLoader, setPageLoader] = useState(true);

  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const handleEventClick = (args: EventClickArg) => {
    const { event } = args;

    if (isMobile) {
      setEvent(event.title);
      const dateEvent: CalendarType | undefined = calendarData.find(
        (c) => new Date(c.date).toDateString() === event.start!.toDateString()
      );
      setClickedCurrentDate(
        DateTime.fromISO(dateEvent?.date!).toLocaleString(DateTime.DATE_MED)
      );
      setSelectedEvent(dateEvent);
      setShowModal(true);
    }
  };

  const getCalendarData = useCallback(
    async () => {
      try {
        const res = await get(`calendar/${currentMonth}${currentYear}`);
        setCalendarData(processCalendarData(res));
        setPageLoader(false);
      } catch (err) {
        console.log(err);
      }
    },
    [currentYearHoliday,previousYearHoliday,nextYearHoliday,currentMonth,currentYear] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (
      !!currentYearHoliday?.length &&
      !!previousYearHoliday?.length &&
      !!nextYearHoliday?.length
    ) {
      getCalendarData();
    }
  }, [
    currentYearHoliday,
    previousYearHoliday,
    nextYearHoliday,
    currentMonth,
    currentYear,
    getCalendarData,
  ]);

  const processCalendarData = useCallback(
    (calendarData: CalendarType[]) => {
      const currentYearHolidayData = currentYearHoliday.map((e) => {
        if (e.type === "MANDATORY") {
          return {
            title: e.name,
            date: e.date,
            display: "background",
            color: "rgba(34, 197, 94, 0.5)",
          };
        } else {
          return {
            title: e.name,
            date: e.date,
            display: "background",
            color: "yellow",
          };
        }
      });

      const nextYearHolidayData = nextYearHoliday.map((e) => {
        if (e.type === "MANDATORY") {
          return {
            title: e.name,
            date: e.date,
            display: "background",
            color: "rgba(34, 197, 94, 0.5)",
          };
        } else {
          return {
            title: e.name,
            date: e.date,
            display: "background",
            color: "yellow",
          };
        }
      });

      const previousYearHolidayData = previousYearHoliday?.map((e) => {
        if (e.type === "MANDATORY") {
          return {
            title: e.name,
            date: e.date,
            display: "background",
            color: "rgba(34, 197, 94, 0.5)",
          };
        } else {
          return {
            title: e.name,
            date: e.date,
            display: "background",
            color: "yellow",
          };
        }
      });

      const holidayData = [
        ...currentYearHolidayData,
        ...nextYearHolidayData,
        ...previousYearHolidayData,
      ];
      return calendarData
        .map((data: CalendarType) => {
          if (
            data.totalHours ||
            data.status ||
            data.punchOut ||
            data.punchIn || <data className="leaveList"></data>
          ) {
            return {
              ...data,
              display: "background",
              color: "#E7E7E7",
            };
          } else {
            return data;
          }
        })
        .map((data: CalendarType) => {
          if (data.status === "ABSENT") {
            return {
              ...data,
              className: "borderForAbsent",
            };
          } else if (data.status === "HALFDAY") {
            return {
              ...data,
              className: "borderForHalfday",
            };
          } else if (data.status === "LATE") {
            return {
              ...data,
              className: "borderForLate",
            };
          } else {
            return {
              ...data,
            };
          }
        })
        .map((data: CalendarType): CalendarType => {
          const holiday = holidayData.find(
            (holidayItem) => holidayItem.date === data.date
          );
          return {
            ...data,
            ...holiday,
          };
        });
    },
    [currentYearHoliday, previousYearHoliday, nextYearHoliday]
  );

  const eventContent = (eventInfo: EventContentArg) => {
    const {
      title,
      extendedProps: { status, leaveList, punchIn, punchOut, totalHours },
    } = eventInfo.event.toJSON();

    let statusClass;

    switch (status) {
      case "ABSENT":
        statusClass = "absentColor";
        break;
      case "HALFDAY":
        statusClass = "halfDayColor";
        break;
      case "LATE":
        statusClass = "lateColor";
        break;
      default:
        statusClass = "";
    }

    return (
      <>
        <div>
          {title && <p className="calendarText mb-0 mt-1">{title}</p>}
          {status && !leaveList.length && status !== "PRESENT" && (
            <p
              className={`calendarText capitalize calendar-font-size font-bold ${statusClass} punchIn`}
            >
              {status.toLowerCase()}
            </p>
          )}
          {leaveList &&
            leaveList.map((leave: LeaveObj) => (
              <>
                <p className="calendarText capitalize calendar-font-size font-bold punchIn flex-wrap align-items-baseline my-1">
                  {leave.type.toLowerCase() === "workfromhome"
                    ? "WFH"
                    : leave.type.toLowerCase()}
                  &nbsp;
                  {leave?.duration === -0.5 ? "(Half Day)" : ""} &nbsp;
                  {leave.status === StatusEnum.PENDING && (
                    <Tag
                      value={
                        leave.status.charAt(0).toUpperCase() +
                        leave.status.substr(1).toLowerCase()
                      }
                      className="ml-1"
                      severity={StatusColor[leave.status] as TagSeverityType}
                      rounded
                    ></Tag>
                  )}
                </p>
              </>
            ))}
          <div className="punchin-punchout pt-3">
            {punchIn && (
              <div className="punchIn">
                <img
                  width="18px"
                  height="18px"
                  src={punchInLogo}
                  alt="Punch In"
                  className="calendarText"
                />
                <p className="calendarText calendar-font-size font-bold">
                  {punchIn}
                </p>
              </div>
            )}
            {punchOut && (
              <div className="punchIn">
                <img
                  width="18px"
                  src={punchOutLogo}
                  alt="Punch In"
                  className="calendarText"
                />
                <p className="calendarText calendar-font-size font-bold">
                  {punchOut}
                </p>
              </div>
            )}
          </div>
          {totalHours && leaveList.length <= 1 && (
            <div className="punchIn">
              <img
                width="18px"
                src={timerLogo}
                alt="Punch In"
                className="calendarText"
              />
              <p className="calendarText calendar-font-size font-bold">
                {totalHours} Hrs
              </p>
            </div>
          )}
        </div>
      </>
    );
  };

  const handleDateChange = (dates: DatesSetArg) => {
    setCurrentMonth((dates.start.getMonth() + 1).toString().padStart(2, "0"));
    setCurrentYear(dates.start.getFullYear());
  };

  return (
    <>
      {pageLoader ? (
        <Spinner />
      ) : (
        <div className="calendar tmsContainer" id="calMain">
          <Card className="mb-2 calender-card-header ">
            <div className="calender-header flex justify-content-between">
              <div className="calender-header-text">Calendar</div>
              <div className="calender-tooltip-img">
                <img
                  src={info}
                  alt="tooltip-icon"
                  data-pr-position="down"
                  data-pr-placement="bottom"
                />
              </div>
            </div>

            <Tooltip target=".calender-tooltip-img" position="bottom">
              <div className="calender-tooltip">
                <div className="ml-3 mt-4 calender-tooltip-content">
                  <div>
                    <div className="flex">
                      <span className="round-circle round-circle-absent"></span>
                      <span className="absent">Absent</span>
                    </div>
                    <p className="tooltip-text">Not present at usual</p>
                  </div>

                  <div>
                    <div className="flex">
                      <span className="round-circle round-circle-holiday"></span>
                      <span className="holiday">Holiday</span>
                    </div>
                    <p className="tooltip-text">National or general day off</p>
                  </div>

                  <div>
                    <div className="flex">
                      <span className="round-circle round-circle-leave"></span>
                      <span className="leave">Leave Pending</span>
                    </div>
                    <p className="tooltip-text">
                      Leave is still on process to approve
                    </p>
                  </div>

                  <div className="mb-3">
                    <div className="flex">
                      <span className="round-circle round-circle-leave-taken"></span>
                      <span className="leave-taken">Leave Taken</span>
                    </div>
                    <p className="tooltip-text">Leave has been approved</p>
                  </div>

                  <div className="mb-3">
                    <div className="flex">
                      <span className="round-circle round-circle-early"></span>
                      <span className="early">Early Checkout</span>
                    </div>
                    <p className="tooltip-text">
                      Leaving earlier than usual time
                    </p>
                  </div>

                  <div className="mb-3">
                    <div className="flex ">
                      <span className="round-circle round-circle-half"></span>
                      <span className="half">Half Day</span>
                    </div>
                    <p className="tooltip-text">50% usual day off</p>
                  </div>
                </div>
              </div>
            </Tooltip>
          </Card>

          <FullCalendar
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "",
            }}
            plugins={[dayGridPlugin, interactionPlugin]}
            eventClick={handleEventClick}
            initialView="dayGridMonth"
            events={calendarData}
            firstDay={1}
            showNonCurrentDates={false}
            eventContent={eventContent}
            datesSet={handleDateChange}
          />
          <Dialog
            header={`${clickedCurrentDate}`}
            visible={showModal}
            onHide={() => setShowModal(false)}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "60vw" }}
          >
            <div>
              <p>{event}</p>
              {selectedEvent?.status && (
                <p className="capitalize">
                  Status: {selectedEvent.status.toLowerCase()}
                </p>
              )}
              {selectedEvent?.leaveList && (
                <p>
                  Leave Type :{" "}
                  {selectedEvent.leaveList.map((leave) => leave.type).join(", ")}
                </p>
              )}
              {selectedEvent?.punchIn && (
                <p>Log In :{selectedEvent.punchIn} </p>
              )}
              {selectedEvent?.punchOut && (
                <p>Log out :{selectedEvent.punchOut} </p>
              )}
            </div>
          </Dialog>
        </div>
      )}
    </>
  );
};
