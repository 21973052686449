import { Tag } from "primereact/tag";
import { Modal } from "./Modal";
import { DateTime } from "luxon";
import { LeaveRequestModal } from "./LeaveRequestModal";
import { leaveTypesObj } from "../constants/LeaveTypesObj";
export const ActionBodyTemplate = ({
  fetchLeaves,
  leaveId,
}: {
  fetchLeaves: () => void;
  leaveId: number;
}) => {
  return (
    <>
      <Modal fetchLeaves={fetchLeaves} leaveId={leaveId} />
    </>
  );
};

export const LeaveRequestBodyTemplate = ({
  onComplete,
  leaveId,
}: {
  onComplete: () => void;
  leaveId: number;
}) => {
  return (
    <>
      <LeaveRequestModal onComplete={onComplete} leaveId={leaveId} />
    </>
  );
};

export const statusBodyTemplate = ({ status }: { status: string }) => {
  const colors: any = {
    APPROVED: "success",
    ADDED: "info",
    PENDING: "warning",
    REJECTED: "danger",
  };

  return (
    <>
      <Tag
        value={status.charAt(0).toUpperCase() + status.substr(1).toLowerCase()}
        severity={colors[status]}
        rounded
      ></Tag>
    </>
  );
};

export const leaveTypeBodyTemplate = ({ leaveType }: { leaveType: string }) => {
  return <p>{leaveTypesObj[leaveType]}</p>;
};

export const startDateBodyTemplate = ({ startDate }: { startDate: string }) => {
  if (!startDate) return "NA";
  const formattedStartDate = DateTime.fromISO(startDate).toLocaleString(
    DateTime.DATE_MED
  );

  return <p>{formattedStartDate}</p>;
};

export const endDateBodyTemplate = ({ endDate }: { endDate: string }) => {
  if (!endDate) return "NA";
  const formattedEndDate = DateTime.fromISO(endDate).toLocaleString(
    DateTime.DATE_MED
  );
  return <p>{formattedEndDate}</p>;
};

export const createdDateBodyTemplate = ({
  createdDate,
}: {
  createdDate: string;
}) => {
  if (!createdDate) return "NA";
  const dt = DateTime.fromISO(createdDate, { zone: "utc" }).setZone("local");
  const formattedCreatedDate = dt.toLocaleString(DateTime.DATE_MED);

  // Convert the time to 12-hour format with AM/PM
  const hours = dt.hour > 12 ? dt.hour - 12 : dt.hour;
  const amPm = dt.hour >= 12 ? "PM" : "AM";
  const formattedTime12 = `${hours}:${dt.minute
    .toString()
    .padStart(2, "0")}:${dt.second.toString().padStart(2, "0")}${amPm}`;

  return <p>{formattedCreatedDate + ", " + formattedTime12}</p>;
};

export const holidayDateBodyTemplate = ({ date }: { date: string }) => {
  if (!date) return "NA";
  const formattedDate = DateTime.fromISO(date).toLocaleString(
    DateTime.DATE_MED
  );
  return <p>{formattedDate}</p>;
};

export const holidayDayBodyTemplate = ({ date }: { date: string }) => {
  if (!date) return "NA";
  const day = new Date(date).toLocaleDateString("en-US", { weekday: "long" });
  return <p>{day}</p>;
};

 
export const NumberOfLeavesBodyTemplate = ({
  numDays,
}: {
  numDays: number;
}) => {
  if (!numDays) return 0;
  return <p className="ml-6">{Math.abs(numDays)}</p>;
};

export const nameBodyTemplate = ({
  emp_Id: { name },
}: {
  emp_Id: { name: string };
}) => {
  return <p>{name || "NA"}</p>;
};
