import { DateTime } from "luxon";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";

export const monthBodyTemplate = ({
  lastPayrollMonth,
}: {
  lastPayrollMonth: string;
}) => {
  if (lastPayrollMonth === "null") {
    return "NA";
  }
  const monthName = DateTime.fromFormat(lastPayrollMonth, "yyyyMM").toFormat(
    "MMMM"
  );
  return (
    <>
      <Tag className="mr-2" value={monthName} rounded></Tag>
    </>
  );
};

export const monthPayrollBodyTemplate = ({ month }: { month: string }) => {
  const monthName = DateTime.fromFormat(month, "yyyyMM").toFormat("MMMM");
  return (
    <>
      <p className="mr-2">{monthName}</p>
    </>
  );
};

export const yearPayrollBodyTemplate = ({ month }: { month: string }) => {
  const date = DateTime.fromFormat(month, "yyyyLL");
  const year = date.year.toString();
  return (
    <>
      <p className="mr-2">{year}</p>
    </>
  );
};

export const monthYearBodyTemplate = ({
  lastPayrollMonth,
}: {
  lastPayrollMonth: string;
}) => {
  if (lastPayrollMonth === "null") {
    return "NA";
  }
  const year = lastPayrollMonth.slice(0, 4);
  const month = lastPayrollMonth.slice(4, 6);
  const date = DateTime.fromObject({
    year: parseInt(year),
    month: parseInt(month),
    day: 1,
  }).toFormat("MMMM yyyy");

  return (
    <>
      <p className="mr-2">{date}</p>
    </>
  );
};

export const viewPayrollBodyTemplate = ({ id }: { id: number }) => {
  return (
    <Button className="cust-view-btn">
      <Link to={`/payroll-template/${id}`}>View</Link>
    </Button>
  );
};

export const employeeViewButtonTemplate = ({ empId }: { empId: number }) => {
  return (
    <Button className="cust-view-btn">
      <Link to={`/employee-payroll/${empId}`}>View</Link>
    </Button>
  );
};
