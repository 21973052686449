import { useState, useEffect, useCallback, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { viewPayrollBodyTemplate } from "../components/PayrollTableTemplate";
import { Card } from "primereact/card";
import { TemplateJson } from "../types/PayrollComponent";
import { useApi } from "../utils/services/useApi";
import { useToaster } from "../utils/services/useToaster";
import { Toast } from "primereact/toast";
import { Spinner } from "../components/Spinner";

export const ManagePayrollTemplate = () => {
  const toast = useRef<any>(null);
  const [templateJson, setTemplateJson] = useState<TemplateJson[]>([]);
  const { showToaster } = useToaster();
  const { get } = useApi(showToaster);
  const [pageLoader, setPageLoader] = useState(true);

  const getAllPayrollTemplate = useCallback(
    async () => {
      try {
        const data = await get(`/templates`);
        setTemplateJson(data);
        setPageLoader(false)
      } catch (err) {
        console.log(err);
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    getAllPayrollTemplate();
  }, [getAllPayrollTemplate]);

  const navigate = useNavigate();

  //navigate to register page
  const navigateToPayroll = () => {
    navigate("/payroll-template/create");
  };

  return (
    <>
      {pageLoader ? (
        <Spinner />
      ) : (
        <div id="payroll-card-header" className="payroll-template-bg px-4 py-3">
          <Toast ref={toast} />
          <div>
            <Card className="my-3">
              <div className="text-left font-bold px-5">Templates</div>
            </Card>
          </div>
          <div className="text-right pb-3">
            <Button className="cust-btn-clr" onClick={navigateToPayroll}>
              {" "}
              + Create New
            </Button>
          </div>

          <div className="card">
            <DataTable
              value={templateJson}
              sortField="id"
              responsiveLayout="scroll"
            >
              <Column field="id" header="Template ID"></Column>
              <Column field="name" header="Template name"></Column>
              <Column
                header="Action"
                field="id"
                body={viewPayrollBodyTemplate}
                exportable={false}
              ></Column>
            </DataTable>
          </div>
        </div>
      )}
    </>
  );
};
