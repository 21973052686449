import { Tooltip } from "primereact/tooltip";
import { NavLink } from "react-router-dom";
import { useStore } from "../../store/useStore";

export const Header = () => {
  const { logout,profileImage } = useStore();
  
  return (
    <>
      <div className="sticky top-0 z-2  bg-white w-full h-4rem flex justify-content-end align-items-center pr-5">
        <img
          src={`data:image/png;base64,${profileImage}`}
          alt="Profile Icon"
          width="32px"
          height="32px"
          id="profile"
          data-pr-position="bottom"
          className="headerProfileImage"
        />
      </div>
      <Tooltip
        target="#profile"
        className="w-6rem text-center"
        autoHide={false}
      >
        <NavLink to="/profile" className="no-underline text-black-alpha-90">
          <p className="profile-active">Profile</p>
        </NavLink>

        <div
          role="button"
          className="pointer-events-auto profile-active cursor-pointer"
          onClick={logout}
        >
          <span>Logout</span>
        </div>
      </Tooltip>
    </>
  );
};
