import { useParams } from "react-router-dom";
import { EmployeeDetails } from "../components/EmployeeDetails";
import { useState, useEffect, useCallback } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useStore } from "../store/useStore";
import "../assets/styles/calendar.scss";
import interactionPlugin from "@fullcalendar/interaction";
import { Dialog } from "primereact/dialog";
import {
  DatesSetArg,
  EventClickArg,
  EventContentArg,
} from "@fullcalendar/core";
import { useApi } from "../utils/services/useApi";
import { useToaster } from "../utils/services/useToaster";
import punchInLogo from "../assets/images/punchInLogo.svg";
import punchOutLogo from "../assets/images/punchOutLogo.svg";
import timerLogo from "../assets/images/timerLogo.svg";
import { CalendarType, LeaveObj } from "../types/CalendarType";
import { useMediaQuery } from "react-responsive";
import { DateTime } from "luxon";
import { StatusEnum } from "../enum/StatusEnum";
import { Tag, TagSeverityType } from "primereact/tag";
import { StatusColor } from "../constants/StatusColor";

export const EmployeeCalendar = () => {
  const { showToaster } = useToaster();
  const { get } = useApi(showToaster);
  const [clickedCurrentDate, setClickedCurrentDate] = useState<
    string | undefined
  >();
  const [calendarData, setCalendarData] = useState<CalendarType[]>([]);
  const [currentMonth, setCurrentMonth] = useState(
    (new Date().getMonth() + 1).toString().padStart(2, "0")
  );

  const [memberData, setMemberData] = useState<any>({});
  const { id } = useParams<string>();
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const { currentYearHoliday, nextYearHoliday, previousYearHoliday } =
    useStore();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [event, setEvent] = useState("");
  const [selectedEvent, setSelectedEvent] = useState<CalendarType>();

  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const handleEventClick = (args: EventClickArg) => {
    const { event } = args;

    if (isMobile) {
      setEvent(event.title);
      const dateEvent: CalendarType | undefined = calendarData.find(
        (c) => new Date(c.date).toDateString() === event.start!.toDateString()
      );
      setClickedCurrentDate(
        DateTime.fromISO(dateEvent?.date!).toLocaleString(DateTime.DATE_MED)
      );
      setSelectedEvent(dateEvent);
      setShowModal(true);
    }
  };

  const getCalendarData = useCallback(async () => {
    try {
      const res = await get(
        `calendar/${currentMonth}${currentYear}?empId=${id}`
      );
      setCalendarData(processCalendarData(res));
    } catch (err) {
      console.log(err);
    }
  }, [currentYearHoliday,previousYearHoliday,nextYearHoliday,currentMonth,currentYear]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !!currentYearHoliday?.length &&
      !!previousYearHoliday?.length &&
      !!nextYearHoliday?.length
    ) {
      getCalendarData();
    }
  }, [
    currentYearHoliday,
    previousYearHoliday,
    nextYearHoliday,
    currentMonth,
    currentYear,
    getCalendarData,
  ]);

  const processCalendarData = useCallback(
    (calendarData: CalendarType[]) => {
      const currentYearHolidayData = currentYearHoliday.map((e) => {
        if (e.type === "MANDATORY") {
          return {
            title: e.name,
            date: e.date,
            display: "background",
            color: "rgba(34, 197, 94, 0.5)",
          };
        } else {
          return {
            title: e.name,
            date: e.date,
            display: "background",
            color: "yellow",
          };
        }
      });

      const nextYearHolidayData = nextYearHoliday.map((e) => {
        if (e.type === "MANDATORY") {
          return {
            title: e.name,
            date: e.date,
            display: "background",
            color: "rgba(34, 197, 94, 0.5)",
          };
        } else {
          return {
            title: e.name,
            date: e.date,
            display: "background",
            color: "yellow",
          };
        }
      });

      const previousYearHolidayData = previousYearHoliday?.map((e) => {
        if (e.type === "MANDATORY") {
          return {
            title: e.name,
            date: e.date,
            display: "background",
            color: "rgba(34, 197, 94, 0.5)",
          };
        } else {
          return {
            title: e.name,
            date: e.date,
            display: "background",
            color: "yellow",
          };
        }
      });

      const holidayData = [
        ...currentYearHolidayData,
        ...nextYearHolidayData,
        ...previousYearHolidayData,
      ];
      return calendarData
        .map((data: CalendarType) => {
          if (
            data.totalHours ||
            data.status ||
            data.punchOut ||
            data.punchIn ||
            data.leaveList
          ) {
            return {
              ...data,
              display: "background",
              color: "#E7E7E7",
            };
          } else {
            return data;
          }
        })
        .map((data: CalendarType) => {
          if (data.status === "ABSENT") {
            return {
              ...data,
              className: "borderForAbsent",
            };
          } else if (data.status === "HALFDAY") {
            return {
              ...data,
              className: "borderForHalfday",
            };
          } else if (data.status === "LATE") {
            return {
              ...data,
              className: "borderForLate",
            };
          } else {
            return {
              ...data,
            };
          }
        })
        .map((data: CalendarType): CalendarType => {
          const holiday = holidayData.find(
            (holidayItem) => holidayItem.date === data.date
          );
          return {
            ...data,
            ...holiday,
          };
        });
    },
    [currentYearHoliday, previousYearHoliday, nextYearHoliday]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const eventContent = (eventInfo: EventContentArg) => {
    const {
      title,
      extendedProps: { status, leaveList, punchIn, punchOut, totalHours },
    } = eventInfo.event.toJSON();

    let statusClass;

    switch (status) {
      case "ABSENT":
        statusClass = "absentColor";
        break;
      case "HALFDAY":
        statusClass = "halfDayColor";
        break;
      case "LATE":
        statusClass = "lateColor";
        break;
      default:
        statusClass = "";
    }

    return (
      <>
        <div>
          {title && <p className="calendarText">{title}</p>}
          {status && !leaveList.length && status !== "PRESENT" && (
            <p
              className={`calendarText capitalize calendar-font-size font-bold ${statusClass} punchIn`}
            >
              {status.toLowerCase()}
            </p>
          )}
          {leaveList &&
            leaveList.map((leave: LeaveObj) => (
              <p className="calendarText capitalize calendar-font-size font-bold punchIn flex-wrap align-items-baseline my-1">
                {leave.type.toLowerCase() === "workfromhome"
                  ? "WFH"
                  : leave.type.toLowerCase()}
                &nbsp;
                {leave?.duration === -0.5 ? "(Half Day)" : ""}
                {leave.status === StatusEnum.PENDING && (
                  <Tag
                    value={
                      leave.status.charAt(0).toUpperCase() +
                      leave.status.substr(1).toLowerCase()
                    }
                    className="ml-1"
                    severity={StatusColor[leave.status] as TagSeverityType}
                    rounded
                  ></Tag>
                )}
              </p>
            ))}
          {punchIn && (
            <div className="punchIn">
              <img
                width="20px"
                height="20px"
                src={punchInLogo}
                alt="Punch In"
                className="calendarText"
              />
              <p className="calendarText calendar-font-size font-bold">
                {punchIn}
              </p>
            </div>
          )}
          {punchOut && (
            <div className="punchIn">
              <img
                width="20px"
                src={punchOutLogo}
                alt="Punch In"
                className="calendarText"
              />
              <p className="calendarText calendar-font-size font-bold">
                {punchOut}
              </p>
            </div>
          )}
          {totalHours && leaveList.length <= 1 && (
            <div className="punchIn">
              <img
                width="20px"
                src={timerLogo}
                alt="Punch In"
                className="calendarText"
              />
              <p className="calendarText calendar-font-size font-bold">
                {totalHours} Hrs
              </p>
            </div>
          )}
        </div>
      </>
    );
  };

  const handleDateChange = (dates: DatesSetArg) => {
    setCurrentMonth((dates.start.getMonth() + 1).toString().padStart(2, "0"));
    setCurrentYear(dates.start.getFullYear());
  };

  const getIndividualTeam = useCallback(async () => {
    try {
      const res = await get(`/employee-info/${id}`);
      setMemberData(res);
    } catch (err) {
      console.log(err);
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getIndividualTeam();
  }, [getIndividualTeam]);

  return (
    <div className="mx-3 tmsContainer">
      <div id="" className="my-team-bg p-3 mb-2">
        <EmployeeDetails
          memberData={memberData}
          backLink="/my-team"
          hideAccountInfo={true}
        />
      </div>
      <FullCalendar
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "",
        }}
        plugins={[dayGridPlugin, interactionPlugin]}
        eventClick={handleEventClick}
        initialView="dayGridMonth"
        events={calendarData}
        firstDay={1}
        showNonCurrentDates={false}
        eventContent={eventContent}
        datesSet={handleDateChange}
      />
      <Dialog
        header={`${clickedCurrentDate}`}
        visible={showModal}
        onHide={() => setShowModal(false)}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "60vw" }}
      >
        <div>
          <p>{event}</p>
          {selectedEvent?.status && (
            <p className="capitalize">
              Status: {selectedEvent.status.toLowerCase()}
            </p>
          )}
          {selectedEvent?.leaveList.length && (
            <p>
              Leave Type :{" "}
              {selectedEvent.leaveList.map((leave: LeaveObj) => leave.type).join(", ")}
            </p>
          )}
          {selectedEvent?.punchIn && <p>Log In :{selectedEvent.punchIn} </p>}
          {selectedEvent?.punchOut && <p>Log out :{selectedEvent.punchOut} </p>}
        </div>
      </Dialog>
    </div>
  );
};
