import { useEffect, useState, useCallback, useRef } from "react";
import { Payroll } from "../components/Payroll";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { useForm, useFieldArray } from "react-hook-form";
import { useApi } from "../utils/services/useApi";
import { useToaster } from "../utils/services/useToaster";
import { Toast } from "primereact/toast";
import { useParams, useNavigate } from "react-router";
import { Dialog } from "primereact/dialog";
import warning from "../assets/images/warning.svg";
import "../assets/styles/payrollPage.scss";
import Loader from "../components/Loader";
import { ProgressSpinner } from "primereact/progressspinner";

export const PayrollPage = () => {
  const [displayDeleteWarning, setdisplayDeleteWarning] = useState(false);
  const [visibleButton, setVisibleButton] = useState<boolean>(true);

  const { showToaster } = useToaster();
  const { post, get, put, httpDelete } = useApi(showToaster);
  const [, setPosition] = useState("center");
  const toast = useRef<any>(null);
  const { id } = useParams<string>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const dialogFuncMap: any = {
    displayDeleteWarning: setdisplayDeleteWarning,
  };

  const handleDelete = (
    name: string,
    position: React.SetStateAction<string> | undefined
  ) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name: string) => {
    dialogFuncMap[`${name}`](false);
  };

  const {
    control,
    handleSubmit,
    reset,
    register,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
  });

  const watchedFormData = watch();
  const className = errors.name ? "input-error" : "";

  const {
    fields: allowanceFields,
    append: appendAllowance,
    remove: removeAllowance,
  } = useFieldArray({
    control,
    name: "allowanceArray",
  });

  const {
    fields: deductionsFields,
    append: appendDeduction,
    remove: removeDeduction,
  } = useFieldArray({
    control,
    name: "deductionArray",
  });

  const getIndividualTemplate = useCallback(async () => {
    try {
      const res = await get(`templates/${id}`);
      setVisibleButton(false);
      setValue("name", res.name);
      setValue("allowanceArray", res.templateJson.allowance);
      setValue("deductionArray", res.templateJson.deduction);
    } catch (err) {
      console.log(err);
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    getIndividualTemplate();
  }, [getIndividualTemplate]);

  const handlePutRequest = async () => {
    try {
      setLoading(true);
      let updatedData;
      if (
        watchedFormData.name &&
        watchedFormData.allowanceArray.length > 0 &&
        watchedFormData.deductionArray.length > 0
      ) {
        updatedData = {
          name: watchedFormData.name,
          templateJson: {
            allowance: watchedFormData.allowanceArray.map(
              (item: any, index: number) => {
                return { ...item, index };
              }
            ),
            deduction: watchedFormData.deductionArray.map(
              (item: any, index: number) => {
                return { ...item, index };
              }
            ),
          },
        };

        const { success } = await put(`templates/${id}`, updatedData);

        if (success) {
          setLoading(false);
          toast.current.show({
            severity: "success",
            summary: "Template Edited Successfully",
            life: 1000,
          });
        }
        setTimeout(() => {
          navigate("/payroll-template");
        }, 1500);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleDeleteRequest = async () => {
    try {
      const { success } = await httpDelete(`templates/${id}`);
      if (success) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Template deleted successfully",
          life: 3000,
        });
      }
      onHide("displayDeleteWarning");
      setTimeout(() => {
        navigate("/payroll-template");
      }, 1500);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleOnSubmit = useCallback(
    async (data: any) => {
      data.allowanceArray = data.allowanceArray.map(
        (item: any, index: number) => {
          return { ...item, index: index };
        }
      );

      data.deductionArray = data.deductionArray.map(
        (item: any, index: number) => {
          return { ...item, index: index };
        }
      );

      try {
        let payrollJson;
        setLoading(true);
        if (
          data.name &&
          data.allowanceArray.length > 0 &&
          data.deductionArray.length > 0
        ) {
          payrollJson = {
            name: data.name,
            templateJson: {
              allowance: data.allowanceArray,
              deduction: data.deductionArray,
            },
          };

          const { success } = await post(`templates/add-template`, payrollJson);
          if (success) {
            setLoading(false);
            toast.current.show({
              severity: "success",
              summary: "Template Added Successfully",
              life: 3000,
            });
          }
          setTimeout(() => {
            navigate("/payroll-template");
          }, 1500);
        }
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
      reset();
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <div className="payroll-page-bg px-6 py-3">
          <Toast ref={toast} />

          <div id="payroll-page-header">
            <Card className="mb-3">
              <div className="text-left font-bold px-5 flex align-items-center">
                <label htmlFor="username2" className="text-base line-height-3">
                  New Payroll Template :
                </label>
                <InputText
                  className={`payroll-page-inputBox block ml-2 ${className}`}
                  {...register("name", { required: true })}
                  placeholder="Enter Template name"
                />
              </div>
            </Card>
          </div>

          <div id="payroll-page-content" className="mb-3">
            <Card>
              <div className="flex flex-row space-between px-5">
                <div className="w-6 pr-6 payroll-left-component">
                  <Payroll
                    header="Salary Components"
                    name="allowanceArray"
                    control={control}
                    fields={allowanceFields}
                    appendInput={appendAllowance}
                    removeInput={removeAllowance}
                  />
                </div>
                <div className="w-6 pl-6 ml-8">
                  <Payroll
                    header="Salary deductions"
                    name="deductionArray"
                    control={control}
                    fields={deductionsFields}
                    appendInput={appendDeduction}
                    removeInput={removeDeduction}
                  />
                </div>
              </div>
              <Dialog
                header="Delete"
                visible={displayDeleteWarning}
                onHide={() => onHide("displayDeleteWarning")}
              >
                <div className="flex MobileWithdrawTop">
                  <img src={warning} alt="warningmsg" />
                  <div className="ml-3 flex align-items-center">
                    Are you sure that you want to delete the template?
                  </div>
                </div>
                <div className="text-right mt-3">
                  <Button
                    className="mr-3 bg-transparent border-0 text-blue-600"
                    onClick={() => onHide("displayDeleteWarning")}
                  >
                    No
                  </Button>
                  <Button
                    disabled={loading}
                    className=" delete-btn-clr"
                    onClick={(e) => {
                      e.preventDefault();
                      handleDeleteRequest();
                      setLoading(true);
                    }}
                  >
                    {loading ? (
                      <ProgressSpinner
                        className="custom-progress-spinner"
                        strokeWidth="10"
                      />
                    ) : (
                      "Yes"
                    )}
                  </Button>
                </div>
              </Dialog>

              <div className="flex flex-row-reverse justify-content-between  mt-5">
                <div>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      reset();
                      navigate("/payroll-template");
                    }}
                    className="p-button-outlined mr-2 ml-2"
                  >
                    Cancel
                  </Button>
                  {!visibleButton ? (
                    <Button
                      disabled={loading}
                      onClick={(event) => {
                        event.preventDefault();
                        handlePutRequest();
                      }}
                      className="cust-btn-clr h-3rem"
                    >
                      {loading && (
                        <Loader
                          color="#00BFFF"
                          height={100}
                          width={100}
                          // timeout={3000} //3 secs
                          visible={true}
                        />
                      )}
                      <span>Save</span>
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      disabled={loading}
                      className="cust-btn-clr"
                    >
                      {loading && (
                        <Loader
                          color="#00BFFF"
                          height={100}
                          width={100}
                          // timeout={3000} //3 secs
                          visible={true}
                        />
                      )}
                      <span>Save</span>
                    </Button>
                  )}
                </div>
                <div>
                  {!visibleButton ? (
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleDelete("displayDeleteWarning", "center");
                      }}
                      className=" delete-btn-clr ml-5"
                    >
                      <span>Delete</span>
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Card>
          </div>
        </div>
      </form>
    </>
  );
};
