import create from "zustand";
import { HolidayType } from "../types/HolidayType";
import { ProfileType } from "../types/ProfileType";
import { useCookies } from "../utils/services/useCookies";

type Store = {
  token: string;
  profileImage: string;
  roles: string[];
  currentYearHoliday: HolidayType[];
  nextYearHoliday: HolidayType[];
  previousYearHoliday: HolidayType[];
  profile: ProfileType;
  login: (token: string) => void;
  logout: () => void;
  setCurrentYearHolidays: (currentYearHoliday: HolidayType[]) => void;
  setNextYearHolidays: (nextYearHoliday: HolidayType[]) => void;
  setPreviousYearHolidays: (previousYearHoliday: HolidayType[]) => void;
  storeProfile: (profile: ProfileType) => void;
  storeProfileImage: (Image: string | undefined ) => void;
  storeRole:(roles:string[])=>void
};

const initialProfile = {
  displayName: "",
  department: "",
  employeeId: "",
  jobTitle: "",
  mobilePhone: "",
  officeLocation: "",
  userPrincipalName: "",
  employeeHireDate: "",
  roles: [],
};
export const useStore = (() => {
  const { clearCookies } = useCookies();
  return create<Store>((set) => ({
    token: "",
    profileImage: "",
    roles: [],
    currentYearHoliday: [],
    nextYearHoliday: [],
    previousYearHoliday: [],
    profile: initialProfile,
    login: (token: string) => {
      set({
        token,
      });
    },
    logout: () => {
      clearCookies();
      set({
        token: undefined,
      });
    },
    setCurrentYearHolidays: (currentYearHoliday: HolidayType[]) => {
      set({
        currentYearHoliday,
      });
    },
    setNextYearHolidays: (nextYearHoliday: HolidayType[]) => {
      set({
        nextYearHoliday,
      });
    },
    setPreviousYearHolidays: (previousYearHoliday: HolidayType[]) => {
      set({
        previousYearHoliday,
      });
    },
    storeProfile: (profile: ProfileType) => {
      set({
        profile,
      });
    },
    storeProfileImage: (profileImage: string| undefined) => {
      set({
        profileImage,
      });
    },
    storeRole: (roles: string[]) => {
      set({
        roles,
      });
    },
  }));
})();
