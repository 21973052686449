import { useCallback, useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { useApi } from "../utils/services/useApi";
import { useToaster } from "../utils/services/useToaster";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { ProgressSpinner } from "primereact/progressspinner";
import {
  monthPayrollBodyTemplate,
  yearPayrollBodyTemplate,
} from "../components/PayrollTableTemplate";
import { Column } from "primereact/column";
import { Card } from "primereact/card";
import "../assets/styles/RunningPayroll.scss";
import "../assets/styles/Payslip.scss";
import { DateTime } from "luxon";
import { Dialog } from "primereact/dialog";
import OTPInput from "react-otp-input";
import { Toast } from "primereact/toast";
import { Spinner } from "../components/Spinner";

export const PayslipDownload = () => {
  const [payrollList, setPayrollList] = useState<any>([]);
  const [payslipMonth, setPayslipMonth] = useState<string | null>(null);
  const [otp, setOtp] = useState("");
  const [loader, setLoader] = useState(false);
  const [loadingRows, setLoadingRows] = useState<string[]>([]);
  const [pageLoader, setPageLoader] = useState(true);
  const { showToaster, toast } = useToaster();
  const { get, getBinary, post } = useApi(showToaster);

  const getPayrollList = useCallback(async () => {
    try {
      const res = await get(`/payroll/`);
      setPayrollList(res.payrolls);
      setPageLoader(false);
    } catch (err) {
      console.log(err);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getPayrollList();
  }, [getPayrollList]);

  const generateOtp = useCallback(async (month: string) => {
    try {
      setLoadingRows((prevLoadingRows: string[]) => [
        ...prevLoadingRows,
        month,
      ]);
      await post(`payroll/generate-otp?month=${month}`);
      setPayslipMonth(month);
      setLoadingRows((prevLoadingRows: string[]) =>
        prevLoadingRows.filter((rowMonth: string) => rowMonth !== month)
      );
    } catch (err) {
      console.log(err);
      setLoadingRows((prevLoadingRows: string[]) =>
        prevLoadingRows.filter((rowMonth: string) => rowMonth !== month)
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const downloadSlip = useCallback(async () => {
    try {
      const res = await getBinary(
        `payroll/download?month=${payslipMonth}&otp=${otp}`
      );
      const blob = new Blob([res], { type: "application/pdf" });
      saveAs(blob, `payroll_slip_${payslipMonth}.pdf`);
      setOtp("");
      setPayslipMonth(null);
    } catch (err) {
      console.log(err);
      showToaster({
        status: "error",
        CustomError: ["Cannot download payslip"],
      });
    } finally {
      setLoadingRows((prevLoadingRows: string[]) =>
        prevLoadingRows.filter((rowMonth: string) => rowMonth !== payslipMonth)
      );
      setLoader(false);
    }
  }, [payslipMonth, otp]); // eslint-disable-line react-hooks/exhaustive-deps

  const monthBodyTemplate = (month: string) => {
    const monthName = DateTime.fromFormat(month, "yyyyMM").toFormat("MMMM");
    return (
      <>
        <span className="ml-1">{monthName}</span>
      </>
    );
  };

  const yearBodyTemplate = (month: string) => {
    const date = DateTime.fromFormat(month, "yyyyLL");
    const year = date.year.toString();
    return (
      <>
        <span className="ml-1">{year}</span>
      </>
    );
  };

  return (
    <>
      {pageLoader ? (
        <Spinner />
      ) : (
        <div
          className="my-team-bg pt-3 tmsContainer"
          id="Individual-payroll-header"
        >
          <Toast ref={toast} />
          <Dialog
            header="Enter OTP"
            className="pb-0 w-4"
            id="MobileViewModal"
            visible={!!payslipMonth}
            onHide={() => {
              setPayslipMonth(null);
              setLoadingRows((prevLoadingRows: string[]) =>
                prevLoadingRows.filter(
                  (rowMonth: string) => rowMonth !== payslipMonth
                )
              );
            }}
          >
            <div>
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                inputStyle={{ height: 60, width: 60 }}
                renderSeparator={<span>&nbsp;&nbsp;</span>}
                renderInput={(props) => <input {...props} />}
              />
              <p className="text-xs">
                &#9432; OTP will be sent to the mobile number registered on
                Microsoft account. OTP can only be generated once in 5 minutes
                for a particular month's payslip.
              </p>
              <div className="w-12 flex justify-content-end">
                <Button
                  disabled={otp?.length !== 6 || loader}
                  className="cust-btn-clr mt-3"
                  onClick={() => {
                    downloadSlip();
                    setLoader(true);
                  }}
                >
                  {loader ? (
                    <ProgressSpinner
                      className="custom-progress-spinner"
                      strokeWidth="10"
                    />
                  ) : (
                    "Download"
                  )}
                </Button>
              </div>
            </div>
          </Dialog>
          <div>
            <Card className="m-3 Individual-payroll-download">
              <div className="text-left font-bold  payroll-card sm:px-8">
                Payroll
              </div>
            </Card>
          </div>
          <div className="mobilePayslipPage" id="mobilePayslipId">
            {!!payrollList.length &&
              payrollList.map((pd: any) => (
                <Card className="m-3  download-card">
                  <div className="flex justify-content-between">
                    <div className="flex flex-column">
                      <div>
                        <p className="dark mb-2">
                          Month :{monthBodyTemplate(pd.month)}
                        </p>
                      </div>
                      <div>
                        <p className="dark">
                          Year :{yearBodyTemplate(pd.month)}
                        </p>
                      </div>
                    </div>
                    <Button
                      onClick={() => generateOtp(pd.month)}
                      className="cust-view-btn payslip-mobile-button mt-5"
                      disabled={loadingRows.includes(pd.month)}
                    >
                      {loadingRows.includes(pd.month) ? (
                        <ProgressSpinner
                          className="custom-progress-spinner"
                          strokeWidth="10"
                        />
                      ) : (
                        "Download"
                      )}
                    </Button>
                  </div>
                </Card>
              ))}
          </div>
          <div className="card mx-3 desktopPayslipDownload">
            <DataTable value={payrollList} responsiveLayout="scroll">
              <Column
                header="Month"
                body={monthPayrollBodyTemplate}
                field="month"
                exportable={false}
              />
              <Column
                header="Year"
                body={yearPayrollBodyTemplate}
                field="month"
                exportable={false}
              />
              <Column
                header="Download Payslip"
                body={(rowData) => (
                  <Button
                    onClick={() => {
                      generateOtp(rowData.month);
                    }}
                    className="cust-view-btn h-3rem"
                    disabled={loadingRows.includes(rowData.month)}
                  >
                    {loadingRows.includes(rowData.month) ? (
                      <ProgressSpinner
                        className="custom-progress-spinner"
                        strokeWidth="10"
                      />
                    ) : (
                      "Download"
                    )}
                  </Button>
                )}
                exportable={false}
              ></Column>
            </DataTable>
          </div>
        </div>
      )}
    </>
  );
};
