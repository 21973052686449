import { useState, useEffect, useCallback, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { LeavesTable } from "../utils/LeavesTable";
import { LeaveCard } from "../utils/LeaveCard";
import { Duration } from "../enum/Duration";
import { LeavesData } from "../constants/LeavesData";
import { useStore } from "../store/useStore";
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";
import { MyLeaveType } from "../types/MyLeaveType";
import "../assets/styles/myLeave.scss";
import { useApi } from "../utils/services/useApi";
import { useToaster } from "../utils/services/useToaster";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { leaveBalanceType } from "../types/LeaveBalanceType";
import { Spinner } from "../components/Spinner";
import { LeaveEnum } from "../enum/LeaveEnum";

function MyLeaves() {
  const [selectedLeaveType, setSelectedLeaveType] = useState(null);
  const [displayBasic, setDisplayBasic] = useState(false);
  const { currentYearHoliday, nextYearHoliday, previousYearHoliday } =
    useStore();
  const [formData, setFormData] = useState<MyLeaveType>({
    period: "",
    type: "",
    startDate: new Date(),
    endDate: new Date(),
    reason: "",
  });
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [total, setTotal] = useState(0);
  const { showToaster } = useToaster();
  const { post, get } = useApi(showToaster);
  const toast = useRef<any>(null);
  const [myLeaveData, setMyLeaveData] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState();
  const [size, setSize] = useState<number>(10);
  const [leaveBalance, setLeaveBalance] = useState<leaveBalanceType>({});
  const [pageLoader, setPageLoader] = useState(true);
  const [leaveType, setLeaveType] = useState("");

  const handleLeaves = useCallback(
    async () => {
      try {
        const data = await get(`leave/balance`);
        setLeaveBalance(data);
        setPageLoader(false);
      } catch (err) {
        console.log(err);
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    handleLeaves();
  }, [handleLeaves]);

  const fetchLeaves = useCallback(
    async () => {
      try {
        const { content, totalElements } = await get(
          `leave/list?number=${currentPage}&size=${size}`
        );
        setMyLeaveData(content);
        setTotalRecords(totalElements);
      } catch (err) {
        console.log(err);
      }
    },
    [size, currentPage] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    fetchLeaves();
  }, [fetchLeaves]);

  const getTotalLeaves = useCallback(() => {
    const countWeekendDays = (d1: Date, d2: Date): number => {
      let ndays =
        1 + Math.round((d2.getTime() - d1.getTime()) / (24 * 3600 * 1000));
      let nsaturdays = Math.floor((d1.getDay() + ndays) / 7);
      let weekends = 2 * nsaturdays;
      if (d1.getDay() === 0) {
        weekends++;
      }
      if (d2.getDay() === 6) {
        weekends--;
      }
      return weekends;
    };

    const SUN = "Sun";
    const SAT = "Sat";
    const holidays = [
      ...previousYearHoliday,
      ...currentYearHoliday,
      ...nextYearHoliday,
    ]
      .map((e) => e.date)
      .filter((h) => new Date(h) >= startDate && new Date(h) <= endDate);

    const holidayWeekends = holidays.filter((h) => {
      const day = new Date(h).toString().split(" ")[0];
      return [SAT, SUN].includes(day);
    }).length;

    const holidayCount = holidays.length;

    const diffInMilliseconds = Math.abs(
      new Date(endDate.toDateString()).getTime() -
        new Date(startDate.toDateString()).getTime()
    );
    const diffInDays = Math.floor(diffInMilliseconds / 86400000);

    const weekends = countWeekendDays(startDate, endDate);

    const totalLeaves =
      diffInDays + 1 - weekends - holidayCount + holidayWeekends;

    setTotal(totalLeaves);
  }, [startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getTotalLeaves();
  }, [startDate, endDate, getTotalLeaves]);

  const defaultValues = {
    period: "",
    type: "",
    startDate: new Date(),
    endDate: new Date(),
    reason: "",
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({ defaultValues });

  const holidaysDates = currentYearHoliday
    .filter((e) => e.type === "MANDATORY")
    .map((e) => new Date(e.date));

  const invalidDates = holidaysDates;
  const dialogFuncMap: any = {
    displayBasic: setDisplayBasic,
  };

  const onClick = (name: string) => {
    dialogFuncMap[`${name}`](true);
  };
  const onHide = (name: string) => {
    dialogFuncMap[`${name}`](false);
  };

  const onSubmit = (data: MyLeaveType) => {
    setFormData(data);
    reset();
    onHide("displayBasic");
  };
  const selectedStartDate = new Date(formData.startDate);
  selectedStartDate.setMinutes(
    selectedStartDate.getMinutes() - selectedStartDate.getTimezoneOffset()
  );
  const newStartDate = selectedStartDate.toISOString().slice(0, 10);

  const selectedEndDate = new Date(formData.endDate);
  selectedEndDate.setMinutes(
    selectedEndDate.getMinutes() - selectedEndDate.getTimezoneOffset()
  );
  const newEndDate = selectedEndDate.toISOString().slice(0, 10);

  const postApplyLeave = useCallback(
    async () => {
      try {
        let data;
        if (
          formData.startDate &&
          formData.endDate &&
          formData.type &&
          formData.period
        ) {
          if (formData.period === "MULTIPLE") {
            data = {
              period: formData.period,
              type: formData.type,
              startDate: newStartDate,
              endDate: newEndDate,
              reason: formData.reason,
            };
          } else {
            data = {
              period: formData.period,
              type: formData.type,
              startDate: newStartDate,
              endDate: newStartDate,
              reason: formData.reason,
            };
          }
          const { success } = await post(`leave/apply`, data);
          if (success) {
            toast.current.show({
              severity: "success",
              summary: "Leave Applied Successfully",
              life: 3000,
            });
            fetchLeaves();
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    [formData] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    postApplyLeave();
  }, [postApplyLeave]);

  return (
    <>
      {pageLoader ? (
        <Spinner />
      ) : (
        <div id="team-card-header" className="my-team-bg pt-3 tmsContainer">
          <Toast ref={toast} />
          <LeaveCard leaveBalance={leaveBalance} />
          <div className="text-right pr-3 pb-3">
            <Button
              className="cust-btn-clr"
              onClick={() => onClick("displayBasic")}
            >
              + Apply Leave
            </Button>
          </div>
          <div className="leaveStatus">
            <Card className="leaveStatusCard">Leave Status</Card>
          </div>
          <div className="px-3 custleavetable">
            <LeavesTable
              size={size}
              setSize={setSize}
              setCurrentPage={setCurrentPage}
              fetchLeaves={fetchLeaves}
              myLeaveData={myLeaveData}
              totalRecords={totalRecords}
            />
          </div>
          <Dialog
            header="New Leave"
            visible={displayBasic}
            style={{ width: "50vw" }}
            onHide={() => onHide("displayBasic")}
            className="MobileDialog"
          >
            <div className="MobileLeaveModal">
              <form className="MobileForm">
                <div className="flex mb-2" id="MobileLeaveType">
                  <div className="w-2">
                    <label htmlFor="in" id="MobileLeaveTypeText">
                      Leave Type
                    </label>
                  </div>
                  <div className="w-10">
                    <div className="field">
                      <span className="p-float-label">
                        <Controller
                          name="type"
                          control={control}
                          rules={{ required: "Leave Type is required." }}
                          render={({ field, fieldState }) => (
                            <Dropdown
                              id={field.name}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                                setLeaveType(e.value);
                              }}
                              options={LeavesData}
                              optionLabel="name"
                              className="w-11 md:w-5"
                            />
                          )}
                        />
                        <label
                          htmlFor="country"
                          className={classNames({ "p-error": errors.type })}
                        >
                          Select Leave Type
                        </label>
                      </span>
                      {errors.type && (
                        <small role="alert" className="text-red-500">
                          {errors.type?.message}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-0">
                  <Controller
                    name="period"
                    control={control}
                    rules={{ required: "Leave Duration is required." }}
                    render={({ field, fieldState }) => (
                      <div className="flex mb-2 MobileLeaveDuration">
                        <div className="w-2">
                          <label htmlFor="in" id="MobileLeaveDurationText">
                            Leave Period
                          </label>
                        </div>
                        <div className="w-10  field ">
                          <span className="flex" id="MobileLeaveDurationRight">
                            <div>
                              <RadioButton
                                className="ml-2 md:ml-0 mr-2"
                                inputId="LeaveType1"
                                value="HALFDAY"
                                id={field.name}
                                name={field.name}
                                onChange={(e) => {
                                  field.onChange(e.value);
                                  setSelectedLeaveType(e.target.value);
                                }}
                                checked={field.value === Duration.HALFDAY}
                              />
                              <label htmlFor="LeaveType1">Half Day</label>
                            </div>
                            <div>
                              <RadioButton
                                className="mr-2 ml-2"
                                inputId="LeaveType2"
                                id={field.name}
                                name={field.name}
                                value="SINGLE"
                                onChange={(e) => {
                                  field.onChange(e.value);
                                  setSelectedLeaveType(e.target.value);
                                }}
                                checked={field.value === Duration.SINGLE}
                              />
                              <label htmlFor="LeaveType2">Single</label>
                            </div>
                            <div>
                              <RadioButton
                                className="mr-2 ml-2"
                                inputId="LeaveType3"
                                id={field.name}
                                name={field.name}
                                value="MULTIPLE"
                                onChange={(e) => {
                                  field.onChange(e.value);
                                  setSelectedLeaveType(e.target.value);
                                }}
                                checked={field.value === Duration.MULTIPLE}
                              />
                              <label htmlFor="LeaveType3">Multiple</label>
                            </div>
                          </span>
                          <div>
                            <small>
                              {errors.period && (
                                <p
                                  role="alert"
                                  className="text-red-500 mt-1 mb-0"
                                >
                                  {errors.period?.message}
                                </p>
                              )}
                            </small>
                          </div>
                        </div>
                      </div>
                    )}
                  />
                </div>
                <div className="flex flex-column mb-2">
                  <div className="flex mb-0 MobileCalendar">
                    <div className="w-2">
                      <label htmlFor="date">Date</label>
                    </div>
                    <div className="w-10">
                      {selectedLeaveType && selectedLeaveType === "MULTIPLE" ? (
                        <div className="flex gap-4 MobileMultipleCalendar">
                          <div className="field">
                            <span className="p-float-label">
                              <Controller
                                name="startDate"
                                control={control}
                                render={({ field }) => (
                                  <Calendar
                                    id={field.name}
                                    value={field.value}
                                    onChange={(e) => {
                                      field.onChange(e.value);
                                      setStartDate(e.value as Date);
                                      if (e.value)
                                        setValue("endDate", e.value as Date);
                                    }}
                                    showIcon
                                    disabledDates={invalidDates}
                                    disabledDays={[0, 6]}
                                    readOnlyInput
                                    dateFormat="dd/mm/yy"
                                  />
                                )}
                              />
                            </span>
                          </div>

                          <div className="field">
                            <span className="p-float-label">
                              <Controller
                                name="endDate"
                                control={control}
                                render={({ field }) => (
                                  <Calendar
                                    id={field.name}
                                    value={field.value}
                                    onChange={(e) => {
                                      field.onChange(e.value);
                                      setEndDate(e.value as Date);
                                    }}
                                    showIcon
                                    disabledDates={invalidDates}
                                    disabledDays={[0, 6]}
                                    readOnlyInput
                                    minDate={startDate}
                                    dateFormat="dd/mm/yy"
                                  />
                                )}
                              />
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="field MobileSingleCalendar">
                          <span className="p-float-label">
                            <Controller
                              name="startDate"
                              control={control}
                              render={({ field }) => (
                                <Calendar
                                  id={field.name}
                                  value={field.value}
                                  onChange={(e) => {
                                    field.onChange(e.value);
                                  }}
                                  showIcon
                                  disabledDates={invalidDates}
                                  disabledDays={[0, 6]}
                                  readOnlyInput
                                  dateFormat="dd/mm/yy"
                                />
                              )}
                            />
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex gap-0 mb-1 -mt-2">
                  <div className="w-2"></div>
                  <div className="w-10">
                    {selectedLeaveType === "MULTIPLE" &&
                      leaveType !== LeaveEnum.CLIENTOFFICE &&
                      total > 0 &&
                      startDate < endDate && (
                        <span className="text-red-500 text-sm">
                          {`${total} ${
                            total === 1 ? "Leave" : "Leaves"
                          }  will be deducted`}
                        </span>
                      )}
                  </div>
                </div>

                <div className="flex mb-4 MobileReason">
                  <div className="w-2">
                    <label htmlFor="date" id="MobileReasonText">
                      Reason (Optional)
                    </label>
                  </div>
                  <div className="w-10">
                    <div className="field">
                      <span className="p-float-label">
                        <Controller
                          name="reason"
                          control={control}
                          render={({ field }) => (
                            <InputTextarea
                              className="w-18rem md:w-12"
                              placeholder="Please enter the reason"
                              id={field.name}
                              value={field.value}
                              onChange={(e) => field.onChange(e.target.value)}
                            />
                          )}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </form>
              <div className="flex justify-content-end" id="MobileButtonMain">
                <Button
                  className="p-button-outlined mr-2 MobileButtonLeft"
                  onClick={() => onHide("displayBasic")}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit(onSubmit)}
                  className="cust-btn-clr MobileButtonRight"
                >
                  Apply
                </Button>
              </div>
            </div>
          </Dialog>
        </div>
      )}
    </>
  );
}

export default MyLeaves;
