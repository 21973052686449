import { useCallback, useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { useNavigate, useParams } from "react-router";
import { EmployeeDetails } from "../components/EmployeeDetails";
import { useApi } from "../utils/services/useApi";
import { useToaster } from "../utils/services/useToaster";
import "../assets/styles/IndividualPayroll.scss";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  monthPayrollBodyTemplate,
  yearPayrollBodyTemplate,
} from "../components/PayrollTableTemplate";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { Controller, useForm } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { RunPayrollOptions } from "../constants/RunPayrollOptions";
import { DateTime } from "luxon";
import Loader from "../components/Loader";
import { ProgressSpinner } from "primereact/progressspinner";
import warning from "../assets/images/warning.svg";
import { Tooltip } from "primereact/tooltip";
import { Spinner } from "../components/Spinner";
import { PayrollEnum } from "../enum/PayrollEnum";

export const IndividualPayroll = () => {
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [selectedTemplateValue, setSelectedTemplateValue] =
    useState<string>("");
  const { id } = useParams<string>();
  const [individualPayroll, setIndividualPayroll] = useState<any>([]);
  const [displayBasic, setDisplayBasic] = useState(false);
  const { showToaster } = useToaster();
  const { get, getBinary, httpDelete } = useApi(showToaster);
  const [memberData, setMemberData] = useState<any>({});
  const [date, setDate] = useState<Date | Date[] | undefined>(undefined);
  const [errorDateErrorMessage, setDateErrorMessage] = useState("");
  const [errorDropdownMessage, setErrorDropDownMessage] = useState<
    string | null
  >(null);
  const [templateOptions, setTemplateOptions] = useState<any>([]);
  const [selectionOptionId, setSelectedOptionId] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState("");
  const [loader, setLoader] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [pageLoader, setPageLoader] = useState(true);
  const [previousMonthPayroll, setPreviousMonthPayroll] = useState<
    string | null
  >();
  const [previousMonthPayrollError, setPreviousMonthPayrollError] = useState<
    string | null
  >(null);
  useEffect(() => {
    setSelectedValue(RunPayrollOptions[0].value);
  }, []);

  const handleValueChange = (e: any) => {
    setSelectedValue(e.value);
  };

  const handleTemplateValueChange = (e: any) => {
    setSelectedTemplateValue(e.value);
    setSelectedOptionId(e.value);
  };

  const getTemplateOptions = useCallback(async () => {
    try {
      const res = await get(`/templates`);
      setTemplateOptions(res);
    } catch (err) {
      console.log(err);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getTemplateOptions();
  }, [getTemplateOptions]);

  const TemplateOptions = templateOptions.map((obj: any) => ({
    name: obj.name,
    value: obj.name,
    id: obj.id,
  }));
  const navigate = useNavigate();

  const { control } = useForm({});

  const dialogFuncMap: any = {
    displayBasic: setDisplayBasic,
  };

  const onClick = (name: string) => {
    dialogFuncMap[`${name}`](true);
  };
  const onHide = (name: string) => {
    dialogFuncMap[`${name}`](false);
  };

  const getIndividualTeam = useCallback(async () => {
    try {
      const res = await get(`/employee-info/${id}`);
      setMemberData(res);
      setPageLoader(false);
    } catch (err) {
      console.log(err);
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getIndividualTeam();
  }, [getIndividualTeam]);

  const handleError = () => {
    if (!date) {
      setDateErrorMessage("This field is required.");
    } else {
      setDateErrorMessage("");
    }
    if (!previousMonthPayroll) {
      setPreviousMonthPayrollError(
        "Please select copy from template as we don't have previous month payroll data."
      );
    } else {
      setPreviousMonthPayrollError(null);
    }
  };

  const handleTemplateError = () => {
    setPreviousMonthPayroll(id);
    if (!selectedTemplateValue) {
      setErrorDropDownMessage("Please select a template.");
      return false;
    } else {
      setErrorDropDownMessage("");
      return true;
    }
  };

  const formatDate = (date: any) => {
    const dateInString = new Date(date);
    const dateFormat = DateTime.fromJSDate(dateInString);
    const month = dateFormat.toFormat("MM");
    const year = dateFormat.toFormat("yyyy");
    const formattedDate = year + month;
    return formattedDate;
  };

  const handleOkClick = (date: any) => {
    const formattedDate = formatDate(date);
    navigate(`/employee-payroll/${id}/${formattedDate}`);
  };

   const handleEditClick = (date: any) => {
     navigate(`/edit-employee-payroll/${id}/${individualPayroll[0].month}`);
   };

  const getDownloadSlip = useCallback(async () => {
    try {
      const res = await get(`/payroll/${id}`);
      setIndividualPayroll(res.payrolls);
    } catch (err) {
      console.log(err);
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const deleteLastPayroll = async (id: string | undefined) => {
    try {
      await httpDelete(`/payroll/${id}`);
      getDownloadSlip();
    } catch (err) {
      console.log(err);
    }
    setLoader(false);
  };

  const getEmployeePayrollData = useCallback(async () => {
    try {
      const res = await get(`/payroll/previous/${id}`);
      if (selectedValue === PayrollEnum.TEMPLATE || res) {
        setPreviousMonthPayroll(id);
      } else {
        setPreviousMonthPayroll(null);
      }
    } catch (err) {
      console.log(err);
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getEmployeePayrollData();
  }, [getEmployeePayrollData]);

  useEffect(() => {
    getDownloadSlip();
  }, [getDownloadSlip]);

  const downloadSlip = useCallback(
    async (month: string) => {
      try {
        setMonth(month);
        setLoading(true);
        const res = await getBinary(`payroll/download/${id}?month=${month}`);
        const blob = new Blob([res], { type: "application/pdf" });
        saveAs(blob, `payroll_slip_${month}.pdf`);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    },[id]); // eslint-disable-line react-hooks/exhaustive-deps

  const showTemplateDailog = (templateid: string) => {
    if (handleTemplateError()) {
      let formattedDate = formatDate(date);
      navigate(`/employee-payroll/${id}/${formattedDate}/${templateid}`);
    }
  };

  const getAccountInfo = useCallback(async (value: any) => {
    try {
      if (value) {
        const res = await get(`employee-info/accounts/${value}`);
        if (res === null) {
          setIsDisable(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getAccountInfo(id);
  }, [getAccountInfo, id]);

  useEffect(() => {
    if (date) {
      setDateErrorMessage("");
    }
    if (previousMonthPayroll) {
      setPreviousMonthPayrollError(null);
    }
    if (selectedValue === PayrollEnum.TEMPLATE) {
      setPreviousMonthPayrollError(null);
    }
    if (selectedTemplateValue) {
      setErrorDropDownMessage(null);
    }
  }, [date, previousMonthPayroll, selectedValue, selectedTemplateValue]);

  const renderButton = () => (
    <Button
      className="cust-btn-clr mr-3"
      onClick={() => onClick("displayBasic")}
      disabled={isDisable}
    >
      Run Payroll
    </Button>
  );
  return (
    <>
      {pageLoader ? (
        <Spinner />
      ) : (
        <div
          className="my-team-bg pt-3 tmsContainer"
          id="Individual-payroll-header"
        >
          <div className="individual-employee-details p-3 mx-3">
            <EmployeeDetails
              memberData={memberData}
              backLink="/employee-payroll"
              setIsDisable={setIsDisable}
            />
          </div>
          <div className="text-right my-3">
            {isDisable ? (
              <>
                <Tooltip target=".disabled-button" />
                <span
                  className="disabled-button"
                  data-pr-tooltip="Please add employee information before running payroll"
                >
                  {renderButton()}
                </span>
              </>
            ) : (
              renderButton()
            )}

            <Button
              disabled={!individualPayroll?.length}
              className="h-3rem mr-3 delete-btn-clr border-none"
              onClick={(e) => {
                setDeleteModal(true);
              }}
            >
              Delete Last Payroll
            </Button>
            <Button
              disabled={!individualPayroll?.length}
              className="cust-btn-clr mr-3"
              onClick={(e) => {
                  handleEditClick(date)
              }}
            >
              Edit Last Payroll
            </Button>
          </div>
          <div className="card mx-3">
            <DataTable value={individualPayroll} responsiveLayout="scroll">
              <Column
                header="Month"
                body={monthPayrollBodyTemplate}
                field="month"
                exportable={false}
              />
              <Column
                header="Year"
                body={yearPayrollBodyTemplate}
                field="month"
                exportable={false}
              />
              <Column
                header="Download Payslip"
                body={(rowData) => (
                  <>
                    <Button
                      disabled={loading}
                      onClick={() => downloadSlip(rowData.month)}
                      className="cust-view-btn h-3rem"
                    >
                      {loading && rowData.month === month && (
                        <Loader
                          color="#00BFFF"
                          height={100}
                          width={100}
                          // timeout={3000} //3 secs
                          visible={true}
                        />
                      )}
                      <span>Download</span>
                    </Button>
                  </>
                )}
                exportable={false}
              ></Column>
            </DataTable>
          </div>

          <Dialog
            header="Run Payroll"
            visible={displayBasic}
            style={{ width: "35vw" }}
            onHide={() => onHide("displayBasic")}
            className="font-bold pb-0 payroll-modal"
          >
            <div className="payroll-modal">
              <div className="flex flex-row ">
                <p className="ml-5 mb-3">Select Month and Year</p>
              </div>
              <div className="ml-5 employee-payroll-calender mb-3">
                <Calendar
                  showIcon
                  id="monthpicker"
                  value={date}
                  onChange={(e) => setDate(e.value)}
                  view="month"
                  dateFormat="mm/yy"
                  placeholder="Select Month and Year"
                />
                {errorDateErrorMessage && (
                  <div style={{ color: "red" }}>{errorDateErrorMessage}</div>
                )}
              </div>
              <div className="flex mt-2">
                <div className="w-12">
                  <div className="field">
                    <span className="ml-4">
                      <Controller
                        name="type"
                        control={control}
                        rules={{ required: "please select a type" }}
                        render={({ field }) => (
                          <Dropdown
                            id={field.name}
                            value={selectedValue}
                            onChange={handleValueChange}
                            options={RunPayrollOptions}
                            optionLabel="name"
                            className="w-6 ml-2"
                            placeholder="Copy From Previous Month"
                          />
                        )}
                      />
                      {previousMonthPayrollError && (
                        <div className="ml-5" style={{ color: "red" }}>
                          {previousMonthPayrollError}
                        </div>
                      )}
                      {selectedValue === PayrollEnum.TEMPLATE && (
                        <Controller
                          name="Templatetype"
                          control={control}
                          rules={{ required: "please select a Templatetype" }}
                          render={({ field }) => (
                            <Dropdown
                              id={field.name}
                              value={selectedTemplateValue}
                              onChange={handleTemplateValueChange}
                              options={TemplateOptions}
                              optionLabel="name"
                              optionValue="id"
                              className="w-6 ml-5 mt-4"
                              placeholder="Select From Template"
                            />
                          )}
                        />
                      )}
                      {errorDropdownMessage && (
                        <div className="ml-5" style={{ color: "red" }}>
                          {errorDropdownMessage}
                        </div>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-row-reverse mt-2 employee-payroll-buttons">
                <Button
                  onClick={() => {
                    if (
                      date &&
                      previousMonthPayroll &&
                      selectedValue === PayrollEnum.PREVIOUSMONTH
                    ) {
                      handleOkClick(date);
                    }
                    if (date && selectedValue === PayrollEnum.TEMPLATE) {
                      showTemplateDailog(selectionOptionId);
                    }
                    handleError();
                  }}
                  className="cust-btn-clr"
                >
                  Ok
                </Button>
                <Button
                  onClick={() => onHide("displayBasic")}
                  label="Cancel"
                  className="p-button-outlined mr-2 cust-btn-text"
                />
              </div>
            </div>
          </Dialog>
          <Dialog
            header="Delete Last Payroll"
            visible={deleteModal}
            style={{ width: "35vw" }}
            onHide={() => {
              setDeleteModal(false);
            }}
            className="pb-0 payroll-modal"
          >
            <div className="flex MobileWithdrawTop">
              <img src={warning} alt="" />
              <div className="ml-3 flex align-items-center">
                Are you sure that you want to delete last payroll ?
              </div>
            </div>
            <div className="text-right mt-3">
              <Button
                className="mr-3 bg-transparent border-0 text-blue-600"
                onClick={() => {
                  setDeleteModal(false);
                }}
              >
                No
              </Button>
              <Button
                disabled={loader}
                className="cust-btn-clr"
                onClick={() => {
                  setLoader(true);
                  deleteLastPayroll(id);
                  setDeleteModal(false);
                }}
              >
                {loader ? (
                  <ProgressSpinner
                    className="custom-progress-spinner"
                    strokeWidth="10"
                  />
                ) : (
                  "Yes"
                )}
              </Button>
            </div>
          </Dialog>
        </div>
      )}
    </>
  );
};
