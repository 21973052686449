import { useEffect, useState } from "react";
import { useApi } from "../utils/services/useApi";
import { useToaster } from "../utils/services/useToaster";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import "../assets/styles/accountReports.scss";
import { Calendar } from "primereact/calendar";
import saveAs from "file-saver";
import { useDateFormat } from "../utils/services/useDateFormat";
import { EmployeePayrollDataType } from "../types/EmployeePayrollDataType";
import { Spinner } from "../components/Spinner";

export const AccountsReports = () => {
  const { customDateFormat } = useDateFormat();
  const [employeePayrollDataList, setEmployeePayrollDataList] = useState<
    EmployeePayrollDataType[] | undefined
  >();
  const [date, setDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 2)
  );
  const { showToaster } = useToaster();
  const { get, getBinary } = useApi(showToaster);
  const [pageLoader, setPageLoader] = useState(true);

  useEffect(() => {
    const getAccountsReports = async () => {
      const { month, year } = customDateFormat(date);
      const res = await get(`payroll/reports/${year}${month}`);
      setEmployeePayrollDataList(res);
      setPageLoader(false);
    };
    getAccountsReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const allowanceKeys: string[] | undefined | boolean =
    employeePayrollDataList &&
    employeePayrollDataList.length > 0 &&
    Object.keys(employeePayrollDataList[0].allowance);
  const deductionKeys: string[] | undefined | boolean =
    employeePayrollDataList &&
    employeePayrollDataList.length > 0 &&
    Object.keys(employeePayrollDataList[0].deduction);

  const calculateInHandTotal = (): number => {
    return employeePayrollDataList &&
      employeePayrollDataList.length > 0 &&
      Array.isArray(employeePayrollDataList)
      ? employeePayrollDataList.reduce(
          (total: number, employee: Partial<EmployeePayrollDataType>) => {
            return total + employee?.inHand_Sum!;
          },
          0
        )
      : 0;
  };

  const calculateTotal = (): number => {
    return employeePayrollDataList &&
      employeePayrollDataList.length > 0 &&
      Array.isArray(employeePayrollDataList)
      ? employeePayrollDataList.reduce(
          (total: number, employee: Partial<EmployeePayrollDataType>) => {
            return total + employee?.total_Sum!;
          },
          0
        )
      : 0;
  };

  const calculateColumnTotal = (key: string): number => {
    return (
      employeePayrollDataList?.reduce(
        (total: number, row: Partial<EmployeePayrollDataType>) => {
          if (row.allowance?.hasOwnProperty(key)) {
            return total + row.allowance[key];
          } else if (row.deduction?.hasOwnProperty(key)) {
            return total + row.deduction[key];
          }
          return total;
        },
        0
      ) || 0
    );
  };

  const handleExport = async () => {
    const { month, year } = customDateFormat(date);
    const res = await getBinary(`payroll/export/${year}${month}`);
    const blob = new Blob([res], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `account_${month}-${year}.xlsx`);
  };

  const isDateDisabled = (date: Date) => {
    const today = new Date();
    const yearDiff = date.getFullYear() - today.getFullYear();
    const monthDiff = date.getMonth() - today.getMonth();

    if (yearDiff > 0 || (yearDiff === 0 && monthDiff > 0)) {
      return true; // Disable future years and months
    }

    return false; // Enable current and previous years and months
  };

  return (
    <>
      {pageLoader ? (
        <Spinner />
      ) : (
        <div
          id="account-card-header"
          className="my-team-bg pt-3 h-full tmsContainer"
        >
          <div>
            <Card className="mx-3 my-3 py-3 px-7">
              <div className="flex justify-content-between align-items-center">
                <div className="font-bold text-center">Account Reports</div>
              </div>
            </Card>
            <Card className="mx-3 my-3 py-4 px-7">
              <div className="flex justify-content-between align-items-center">
                <div className="p-float-label">
                  <Calendar
                    showIcon
                    readOnlyInput
                    dateFormat="MM yy"
                    view="month"
                    value={date}
                    onChange={(e) => setDate(e.value as Date)}
                    placeholder="Please Select Month"
                    maxDate={new Date()} // Disables future dates
                    dateTemplate={({ date, className }: any) => {
                      const isDisabled = isDateDisabled(date);
                      return (
                        <span
                          className={`${className} ${
                            isDisabled ? "disabled-date" : ""
                          }`}
                        >
                          {date.getDate()}
                        </span>
                      );
                    }}
                  />
                </div>
                <Button className="cust-btn-clr" onClick={handleExport}>
                  Export
                </Button>
              </div>
            </Card>
            {employeePayrollDataList && employeePayrollDataList.length > 0 && (
              <div className="card mx-3">
                <div className="table-container">
                  <table className="scrollable-table border-none bg-white p-4 ">
                    <thead>
                      <tr className="border-bottom-1 border-gray-300">
                        <th className="bg-cyan-300">Employee ID</th>
                        <th className="bg-cyan-300">Name</th>
                        <th className="bg-cyan-300">PF No.</th>
                        <th className="bg-cyan-300">UAN No.</th>
                        <th className="bg-cyan-300">PAN No.</th>
                        {Array.isArray(allowanceKeys) &&
                          allowanceKeys?.map((key: string) => (
                            <th key={key} className="bg-green-400 px-2">
                              {key}
                            </th>
                          ))}
                        {Array.isArray(deductionKeys) &&
                          deductionKeys?.map((key: string) => (
                            <th key={key} className="bg-yellow-400 px-2">
                              {key}
                            </th>
                          ))}
                        <th>In Hand Salary</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {employeePayrollDataList?.map(
                        (
                          row: Partial<EmployeePayrollDataType>,
                          index: number
                        ) => (
                          <>
                            <tr key={index}>
                              <td className="text-center border-bottom-1 border-gray-300 text-center py-3 bg-cyan-300">
                                {row.empId}
                              </td>
                              <td className="text-center border-bottom-1 border-gray-300 text-center py-3 bg-cyan-300">
                                {row.name}
                              </td>
                              <td className="text-center border-bottom-1 border-gray-300 text-center py-3 bg-cyan-300">
                                {row.pfNo}
                              </td>
                              <td className="text-center border-bottom-1 border-gray-300 text-center py-3 bg-cyan-300">
                                {row.uan}
                              </td>
                              <td className="text-center border-bottom-1 border-gray-300 text-center py-3 bg-cyan-300">
                                {row.pan}
                              </td>

                              {Array.isArray(allowanceKeys) &&
                                allowanceKeys?.map((key: string) => (
                                  <td
                                    key={key}
                                    className="border-bottom-1 border-gray-300 text-center py-3 bg-green-400 px-2"
                                  >
                                    {row.allowance?.[key]?.toFixed(2)}
                                  </td>
                                ))}
                              {Array.isArray(deductionKeys) &&
                                deductionKeys?.map((key: string) => (
                                  <td
                                    className="text-center border-bottom-1 border-gray-300 text-center py-3 bg-yellow-400 px-2"
                                    key={key}
                                  >
                                    {row.deduction?.[key]?.toFixed(2)}
                                  </td>
                                ))}
                              <td className="text-center border-bottom-1 border-gray-300 text-center py-3 px-3">
                                {row.inHand_Sum?.toFixed(2)}
                              </td>
                              <td className="text-center border-bottom-1 border-gray-300 text-center py-3 px-3">
                                {row.total_Sum?.toFixed(2)}
                              </td>
                            </tr>
                          </>
                        )
                      )}
                      <tr>
                        <td className="bg-cyan-300"></td>
                        <td className="bg-cyan-300"></td>
                        <td className="bg-cyan-300"></td>
                        <td className="bg-cyan-300"></td>
                        <td className="bg-cyan-300"></td>
                        {Array.isArray(allowanceKeys) &&
                          allowanceKeys?.map((key: string) => (
                            <td
                              className="text-center font-bold bg-green-400"
                              key={key}
                            >
                              {calculateColumnTotal(key).toFixed(2)}
                            </td>
                          ))}
                        {Array.isArray(deductionKeys) &&
                          deductionKeys?.map((key: string) => (
                            <td
                              className="text-center font-bold bg-yellow-400"
                              key={key}
                            >
                              {calculateColumnTotal(key).toFixed(2)}
                            </td>
                          ))}
                        <td className="text-center py-3 px-3 font-bold">
                          {calculateInHandTotal().toFixed(2)}
                        </td>
                        <td className="text-center py-3 px-3 font-bold">
                          {calculateTotal().toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
