import { constantObj } from "../utils/config/constantConfig";
const Footer = () => {
  return (
    <footer className="footer">
      <p className="text-center TextColor">
        Copyright © {new Date().getFullYear()}{" "}
        <span className="FooterText">{constantObj.companyName}</span>. All
        Rights Reserved
      </p>
    </footer>
  );
};
export { Footer };
