import React from "react";
import "../assets/styles/LeaveRequestModal.scss";
import { ProgressSpinner } from "primereact/progressspinner";

export const Spinner = ({ placement }: { placement?: boolean }) => {
  return (
    <div className={`spinner-wrapper `}>
      <div
        className={`spinner-border ${placement ? "d-center" : ""}`}
        style={{ width: "4rem", height: "4rem" }}
        role="status"
      >
        <span className="visually-hidden">
          {" "}
          <ProgressSpinner
            className="custom-progress-spinner custom-progress-spinner-color"
            strokeWidth="10"
          />
        </span>
      </div>
    </div>
  );
};
