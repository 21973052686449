import { useState, useEffect, useCallback, useRef } from "react";
import { LeaveCard } from "../utils/LeaveCard";
import "../assets/styles/myLeave.scss";
import { useApi } from "../utils/services/useApi";
import { useToaster } from "../utils/services/useToaster";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { useParams } from "react-router";
import { leaveBalanceType } from "../types/LeaveBalanceType";
import {EmployeeDetails} from "../components/EmployeeDetails";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { NumberOfLeavesBodyTemplate, createdDateBodyTemplate, endDateBodyTemplate, LeaveRequestBodyTemplate, leaveTypeBodyTemplate, nameBodyTemplate, startDateBodyTemplate, statusBodyTemplate } from "../components/LeaveTableTemplate";
import { Paginator } from "primereact/paginator";
import { currentPageReportType, onBasicPageChangeType } from "../types/PaginationType";
import "../assets/styles/leaveRequest.scss";

function MyLeaves() {
    const toast = useRef<any>(null);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalRecords, setTotalRecords] = useState();
    const [size, setSize] = useState<number>(10);
    const [leaveBalanceId, setLeaveBalanceId] = useState<leaveBalanceType>({});
    const { showToaster } = useToaster();
    const { get } = useApi(showToaster);
    const [memberData, setMemberData] = useState<any>({})
    const { id } = useParams<string>();
    const [leaveRequestData, setLeaveRequestData] = useState([]);
    const [basicFirst, setBasicFirst] = useState(0);

    const getLeaveRequest = useCallback(async () => {
        try {
            const { content, totalElements } = await get(`leave/list/${id}?number=${currentPage}&size=${size}`)
            setLeaveRequestData(content)
            setTotalRecords(totalElements)
        }
        catch (err) {
            console.log(err)
        }
    }, [size, currentPage] // eslint-disable-line react-hooks/exhaustive-deps
    )

    useEffect(() => {
        getLeaveRequest()
    }, [getLeaveRequest])

    const template3 = {
        layout:
            "RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport",
        CurrentPageReport: (options: currentPageReportType) => {
            return (
                <span
                    style={{
                        color: "var(--text-color)",
                        userSelect: "none",
                        width: "auto",
                        textAlign: "center",
                    }}
                >
                    Showing {options.first} to {options.last} of {options.totalRecords}
                </span>
            );
        },
    };

    const onBasicPageChange = (event: onBasicPageChangeType) => {
        setCurrentPage(event.page);
        setBasicFirst(event.first);
        setSize(event.rows);
    };
    

    const getIndividualTeam = useCallback(async () => {
        try {
            const res = await get(`/employee-info/${id}`)
            setMemberData(res)
        }
        catch (err) {
            console.log(err)
        }
    }, [id]) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        getIndividualTeam()
    }, [getIndividualTeam])

    const leaveBalanceById = useCallback(
        async () => {
            try {
                const data = await get(`leave/balance/${id}`);
                setLeaveBalanceId(data);
            } catch (err) {
                console.log(err);
            }
        },
        [id] // eslint-disable-line react-hooks/exhaustive-deps
    );

    useEffect(() => {
        leaveBalanceById();
    }, [leaveBalanceById]);


    return (
      <div id="team-card-header" className="my-team-bg pt-3 tmsContainer">
        <EmployeeDetails
          memberData={memberData}
          backLink="/my-team"
          hideAccountInfo={true}
        />
        <Toast ref={toast} />
        <LeaveCard leaveBalance={leaveBalanceId} />
        <div className="leaveStatus">
          <Card className="leaveStatusCard">Leave Status</Card>
        </div>
        <div className="test my-team-bg pt-4 h-full tmsContainer">
          <div className="mx-5" id="leaveRequest">
            <div className="card">
              <h3>Leave Requests</h3>
              <DataTable
                value={leaveRequestData}
                id="myLeaveMain"
                className="leaveRequestTable"
              >
                <Column
                  header="Name"
                  body={nameBodyTemplate}
                  exportable={false}
                />
                <Column
                  header="Leave Type"
                  body={leaveTypeBodyTemplate}
                  exportable={false}
                />
                <Column
                  header="From Date"
                  body={startDateBodyTemplate}
                  exportable={false}
                />
                <Column
                  header="To Date"
                  body={endDateBodyTemplate}
                  exportable={false}
                />
                <Column
                  header="Applied At"
                  body={createdDateBodyTemplate}
                  exportable={false}
                />
                <Column
                  header="Number Of Leaves"
                  body={NumberOfLeavesBodyTemplate}
                  exportable={false}
                />
                <Column
                  header="Status"
                  body={statusBodyTemplate}
                  exportable={false}
                ></Column>
                <Column
                  header="Action"
                  body={(e) => (
                    <LeaveRequestBodyTemplate
                      onComplete={getLeaveRequest}
                      leaveId={e.leaveId}
                    />
                  )}
                  exportable={false}
                ></Column>
              </DataTable>
              <Paginator
                first={basicFirst}
                rows={size}
                totalRecords={totalRecords}
                template={template3}
                rowsPerPageOptions={[10, 20, 50]}
                onPageChange={onBasicPageChange}
              ></Paginator>
            </div>
          </div>
        </div>
      </div>
    );
}

export default MyLeaves;
