import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import "../assets/styles/myTeam.scss";
import "../assets/styles/IndividualPayroll.scss";
import { Dialog } from "primereact/dialog";
import { useCallback, useEffect, useState } from "react";
import { useApi } from "../utils/services/useApi";
import { useToaster } from "../utils/services/useToaster";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputNumber } from "primereact/inputnumber";

export const EmployeeDetails = ({
  memberData,
  backLink,
  date,
  hideAccountInfo,
  setIsDisable,
  setLop,
  lop,
  clicked,
}: {
  memberData?: any;
  backLink?: any;
  date?: any;
  hideAccountInfo?: boolean;
  setIsDisable?: any;
  setLop?: any;
  lop?: any;
  clicked?: any;
}): any => {
  const [visible, setVisible] = useState(false);
  const { showToaster } = useToaster();
  const { get, put, post } = useApi(showToaster);
  const [accountInfo, setAccountInfo] = useState<any>();
  const [pfNo, setPfNo] = useState<string>();
  const [pan, setPan] = useState<string>();
  const [accountNo, setAccountNo] = useState<string>();
  const [uan, setUan] = useState<string>();
  const [bank, setBank] = useState<string>();
  const [edit, setEdit] = useState<boolean>();
  const [empId, setEmpId] = useState();
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [lopErrorMessage, setLopErrorMessage] = useState<string | null>(null);

  const formatDate = (date: string) => {
    const dateTime = DateTime.fromFormat(date, "yyyyLL");

    const updatedDateTime = dateTime.set({ month: dateTime.month });

    const payrollMonth = updatedDateTime.toFormat("LLLL yyyy");
    return payrollMonth;
  };

  const navigate = useNavigate();
  const handleBack = (): void => {
    navigate(backLink);
  };

  const handleError = () => {
    if (!lop && lop !== 0) {
      setLopErrorMessage("This field is required.");
    } else {
      setLopErrorMessage(null);
    }
  };

  const getAccountInfo = useCallback(async (value: any) => {
    try {
      if (!hideAccountInfo && value) {
        const res = await get(`employee-info/accounts/${value}`);
        res === null ? setEdit(true) : setEdit(false);
        setAccountInfo(res);
        setPfNo(res.pfNo);
        setBank(res.bank);
        setPan(res.pan);
        setUan(res.uan);
        setAccountNo(res.accountNo);
      }
    } catch (err) {
      console.log(err);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (lop === 0 || lop) {
      setLopErrorMessage(null);
    }
  }, [lop]);

  useEffect(() => {
    if (clicked) {
      handleError();
    }
  });

  const editAccountInfo = async () => {
    try {
      if (editable) {
        setLoading(true);
        const payload = {
          accountNo: accountNo,
          bank: bank,
          pan: pan,
          pfNo: pfNo,
          uan: uan,
        };
        await put(`employee-info/${empId}`, payload);
        setVisible(false);
        setEdit(false);
        setEditable(false);
        setLoading(false);
      }
    } catch (error: any) {
      console.log(error);
      setLoading(false);
    }
  };

  const addAccountInfo = async () => {
    try {
      const payload = {
        empId: empId,
        accountNo: accountNo,
        bank: bank,
        pan: pan,
        pfNo: pfNo,
        uan: uan,
      };
      const res = await post(`employee-info/add`, payload);
      if (res.success) {
        getAccountInfo(empId);
        setIsDisable(false);
      }
      setVisible(false);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAccountInfo(memberData.empId);
  }, [memberData.empId, getAccountInfo]);

  return (
    <>
      {memberData && (
        <>
          <div className="employee-info mb-2 px-4 mt-2">
            <div className="flex">
              <img
                className="h-fit myTeamMobileProfile border-circle profile-image team-profile-image"
                src={`data:image/png;base64,${memberData.profileImage}`}
                alt="profile-img"
              />
              <div className="ml-3">
                <div className="flex">
                  <p className="m-0 font-bold myTeamDisplayName mb-2">
                    {memberData.name}
                  </p>
                </div>
                <p className="m-0 mb-2">{memberData.designation}</p>
                <p className="m-0 text-600 myTeamEmailId mb-2">
                  {memberData.empId}
                </p>
                <p className="m-0 text-600 myTeamEmailId">{memberData.email}</p>
              </div>
            </div>
            <div className="flex flex-column justify-content-between">
              <div>
                <button
                  onClick={handleBack}
                  className="employeeDetils-back-btn  align-self-end"
                >
                  Back
                </button>
              </div>
              {!hideAccountInfo && (
                <div className="mt-2">
                  {!accountInfo ? (
                    <button
                      className="p-button p-component cust-btn-clr"
                      onClick={() => {
                        setVisible(true);
                        setEmpId(memberData.empId);
                      }}
                    >
                      Add Info
                    </button>
                  ) : (
                    <button
                      className="p-button p-component cust-btn-clr"
                      onClick={() => {
                        setVisible(true);
                        getAccountInfo(memberData.empId);
                        setEmpId(memberData.empId);
                      }}
                    >
                      Account Info
                    </button>
                  )}
                </div>
              )}

              <Dialog
                header="View Account Information"
                visible={visible}
                onHide={() => {
                  setVisible(false);
                  accountInfo !== null ? setEdit(false) : setEdit(true);
                  setEditable(false);
                }}
              >
                {
                  <table className="modal-table">
                    <tr>
                      <td className="pl-4">PF No.</td>
                      <td className="pl-4">
                        <InputText
                          disabled={!edit}
                          value={pfNo}
                          onChange={(e) => setPfNo(e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="pl-4">PAN</td>
                      <td className="pl-4 pt-2">
                        <InputText
                          disabled={!edit}
                          value={pan}
                          onChange={(e) => setPan(e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="pl-4">Account No.</td>
                      <td className="pl-4 pt-2">
                        <InputText
                          disabled={!edit}
                          value={accountNo}
                          onChange={(e) => setAccountNo(e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="pl-4">UAN</td>
                      <td className="pl-4 pt-2">
                        <InputText
                          disabled={!edit}
                          value={uan}
                          onChange={(e) => setUan(e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="pl-4">Bank</td>
                      <td className="pl-4 pt-2">
                        <InputText
                          disabled={!edit}
                          value={bank}
                          onChange={(e) => setBank(e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="text-right">
                        {!accountInfo ? (
                          <Button
                            className="cust-btn-clr mt-2"
                            onClick={() => {
                              addAccountInfo();
                            }}
                          >
                            Save
                          </Button>
                        ) : (
                          <Button
                            className="cust-btn-clr mt-2"
                            onClick={() => {
                              setEdit(true);
                              editAccountInfo();
                              setEditable(true);
                              editable && setLoading(true);
                            }}
                          >
                            {!editable ? (
                              "Edit"
                            ) : loading ? (
                              <ProgressSpinner
                                className="custom-progress-spinner"
                                strokeWidth="10"
                              />
                            ) : (
                              "Save"
                            )}
                          </Button>
                        )}
                      </td>
                    </tr>
                  </table>
                }
              </Dialog>
              <div className="flex justify-content-end">
                {date && (
                  <div>
                    <p className="m-0 text-600  text-base mr-8 mt-3 text-right myTeamEmailId">
                      Running Payroll For {formatDate(date)}
                    </p>
                    <div className=" d-flex">
                      <p className="my-2">Loss of Pay</p>
                      <InputNumber
                        onChange={(e) => setLop(e.value)}
                        minFractionDigits={1}
                        value={lop}
                        placeholder="Number of days"
                      />
                      {lopErrorMessage && (
                        <div className="ml-1 mt-1" style={{ color: "red" }}>
                          {lopErrorMessage}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
