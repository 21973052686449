import { useStore } from "../store/useStore";
import "../assets/styles/profile.scss"
import { DateTime } from "luxon";

export const Profile = () => {
  const { profile,profileImage} = useStore();
  

 return (
   <div className="test my-team-bg h-full pt-4 ProfileMain tmsContainer">
     <div className="mx-5 my-3 text-left ">
       <div className="p-3 cust-pro-user-clr profileText">User Profile</div>
       <div className="flex bg-white profileBottomMain">
         <div className="hidden md:block m-4">
           <img
             src={`data:image/png;base64,${profileImage}`}
             className="profileImage"
             alt="Profile"
           />
         </div>
         <div className="ml-4 mt-5" id="mobileProfile">
           <div className="mobileProfileTop">
             <div className="block md:hidden">
               <img
                 src={`data:image/png;base64,${profileImage}`}
                 alt="Profile"
                 className="profileImage"
               />
             </div>
             <div>
               <h1 className="mb-2 userName">{profile.displayName}</h1>
               <p className="mt-0 jobTitle">{profile.jobTitle}</p>
             </div>
           </div>
           <div className="font-bold bottom">
             <div className="eachRow flex">
               <p className="w-3">Employee ID</p>
               <p className="eachRowValue w-4">{profile.employeeId}</p>
             </div>
             <div className="eachRow">
               <p className="w-3">Email</p>
               <p className="w-4 eachRowValue emailWrap">
                 {profile.userPrincipalName}
               </p>
             </div>
             <div className="eachRow">
               <p className="w-3">Work Location</p>
               <p className="eachRowValue w-4">{profile.officeLocation}</p>
             </div>
             <div className="eachRow">
               <p className="w-3">Date of Joining</p>
               <p className="eachRowValue w-4">
                 {DateTime.fromISO(profile.employeeHireDate).toFormat(
                   "MMM dd, yyyy"
                 )}
               </p>
             </div>
             <div className="eachRow">
               <p className="w-3">Phone Number</p>
               <p className="eachRowValue w-4">{profile.mobilePhone}</p>
             </div>
             <div className="eachRow">
               <p className="w-3">Department</p>
               <p className="eachRowValue w-4">{profile.department}</p>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 );
};
