import { useRef } from "react";
import {ToasterType} from "../../types/ToasterType"

const useToaster = () => {
  const toast = useRef<any>(null);

  const showToaster = ({status,CustomError}: ToasterType) => {
    CustomError.forEach((message)=>(
      toast.current?.show({
      severity:status,
      summary:message,
      life: 3000,
    })
 ))
};

  return { showToaster, toast };
};

export { useToaster };