import { SidebarType } from "../types/SidebarType";
import calendarIcon from "../assets/images/calendarIcon.svg";
import myLeavesIcon from "../assets/images/myLeavesIcon.svg";
import teamIcon from "../assets/images/teamIcon.svg";
import holidaysIcon from "../assets/images/holidaysIcon.svg";
import ProfileIcon from "../assets/images/profileLogo.svg";
import leaveRequestIcon from "../assets/images/leaveRequestIcon.svg";
import accountIcon from "../assets/images/accountsIcon.svg";
import { RoleEnum } from "../enum/RoleEnum";

export const SidebarData: SidebarType[] = [
  {
    id: 1,
    pic: calendarIcon,
    name: "Calendar",
    link: "/",
    allowedRoles: [RoleEnum.ALL],
  },
  {
    id: 2,
    pic: myLeavesIcon,
    name: "My Leaves",
    link: "/my-leaves",
    allowedRoles: [RoleEnum.ALL],
  },
  {
    id: 3,
    pic: leaveRequestIcon,
    name: "Leave Request",
    link: "/leave-request",
    allowedRoles: [RoleEnum.ADMIN, RoleEnum.LEADS],
  },
  {
    id: 4,
    pic: teamIcon,
    name: "Payroll",
    link: "/payslip-download",
    allowedRoles: [RoleEnum.ALL],
  },
  {
    id: 5,
    pic: holidaysIcon,
    name: "Holidays",
    link: "/holidays",
    allowedRoles: [RoleEnum.ALL],
  },
  {
    id: 6,
    pic: teamIcon,
    name: "Employee Payroll",
    link: "/employee-payroll",
    allowedRoles: [RoleEnum.ACCOUNTS, RoleEnum.ADMIN],
  },
  {
    id: 7,
    pic: holidaysIcon,
    name: "Payroll Template",
    link: "/payroll-template",
    allowedRoles: [RoleEnum.ACCOUNTS, RoleEnum.ADMIN],
  },
  {
    id: 8,
    pic: teamIcon,
    name: "My Team",
    link: "/my-team",
    allowedRoles: [RoleEnum.ADMIN, RoleEnum.LEADS],
  },
  {
    id: 9,
    pic: accountIcon,
    name: "Accounts Reports",
    link: "/accounts-reports",
    allowedRoles: [RoleEnum.ACCOUNTS, RoleEnum.ADMIN],
  },
];

//Mobile

export const MobileSidebarData: SidebarType[] = [
  {
    id: 1,
    pic: calendarIcon,
    name: "Calendar",
    link: "/",
    allowedRoles: [RoleEnum.ALL],
  },
  {
    id: 2,
    pic: myLeavesIcon,
    name: "My Leaves",
    link: "/my-leaves",
    allowedRoles: [RoleEnum.ALL],
  },
  {
    id: 3,
    pic: leaveRequestIcon,
    name: "Leave Request",
    link: "/leave-request",
    allowedRoles: [RoleEnum.ADMIN, RoleEnum.LEADS],
  },
  {
    id: 4,
    pic: teamIcon,
    name: "Payroll",
    link: "/payslip-download",
    allowedRoles: [RoleEnum.ALL],
  },
  {
    id: 5,
    pic: holidaysIcon,
    name: "Holidays",
    link: "/holidays",
    allowedRoles: [RoleEnum.ALL],
  },
  {
    id: 6,
    pic: teamIcon,
    name: "Employee Payroll",
    link: "/employee-payroll",
    allowedRoles: [RoleEnum.ADMIN, RoleEnum.ACCOUNTS],
  },
  {
    id: 7,
    pic: holidaysIcon,
    name: "Payroll Template",
    link: "/payroll-template",
    allowedRoles: [RoleEnum.ADMIN, RoleEnum.ACCOUNTS],
  },
  {
    id: 8,
    pic: teamIcon,
    name: "My Team",
    link: "/my-team",
    allowedRoles: [RoleEnum.ACCOUNTS, RoleEnum.ADMIN],
  },
  {
    id: 9,
    pic: ProfileIcon,
    name: "Profile",
    link: "/profile",
    allowedRoles: [RoleEnum.ALL],
  },
  {
    id: 9,
    pic: accountIcon,
    name: "Accounts Reports",
    link: "/accounts-reports",
    allowedRoles: [RoleEnum.ACCOUNTS, RoleEnum.ADMIN],
  },
];
