import { DateTime } from "luxon";

export const useDateFormat = () => {
  const customDateFormat = (date: Date) => {
    const dateInString = new Date(date);
    const dateFormat = DateTime.fromJSDate(dateInString);
    const month = dateFormat.toFormat("MM");
    const year = dateFormat.toFormat("yyyy");

    return { month, year };
  };
  return { customDateFormat };
};
