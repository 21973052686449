import { Button } from "primereact/button";
import { PayrollProps } from '../types/PayrollProps';
import { Controller, useWatch } from "react-hook-form";
import { InputText } from 'primereact/inputtext';

export const Payroll = ({ header, name, fields, appendInput, removeInput, control }: PayrollProps) => {
    const watchedFields = useWatch({
        control,
        name: name,
    });

    const isDuplicateValue = (value: string, index: number) => {
        const values = watchedFields && watchedFields.map((item: any) => item.value);
        return value !== "" && values.indexOf(value) !== index;
    };

    return (
        <div id="payroll-component">
            <h1 className='mb-5 text-base'>{header}</h1>
            {fields.map((inputName: any, index: number) => (
                <div key={inputName.id} className="payroll-outerBox">
                    <div key={inputName.id} className="m-2 payroll-innerBox flex">
                        <div>
                            <Controller
                                name={`${name}.${index}.value`}
                                control={control}
                                rules={{ required: "This field is required" }}
                                defaultValue={inputName.value}
                                render={({ field }) => (
                                    <>
                                        <InputText {...field} value={field.value}/>
                                        {isDuplicateValue(field.value, index) && (
                                            <span className="payroll-error-message">
                                                Input value is duplicated
                                            </span>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                        <div>
                            <i onClick={() => removeInput(index)} className="pi pi-times payroll-outer-box  ml-3 mt-3"></i>
                        </div>
                    </div>
                </div>
            ))}
            <Button
                className="payroll-add-btn pl-0"
                onClick={() => appendInput({ value: "" })}
            >
                + Add New
            </Button>
        </div>
    );
};