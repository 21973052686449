import { Card } from "primereact/card";
import { Tooltip } from "primereact/tooltip";
import info from "../assets/images/info.svg";
import { LeavesCardData } from "../constants/LeavesCardData";
import { LeaveCardTypes } from "../types/LeaveCardTypes";

export function LeaveCard({leaveBalance}:any) {
  
  return (
    <div id="team-card-header" className="my-team-bg pt-3">
      <div className="flex" id="MobileCard">
        {LeavesCardData.map(
          (
            { id, leaveType, icon, content, targetId, apiName }: LeaveCardTypes,
            index
          ) => (
            <Card className="w-3 m-3" id="MobileCardEach" key={id}>
              <div className="flex justify-content-between">
                <img className="h-fit" src={icon} alt={leaveType} />
                <div className="flex flex-column">
                  <img
                    className="h-fit infoIcon"
                    id={targetId}
                    src={info}
                    alt=""
                    data-pr-position="bottom"
                  />

                  <p className="m-0 fnt-56 fontSizeMobile" key={index}>
                    {parseFloat(leaveBalance[apiName]).toFixed(2)}
                  </p>
                  <Tooltip target={`#${targetId}`} style={{ width: "300px" }}>
                    <p className="m-0 font-bold">{leaveType}</p>
                    <div>{content}</div>
                  </Tooltip>
                </div>
              </div>
              <div className="text-left">{leaveType}</div>
            </Card>
          )
        )}
      </div>
    </div>
  );
}
