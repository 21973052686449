import { NavLink } from "react-router-dom";
import { SidebarData } from "../../constants/SidebarData";
import { useLocation } from "react-router-dom";
import { useStore } from "../../store/useStore";
import { RoleEnum } from "../../enum/RoleEnum";
import { constantObj } from "../../utils/config/constantConfig";

export const Sidebar = () => {
  const { roles } = useStore();
  let location = useLocation();

  const isActiveLink = (link: string): boolean => {
    return (
      (location.pathname.startsWith(link) && link !== "/") ||
      location.pathname === link
    );
  };

  const isValidRole = (allowedRoles: RoleEnum[]): boolean => {
    let isValid = false;

    allowedRoles.forEach((role: RoleEnum) => {
      if (roles.includes(role)) {
        isValid = true;
        return;
      }
    });

    return isValid;
  };

  return (
    <div className="fixed z-5 h-full overflow-hidden sidebar-container">
      <div
        className={`bg-clr2B px-2 py-3 ${
          constantObj.companyId === "softobotics" ? "h-4rem" : "h-5rem"
        }`}
      >
        <img
          src={constantObj.logo}
          alt="h"
          height={`${
            constantObj.companyId === "softobotics" ? "24px" : "60px"
          }`}
          width={`${
            constantObj.companyId === "softobotics"
              ? "200px"
              : constantObj.companyId === "cloudprism"
              ? "100px"
              : "140px"
          }`}
          className={`${constantObj.companyId === "softobotics" ? "" : "ml-6"}`}
        />
      </div>
      <div className="bg-clr43 h-full pl-1 pr-1  pt-4 pr-2">
        {SidebarData.map((items) => {
          return isValidRole(items.allowedRoles) ? (
            <NavLink
              to={items.link}
              className="no-underline text-white"
              key={items.id}
            >
              <div
                className={`py-2 pl-3 active font-semibold flex align-items-center ${
                  isActiveLink(items.link) ? "activeLink" : ""
                }`}
              >
                <img src={items.pic} className="text-center" alt={items.name} />
                <span className="text-style pl-3">{items.name}</span>
              </div>
            </NavLink>
          ) : null;
        })}
      </div>
    </div>
  );
};
