import { useCallback, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Tag } from "primereact/tag";
import warning from "../assets/images/warning.svg";
import { useApi } from "../utils/services/useApi";
import { Toast } from "primereact/toast";
import { useToaster } from "../utils/services/useToaster";
import { DateTime } from "luxon";
import { LeaveType } from "../types/LeaveType";
import { leaveTypesObj } from "../constants/LeaveTypesObj";
import { StatusEnum } from "../enum/StatusEnum";
import { ProgressSpinner } from "primereact/progressspinner";

export function Modal({
  fetchLeaves,
  leaveId,
}: {
  fetchLeaves: () => void;
  leaveId: number;
}) {
  const [displayPendingLeave, setDisplayPendingLeave] = useState(false);
  const [displayLeaveWarning, setdisplayLeaveWarning] = useState(false);
  const [displayLeave, setdisplayLeave] = useState(false);
  const [, setPosition] = useState("center");
  const [singleLeave, setSingleLeave] = useState<LeaveType | null>(null);
  const { toast, showToaster } = useToaster();
  const { get, put } = useApi(showToaster);
  const [loader, setLoader] = useState(false);
  const colors: any = {
    APPROVED: "success",
    ADDED: "info",
    PENDING: "warning",
    REJECTED: "danger",
  };

  const onShow = (
    name: string,
    position: React.SetStateAction<string> | undefined
  ) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const handleWithdraw = (
    name: string,
    position: React.SetStateAction<string> | undefined
  ) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name: string) => {
    dialogFuncMap[`${name}`](false);
  };

  const dialogFuncMap: any = {
    displayPendingLeave: setDisplayPendingLeave,
    displayLeaveWarning: setdisplayLeaveWarning,
    displayLeave: setdisplayLeave,
  };

  const getSingleLeave = useCallback(async (id: number) => {
    try {
      const res = await get(`leave/${id}`);
      onShow(
        res.status === StatusEnum.PENDING
          ? "displayPendingLeave"
          : "displayLeave",
        "center"
      );
      setSingleLeave(res);
    } catch (err) {
      console.log(err);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleWithdrawLeave = async (id: number) => {
    const { success } = await put(`leave/withdraw/${id}`);
    if (success) {
      toast.current.show({
        severity: "warn",
        summary: "Withdraw",
        detail: "Leave Withdraw",
        life: 3000,
      });
    }
    fetchLeaves();
    getSingleLeave(leaveId);
    onHide("displayLeaveWarning");
    onHide("displayLeave");
    setLoader(false);
  };

  return (
    <div>
      <Toast ref={toast} />
      <div>
        <Button
          className="cust-btn-clr myLeaveViewBtn"
          onClick={async () => {
            if (leaveId) await getSingleLeave(leaveId);
          }}
        >
          View
        </Button>
      </div>
      {singleLeave ? (
        <div>
          {singleLeave.status === StatusEnum.PENDING ? (
            <Dialog
              header="Leave Withdraw"
              className="pb-0"
              id="MobileViewModal"
              visible={displayPendingLeave}
              onHide={() => onHide("displayPendingLeave")}
            >
              <div>
                <div className="bottom">
                  <div className="eachRow">
                    <p className="w-3 left my-2">Leave Type</p>
                    <p className="font-bold right my-2">
                      {leaveTypesObj[singleLeave.leaveType]}
                    </p>
                  </div>
                  <div className="eachRow">
                    <p className="w-3 left my-2">Leave Period</p>
                    <p className="font-bold right my-2">
                      {Math.abs(singleLeave.numDays) === 0.5
                        ? "Half Day"
                        : Math.abs(singleLeave.numDays) === 1
                        ? "Single"
                        : Math.abs(singleLeave.numDays) > 1
                        ? "Multiple"
                        : "-"}
                    </p>
                  </div>
                  <div className="eachRow">
                    <p className="w-3 left my-2">Date</p>
                    <p className="font-bold right my-2">
                      {DateTime.fromISO(singleLeave.startDate).toLocaleString(
                        DateTime.DATE_MED
                      )}{" "}
                      {singleLeave.endDate &&
                        !(singleLeave?.endDate === singleLeave?.startDate) &&
                        ` - ${DateTime.fromISO(
                          singleLeave.endDate
                        ).toLocaleString(DateTime.DATE_MED)}`}
                    </p>
                  </div>
                  <div className="eachRow">
                    <p className="w-3 left my-2">Number Of Leaves</p>
                    <p className="font-bold right my-2">
                      {Math.abs(singleLeave.numDays)}
                    </p>
                  </div>

                  <div className="eachRow">
                    <p className="w-3 left my-2">Reason (Optional)</p>
                    <p className="font-bold right my-2">
                      {singleLeave.reason || "-"}
                    </p>
                  </div>
                  <div className="eachRow">
                    <p className="w-3 left my-2">Status</p>
                    <Tag
                      value={
                        singleLeave.status.charAt(0).toUpperCase() +
                        singleLeave.status.substr(1).toLowerCase()
                      }
                      severity={colors[singleLeave.status]}
                      rounded={true}
                      className="h-1rem p-2 py-3 mt-2"
                    ></Tag>
                  </div>
                </div>
              </div>

              <div className="text-right MobileViewButton">
                <Button
                  className="mr-3 bg-transparent border-0 text-blue-600"
                  id="MobileViewButtonLeft"
                  onClick={() => onHide("displayPendingLeave")}
                >
                  OK
                </Button>
                <Button
                  className="p-button-warning MobileViewButtonRight"
                  onClick={() => {
                    handleWithdraw("displayLeaveWarning", "center");
                  }}
                >
                  Withdraw
                </Button>
              </div>
              <Dialog
                header="Withdraw"
                className="pb-0"
                id="MobileWithdraw"
                visible={displayLeaveWarning}
                onHide={() => {
                  onHide("displayLeaveWarning");
                }}
              >
                <div className="flex MobileWithdrawTop">
                  <img src={warning} alt="" />
                  <div className="ml-3 flex align-items-center">
                    Are you sure that you want to withdraw the requested leave ?
                  </div>
                </div>
                <div className="text-right mt-3">
                  <Button
                    className="mr-3 bg-transparent border-0 text-blue-600"
                    onClick={() => {
                      onHide("displayLeaveWarning");
                    }}
                  >
                    No
                  </Button>
                  <Button
                    disabled={loader}
                    className="cust-btn-clr"
                    onClick={() => {
                      handleWithdrawLeave(singleLeave.leaveId);
                      setLoader(true);
                    }}
                  >
                    {loader ? (
                      <ProgressSpinner
                        className="custom-progress-spinner"
                        strokeWidth="10"
                      />
                    ) : (
                      "Yes"
                    )}
                  </Button>
                </div>
              </Dialog>
            </Dialog>
          ) : (
            <Dialog
              header="Leave Details"
              className="pb-0"
              id="MobileViewModal"
              visible={displayLeave}
              onHide={() => onHide("displayLeave")}
            >
              <div className="bottom">
                <div className="eachRow flex">
                  <p className="w-3 left my-2">Leave Type</p>
                  <p className="font-bold right my-2">
                    {leaveTypesObj[singleLeave.leaveType]}
                  </p>
                </div>
                <div className="eachRow">
                  <p className="w-3 left my-2">Leave Period</p>
                  <p className="font-bold right my-2">
                    {Math.abs(singleLeave.numDays) === 0.5
                      ? "Half Day"
                      : Math.abs(singleLeave.numDays) === 1
                      ? "Single"
                      : Math.abs(singleLeave.numDays) > 1
                      ? "Multiple"
                      : "-"}
                  </p>
                </div>
                {singleLeave.status === StatusEnum.ADDED ||
                singleLeave.status === StatusEnum.SUBTRACTED ? (
                  <div className="eachRow">
                    <p className="w-3 left my-2">Created Date</p>
                    <p className="font-bold right  my-2">
                      {DateTime.fromISO(singleLeave.createdDate).toLocaleString(
                        DateTime.DATE_MED
                      )}
                    </p>
                  </div>
                ) : (
                  <div
                    className="eachRow"
                    id={
                      singleLeave?.startDate == null &&
                      singleLeave?.endDate == null
                        ? "addedSubtractedModal"
                        : ""
                    }
                  >
                    <p className="w-3 left my-2">Date</p>
                    <p className="font-bold right  my-2">
                      {DateTime.fromISO(singleLeave.startDate).toLocaleString(
                        DateTime.DATE_MED
                      )}{" "}
                      {singleLeave.endDate &&
                        !(singleLeave?.endDate === singleLeave?.startDate) &&
                        ` - ${DateTime.fromISO(
                          singleLeave.endDate
                        ).toLocaleString(DateTime.DATE_MED)}`}
                    </p>
                  </div>
                )}
                <div className="eachRow">
                  <p className="w-3 left my-2">Number Of Leaves</p>
                  <p className="font-bold right my-2">
                    {Math.abs(singleLeave.numDays)}
                  </p>
                </div>
                <div className="eachRow">
                  <p className="w-3 left my-2">Reason (Optional)</p>
                  <p className="font-bold right my-2">
                    {singleLeave.reason || "-"}
                  </p>
                </div>
                <div className="eachRow">
                  <p className="w-3 left my-2">Status</p>
                  <Tag
                    value={
                      singleLeave.status.charAt(0).toUpperCase() +
                      singleLeave.status.substr(1).toLowerCase()
                    }
                    severity={colors[singleLeave.status]}
                    rounded={true}
                    className="h-1rem p-2 py-3 mt-2"
                  ></Tag>
                </div>
                <div
                  className="eachRow"
                  id={
                    singleLeave.status === StatusEnum.ADDED ||
                    singleLeave.status === StatusEnum.SUBTRACTED
                      ? "hideSubtracted"
                      : ""
                  }
                >
                  <p className="w-3 left my-2">Reviewer Name</p>
                  <p className="font-bold right my-2">
                    {singleLeave.approver?.name || "-"}
                  </p>
                </div>
                <div
                  className="eachRow"
                  id={
                    singleLeave.status === StatusEnum.ADDED ||
                    singleLeave.status === StatusEnum.SUBTRACTED
                      ? "hideComment"
                      : ""
                  }
                >
                  <p className="w-3 left my-2">Reviewer Comments</p>
                  <p className="font-bold right my-2">
                    {singleLeave.approveComments
                      ? singleLeave.approveComments
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="text-right">
                <Button
                  onClick={() => onHide("displayLeave")}
                  className="bg-transparent text-blue-500 py-1 px-6 MobileAcceptedButton"
                >
                  OK
                </Button>
              </div>
            </Dialog>
          )}
        </div>
      ) : null}
    </div>
  );
}
