import {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router";
import { EmployeeDetails } from "../components/EmployeeDetails";
import { useApi } from "../utils/services/useApi";
import { useToaster } from "../utils/services/useToaster";
import "../assets/styles/RunningPayroll.scss";
import { RunPayroll } from "../components/RunPayroll";
import { useFieldArray, useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import warning from "../assets/images/warning.svg";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { Spinner } from "../components/Spinner";

export const RunningPayrollPage = () => {
  const { id, date } = useParams();
  const [, setPayrollData] = useState<any>({});
  const [memberData, setMemberData] = useState<any>({});
  const { showToaster } = useToaster();
  const { get, post, put } = useApi(showToaster);
  const toast = useRef<any>(null);
  const navigate = useNavigate();
  const [displayRunPayroll, setdisplayRunPayroll] = useState(false);
  const [, setPosition] = useState("center");
  const [loader, setLoader] = useState(false);
  const [lop, setLop] = useState(0);
  const [pageLoader, setPageLoader] = useState(true);
  const [clicked, setClicked] = useState<boolean>(false);
  const location = useLocation();
  const { pathname } = location;
  const isEditing = pathname.includes("/edit-employee-payroll/");

  const dialogFuncMap: any = {
    displayRunPayroll: setdisplayRunPayroll,
  };

  const handleRunPayroll = (
    name: string,
    position: SetStateAction<string> | undefined
  ) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name: string) => {
    dialogFuncMap[`${name}`](false);
  };

  const { control, setValue, watch, reset } = useForm({
    mode: "onChange",
  });

  const { fields: allowanceFields, remove: removeAllowance } = useFieldArray({
    control,
    name: "allowanceArray",
  });

  const { fields: deductionsFields, remove: removeDeduction } = useFieldArray({
    control,
    name: "deductionArray",
  });

  const getIndividualTeam = useCallback(async () => {
    try {
      const res = await get(`/employee-info/${id}`);
      setMemberData(res);
      setPageLoader(false);
    } catch (err) {
      console.log(err);
      setPageLoader(false);
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getIndividualTeam();
  }, [getIndividualTeam]);

  const getEmployeePayrollData = useCallback(async () => {
    try {
      const res = await get(`/payroll/previous/${id}`);
      setPayrollData(res);
      setValue("allowanceArray", res.payrollJson.allowance);
      setValue("deductionArray", res.payrollJson.deduction);
      setLop(res.lop);
    } catch (err) {
      console.log(err);
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getEmployeePayrollData();
  }, [getEmployeePayrollData]);

  const handleOnSubmit = useCallback(
    async (data: any) => {
      try {
        if (isEditing) {
          setValue("allowanceArray", data.allowanceArray);
          setValue("deductionArray", data.deductionArray);
        }
        let payrollJsonPostData;
        if (
          (lop === 0 || lop) &&
          data.allowanceArray.length > 0 &&
          data.deductionArray.length > 0
        ) {
          payrollJsonPostData = {
            empId: id,
            month: Number(date),
            payrollJson: {
              allowance: data.allowanceArray,
              deduction: data.deductionArray,
            },
            lop: lop,
          };
        
          if (isEditing) {
            const { success } = await put(`payroll`, payrollJsonPostData);
            if (success) {
              toast.current.show({
                severity: "success",
                summary: "Payroll Updated Successfully",
                life: 1000,
              });
            }
          } else {
            const { success } = await post(`payroll`, payrollJsonPostData);
            if (success) {
              toast.current.show({
                severity: "success",
                summary: "Payroll Run Successfully",
                life: 1000,
              });
            }
            onHide("displayRunPayroll");
          }
           setTimeout(() => {
             navigate("/employee-payroll");
           }, 1500);
        }
        setLoader(false);
      } catch (err) {
        onHide("displayRunPayroll");
        setLoader(false);
        console.log(err);
      }
      reset();
    },
    [lop] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      {pageLoader ? (
        <Spinner />
      ) : (
        <div className="running-payroll-bg">
          <Toast ref={toast} />
          <div className="running-payroll-details p-3 m-3">
            <EmployeeDetails
              memberData={memberData}
              backLink={`/employee-payroll/${id}`}
              date={date}
              hideAccountInfo={false}
              setLop={setLop}
              lop={lop}
              clicked={clicked}
            />
          </div>
          <Dialog
            header="Run Payroll"
            visible={displayRunPayroll}
            onHide={() => onHide("displayRunPayroll")}
          >
            <div className="flex">
              <img src={warning} alt="" />
              <div className="ml-3 flex align-items-center">
                Are you sure you want to run the payroll?
              </div>
            </div>
            <div className="text-right mt-3">
              <Button
                className="mr-3 bg-transparent border-0 text-blue-600"
                onClick={() => onHide("displayRunPayroll")}
              >
                No
              </Button>
              <Button
                className="cust-btn-clr"
                onClick={(e) => {
                  e.preventDefault();
                  setLoader(true);
                  handleOnSubmit(watch());
                }}
              >
                {loader ? (
                  <ProgressSpinner
                    className="custom-progress-spinner"
                    strokeWidth="10"
                  />
                ) : (
                  "Yes"
                )}
              </Button>
            </div>
          </Dialog>
          <div className="running-payroll-components m-3  p-5">
            <form>
              <div className="flex justify-content-between">
                <div className="payroll-left-component w-5 pl-7">
                  <RunPayroll
                    header="Salary Components"
                    name="allowanceArray"
                    control={control}
                    fields={allowanceFields}
                    removeInput={removeAllowance}
                  />
                </div>
                <div className="w-5">
                  <RunPayroll
                    header="Salary Deductions"
                    name="deductionArray"
                    control={control}
                    fields={deductionsFields}
                    removeInput={removeDeduction}
                  />
                </div>
              </div>
              <div className="flex flex-row-reverse justify-content-between  mt-3 mr-4">
                <div>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/employee-payroll");
                    }}
                    className="p-button-outlined mr-2 ml-2"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      if (isEditing) {
                        handleOnSubmit(watch());
                      } else {
                        lop === 0 || lop
                          ? handleRunPayroll("displayRunPayroll", "center")
                          : setClicked(true);
                      }
                    }}
                    className="cust-btn-clr"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};
