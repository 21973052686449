import { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import {
  ActionBodyTemplate,
  leaveTypeBodyTemplate,
  startDateBodyTemplate,
  endDateBodyTemplate,
  createdDateBodyTemplate,
  NumberOfLeavesBodyTemplate,
} from "../components/LeaveTableTemplate";
import { Paginator } from "primereact/paginator";
import {
  onBasicPageChangeType,
  currentPageReportType,
} from "../types/PaginationType";
import { statusBodyTemplate } from "../components/LeaveTableTemplate";
import {LeaveTableType} from "../types/LeaveTableType"
export const LeavesTable = ({fetchLeaves,myLeaveData,totalRecords,setCurrentPage,setSize,size}:LeaveTableType) => {
  
  const [basicFirst, setBasicFirst] = useState(0);
  
  const onBasicPageChange = (event: onBasicPageChangeType) => {
    setCurrentPage(event.page);
    setBasicFirst(event.first);
    setSize(event.rows);
  };

  const template3 = {
    layout:
      "RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport",
    CurrentPageReport: (options: currentPageReportType) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "auto",
            textAlign: "center",
          }}
        >
          Showing {options.first} to {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  return (
    <div>
      <DataTable value={myLeaveData} id="myLeaveMain" className="myLeaveTable">
        <Column
          header="Leave Type"
          body={leaveTypeBodyTemplate}
          exportable={false}
        />
        <Column
          header="From Date"
          body={startDateBodyTemplate}
          exportable={false}
        />
        <Column
          header="To Date"
          body={endDateBodyTemplate}
          exportable={false}
        />
        <Column
          header="Applied At"
          body={createdDateBodyTemplate}
          exportable={false}
        />
        <Column header="Number Of Leaves" 
         body={NumberOfLeavesBodyTemplate}
         exportable={false}
         />
        <Column
          header="Status"
          body={statusBodyTemplate}
          exportable={false}
        ></Column>
        <Column
          header="Action"
          body={(e)=><ActionBodyTemplate fetchLeaves={fetchLeaves} leaveId={e.leaveId} />}
          exportable={false}
        ></Column>
      </DataTable>

      <Paginator
        first={basicFirst}
        rows={size}
        totalRecords={totalRecords}
        template={template3}
        rowsPerPageOptions={[10, 20, 50]}
        onPageChange={onBasicPageChange}
      ></Paginator>
    </div>
  );
};
