import { Routes, Route, Navigate } from "react-router-dom";
import { Layout } from "../components/layouts/Layout";
import { Calendar } from "../pages/Calendar";
import { Profile } from "../pages/Profile";
import { Holiday } from "../pages/Holiday";
import { LeaveRequest } from "../pages/LeaveRequest";
import MyLeaves from "../pages/MyLeaves";
import EmployeeLeaveDetails from "../pages/EmployeeLeaveDetails";
import { MyTeam } from "../pages/MyTeam";
import { PayrollPage } from "../pages/PayrollPage";
import { ManagePayrollTemplate } from "../pages/ManagePayrollTemplate";
import { EmployeePayroll } from "../pages/EmployeePayroll";
import { AllEmployeePayroll } from "../pages/AllEmployeePayroll";
import { IndividualPayroll } from "../pages/IndividualPayroll";
import { useStore } from "../store/useStore";
import { RoleEnum } from "../enum/RoleEnum";
import { RunningPayrollPage } from "../pages/RunningPayrollPage";
import { RunningTemplatePayroll } from "../pages/RunningTemplatePayroll";
import { PayslipDownload } from "../pages/PayslipDownload";
import { EmployeeCalendar } from "../pages/EmployeeCalendar";
import { AccountsReports } from "../pages/AccountsReports";

export const AuthenticatedApp = () => {
  const { roles } = useStore();

  const isPrivilegedAdminLeads: boolean =
    roles.includes(RoleEnum.ADMIN) || roles.includes(RoleEnum.LEADS);

  const isPriviligedAdminAccounts: boolean =
    roles.includes(RoleEnum.ACCOUNTS) || roles.includes(RoleEnum.ADMIN);

  return (
    <Layout>
      <Routes>
        {isPrivilegedAdminLeads && (
          <Route path="/my-team" element={<MyTeam />} />
        )}
        {isPrivilegedAdminLeads && (
          <Route path="/leave-request" element={<LeaveRequest />} />
        )}
        {isPrivilegedAdminLeads && (
          <Route path="/my-teams/:id" element={<EmployeeLeaveDetails />} />
        )}

        {isPrivilegedAdminLeads && (
          <Route path="/my-teams-calendar/:id" element={<EmployeeCalendar />} />
        )}

        <Route path="/" element={<Calendar />} />
        <Route path="/my-leaves" element={<MyLeaves />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/holidays" element={<Holiday />} />
        <Route path="/payslip-download" element={<PayslipDownload />} />

        {isPriviligedAdminAccounts && (
          <Route path="/payroll-template/:id" element={<PayrollPage />} />
        )}
        {isPriviligedAdminAccounts && (
          <Route path="/payroll-template" element={<ManagePayrollTemplate />} />
        )}
        {isPriviligedAdminAccounts && (
          <Route path="/payroll-template/create" element={<PayrollPage />} />
        )}

        {isPriviligedAdminAccounts && (
          <Route path="/employee-payroll" element={<EmployeePayroll />} />
        )}
        {isPriviligedAdminAccounts && (
          <Route
            path="/employee-payroll/:id/:date/:templateid"
            element={<RunningTemplatePayroll />}
          />
        )}
        {isPriviligedAdminAccounts && (
          <Route
            path="/employee-payroll/all-employees/:date"
            element={<AllEmployeePayroll />}
          />
        )}
        {isPriviligedAdminAccounts && (
          <Route
            path="/employee-payroll/:id/:date"
            element={<RunningPayrollPage />}
          />
        )}
        {isPriviligedAdminAccounts && (
          <Route
            path="/edit-employee-payroll/:id/:date"
            element={<RunningPayrollPage />}
          />
        )}
        {isPriviligedAdminAccounts && (
          <Route path="/employee-payroll/:id" element={<IndividualPayroll />} />
        )}
        {isPriviligedAdminAccounts && (
          <Route path="/accounts-reports/" element={<AccountsReports />} />
        )}
      </Routes>
    </Layout>
  );
};
