import { NavLink } from "react-router-dom";
import {
  MobileSidebarData,
} from "../constants/SidebarData";
import { useLocation } from "react-router-dom";
import logoutIcon from "../assets/images/LogoutLogo.svg";
import { useStore } from "../store/useStore";
import { RoleEnum } from "../enum/RoleEnum";

type setValue = {
  setToggle: (toggle: boolean) => void;
  toggle: boolean;
};

export const Hamburger = ({ setToggle, toggle }: setValue) => {
  const location = useLocation();
  const { logout } = useStore();
  const { roles } = useStore();
  const isActiveLink = (link: string): boolean => {
    return (location.pathname.startsWith(link) && link !== '/') || (location.pathname === link);
  }
  const isValidRole = (allowedRoles: RoleEnum[]): boolean => {
    let isValid = false;
    allowedRoles.forEach((role: RoleEnum) => {
      if (roles.includes(role)) {
        isValid = true;
      }
    });

    return isValid;
  }

  return (
    <div className="fixed z-5 h-full overflow-hidden sidebar-container w-full">
      <div className="bg-clr43 h-full pl-1 pr-1  pt-4 pr-2">
        {MobileSidebarData.map((items) => {
          return isValidRole(items.allowedRoles) ? (
            <NavLink
              to={items.link}
              className="no-underline text-white"
              key={items.id}
              onClick={() => setToggle(!toggle)}
            >
              <div
                className={`py-2 pl-3 active font-semibold flex align-items-center ${isActiveLink(items.link) ? "activeLink" : ""
                  }`}
              >
                <img
                  src={items.pic}
                  className=" text-center"
                  alt={items.name}
                />
                <span className="text-style pl-3">{items.name}</span>
              </div>
            </NavLink>
          ):null
        })}

        <div
          className={`py-2 pl-3 active font-semibold flex align-items-center`}
          onClick={logout}
        >
          <img src={logoutIcon} className=" text-center" alt={"name"} />
          <span className="text-style pl-3">Logout</span>
        </div>
      </div>
    </div>
);
};