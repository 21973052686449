import { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../assets/styles/holiday.scss";
import { useStore } from "../store/useStore";
import {
  holidayDateBodyTemplate,
  holidayDayBodyTemplate,
} from "../components/LeaveTableTemplate";

export const Holiday = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  const { currentYearHoliday, nextYearHoliday, previousYearHoliday } =
    useStore();
  const year = new Date().getFullYear();

  const isWeekend = (date: string) => {
    if (!date) return "NA";
    const day = new Date(date).getDay();
    return day === 0 || day === 6;
  };

  const holidayRowClassName = ({ date }: { date: string }) => {
    return isWeekend(date) ? "weekend-row" : "";
  };

  return (
    <div className="main tmsContainer my-team-bg pt-3 h-full">
      <h3 className="ml-2">Holidays</h3>
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
        className="top"
      >
        <TabPanel
          header={`Previous Year (${year - 1})`}
          className="upcomingyear MobileMainNext"
        >
          <div className="card upcomingcard MobileMain2">
            <DataTable
              value={previousYearHoliday}
              responsiveLayout="scroll"
              className="tablupcoming"
              rowClassName={holidayRowClassName}
            >
              <Column
                field="date"
                header="Date"
                body={holidayDateBodyTemplate}
                style={{ paddingLeft: "60px" }}
                className="colLeftBottom"
              ></Column>
              <Column
                field="day"
                header="Day"
                body={holidayDayBodyTemplate}
                style={{ paddingLeft: "60px" }}
                className="colLeftBottom"
              ></Column>
              <Column field="name" header="Holiday Name"></Column>
              <Column field="type" header="Type"></Column>
            </DataTable>
          </div>
        </TabPanel>
        <TabPanel
          header={`Current Year (${year})`}
          className="currentyear MobileMainCurrent"
        >
          <div className="card currentyearcard MobileMain1">
            <DataTable
              value={currentYearHoliday}
              responsiveLayout="scroll"
              className="tablecurrent"
              rowClassName={holidayRowClassName}
            >
              <Column
                field="date"
                header="Date"
                body={holidayDateBodyTemplate}
                className="colLeft"
                style={{ paddingLeft: "60px" }}
              ></Column>
              <Column
                field="day"
                header="Day"
                body={holidayDayBodyTemplate}
                style={{ paddingLeft: "60px" }}
                className="colLeftBottom"
              ></Column>
              <Column field="name" header="Holiday Name"></Column>
              <Column field="type" header="Type"></Column>
            </DataTable>
          </div>
        </TabPanel>
        <TabPanel
          header={`Upcoming Year (${year + 1})`}
          className="upcomingyear MobileMainNext"
        >
          <div className="card upcomingcard MobileMain2">
            <DataTable
              value={nextYearHoliday}
              responsiveLayout="scroll"
              className="tablupcoming"
              rowClassName={holidayRowClassName}
            >
              <Column
                field="date"
                header="Date"
                body={holidayDateBodyTemplate}
                style={{ paddingLeft: "60px" }}
                className="colLeftBottom"
              ></Column>
              <Column
                field="day"
                header="Day"
                body={holidayDayBodyTemplate}
                style={{ paddingLeft: "60px" }}
                className="colLeftBottom"
              ></Column>
              <Column field="name" header="Holiday Name"></Column>
              <Column field="type" header="Type"></Column>
            </DataTable>
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
};
