import { ConstantType } from "../../types/ConstantType";

const companyIdFromEnv = process.env.REACT_APP_COMPANY_ID!;
const companyId: "softobotics" | "cloudprism" | "toreans" = companyIdFromEnv as
  | "softobotics"
  | "cloudprism" | "toreans";

export const constantObj: ConstantType = {
  clientId: process.env.REACT_APP_CLIENT_ID!,
  companyName: process.env.REACT_APP_COMPANY_NAME!,
  logo:
    process.env.REACT_APP_COMPANY_ID === "softobotics"
      ? require(process.env.REACT_APP_LOGO!).default
      : require(process.env.REACT_APP_LOGO!),
  companyId,
  authority: process.env.REACT_APP_AUTHORITY!,
  companyLogo: require(process.env.REACT_APP_COMPANY_LOGO!),
  darkBackgroundLogo: require(process.env.REACT_APP_DARK_BG_LOGO!),
};
